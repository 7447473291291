import React from 'react';
import autobind from 'auto-bind';
import { withRouter } from 'react-router-dom';
import {
  DatePicker,
  Button,
  Form,
  Select,
  InputNumber,
  message,
  Input
} from 'antd';

import moment from 'moment';
import 'react-virtualized/styles.css';
import sort from 'fast-sort';

import {
  postUserAction,
  putReward,
  deleteUserAction
} from '../../common/network';

import './Gamification.scss';

import { actionTypes, getLevel, getPointsFromActions } from './constants';
import ActionList from './ActionList';
import RewardList from './RewardList';

import level0 from '../../images/gamification_level_1.png';
import level1 from '../../images/gamification_level_2.png';
import level2 from '../../images/gamification_level_3.png';
import level3 from '../../images/gamification_level_4.png';

const levelImages = [level0, level1, level2, level3];

const { Option } = Select;
const { TextArea } = Input;

class Gamification extends React.Component {
  constructor(props) {
    super(props);

    const actions = this.props.record?.gamification?.actions ?? [];
    const toSort = sort(actions);

    const sortedActions = toSort.desc(action =>
      moment(action.date).format('YYYYMMDD')
    );

    const rewards = this.props.record?.gamification?.rewards ?? [];

    this.state = {
      newAction: {},
      actions: sortedActions,
      rewards
    };

    autobind(this);
  }

  clearValues() {
    this.props.form.resetFields();
  }

  async submit(event) {
    event.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        this.setState({ sending: true });

        const action = { ...values, user: this.props.record };

        const response = await postUserAction(action);
        if (response?.ok) {
          this.setState({ sending: false });
          this.props.history.go(0);
        } else {
          this.setState({ sending: false });
          message.error(
            'Erro a enviar acção. Recarregue a página e tente de novo.'
          );
        }
      }
    });
  }

  async deleteUserAction(_id) {
    const response = await deleteUserAction(_id);
    if (response?.ok) {
      this.setState({ sending: false });
      this.props.history.go(0);
    } else {
      this.setState({ sending: false });
      message.error(
        'Erro a remover acção. Recarregue a página e tente de novo.'
      );
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const actions = this.props.record?.gamification?.actions ?? [];
    const points = getPointsFromActions(actions);
    const level = getLevel(points);
    const levelImageSrc = levelImages[level.id];

    // console.log(this.props.record?.gamification);
    return (
      <div
        id="gamification"
        className="w-100 h-100 d-flex flex-col justify-content-start align-items-start"
      >
        <div className="pos-relative d-flex flex-row align-items-stretch mb-3 p-3 pl-0 w-100">
          <div className="header-info d-flex flex-col justify-content-center align-items-center mb-3 mr-5 p-3 px-5 ">
            <div className="level-image mb-3">
              <img src={levelImageSrc} alt="level badge"></img>
            </div>
            <span className="font-3 p-3 mb-3 ">{points} Pontos</span>
          </div>
          <div className="d-flex flex-col justify-content-start align-items-start mb-3 w-100">
            <span className="mr-3 mb-3">Recompensas</span>
            <RewardList
              data={this.state.rewards}
              update={async (_id, values) => {
                const response = await putReward(_id, values);
                if (response?.ok) {
                  this.setState({ sending: false });
                  this.setState(prevState => {
                    const rewards = [...prevState.rewards];
                    const reward = rewards.find(x => x._id === _id);
                    for (const key in values) {
                      const value = values[key];
                      reward[key] = value;
                    }
                    return { rewards };
                  });
                } else {
                  this.setState({ sending: false });
                  message.error(
                    'Erro a enviar informação. Recarregue a página e tente de novo.'
                  );
                }
              }}
            ></RewardList>
          </div>
        </div>
        <div
          id="create-action"
          className="d-flex flex-row justify-content-end align-items-center mb-3 p-3"
        >
          <Form
            onSubmit={this.submit}
            className="d-flex flex-col justify-content-start align-items-start"
          >
            <span className="mr-3 mb-3">Atribuir pontos</span>
            <div className="d-flex justify-content-start align-items-center mb-3">
              <Form.Item className="mr-3 mb-0">
                {getFieldDecorator('date', {
                  initialValue: moment(),
                  rules: [
                    {
                      required: true,
                      message: false
                    }
                  ]
                })(<DatePicker className="mr-3 mb-0" />)}
              </Form.Item>
              <Form.Item className="mr-3 mb-0">
                {getFieldDecorator('type', {
                  initialValue: '',
                  rules: [
                    {
                      required: true,
                      message: false
                    }
                  ]
                })(
                  <Select className="select" placeholder="Tipo">
                    {Object.values(actionTypes).map(actionType => (
                      <Option key={actionType.type} value={actionType.type}>
                        {actionType.label}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item className="mr-3 mb-0">
                {getFieldDecorator('points', {
                  initialValue: 10,
                  rules: [
                    {
                      required: true,
                      message: false
                    }
                  ]
                })(<InputNumber min={1} />)}
              </Form.Item>
            </div>
            <div className="d-flex justify-content-start align-items-center mb-3 w-100">
              <Form.Item className="mr-3 mb-0 w-100">
                {getFieldDecorator('notes', {
                  initialValue: '',
                  rules: [
                    {
                      required: false
                    }
                  ]
                })(
                  <TextArea placeholder="Notas" className="mr-3 mb-0 w-100" />
                )}
              </Form.Item>
            </div>
            <div className="d-flex justify-content-start align-items-center mb-3">
              <Button
                className="mr-3 mb-0"
                loading={this.state.sending}
                type="primary"
                htmlType="submit"
              >
                Enviar
              </Button>

              <Button
                className="mr-3 mb-0"
                loading={this.state.sending}
                onClick={() => this.clearValues()}
              >
                Limpar
              </Button>
            </div>
          </Form>
        </div>
        <span className="mb-3">Lista de Pontos</span>
        <ActionList
          data={actions}
          onDelete={this.deleteUserAction}
        ></ActionList>
      </div>
    );
  }
}

const GamificationForm = withRouter(
  Form.create({
    name: 'gamification_form'
  })(Gamification)
);

export default GamificationForm;
