import React from 'react';
import { ReferenceInput, SelectInput } from 'react-admin';

const CustomerReferenceInput = props => (
    <ReferenceInput
        source="id"
        label="Customer Name"
        reference="users"
        sort={{ field: 'name', order: 'ASC' }}
        perPage={10000000}
        {...props}
    >
        <SelectInput optionText={'name'} />
    </ReferenceInput>
);

export default CustomerReferenceInput;
