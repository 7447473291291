import React from 'react';

import { Storage, Auth } from 'aws-amplify';

import 'antd/dist/antd.css';
import { Button } from 'antd';
import uuidv4 from 'uuid/v4';

import globalStyles from '../common/globalStyles';

import { apiUrl } from '../dataProvider/rest';
import { deleteDocument } from '../common/network';
import DetailedMessage from '../components/DetailedMessage';

const styles = {
  modal: {
    body: {
      paddingTop: globalStyles.global.baseline * 2,
      paddingBottom: globalStyles.global.baseline * 2,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: globalStyles.global.baseline * 1.2
    },
    component: {
      margin: '0 ' + globalStyles.global.baseline + 'px'
    },
    list: {
      listStylePosition: 'inside',
      padding: 0,
      paddingLeft: globalStyles.global.baseline
    },
    message: {
      marginBottom: globalStyles.global.baseline
    },
    error: {
      marginBottom: globalStyles.global.baseline,
      color: globalStyles.colors.feedback.error
    }
  }
};

class GetFileButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { href: null };
  }
  async componentDidMount() {
    this.setState({ href: await Storage.get(this.props.record?.id) });
  }
  render() {
    if (this.state.href) {
      return (
        <Button type="primary" ghost href={this.state.href} target="_blank">
          Download
        </Button>
      );
    }

    return <span>Loading</span>;
  }
}

class DeleteFileButton extends React.Component {
  render() {
    return (
      <Button
        type="danger"
        ghost
        onClick={async e => {
          e.preventDefault();
          e.stopPropagation();
          let s3Result;
          try {
            s3Result = await Storage.remove(this.props.record?.id);
            console.log(s3Result);
          } catch (error) {
            DetailedMessage.error(
              'Erro a apagar ficheiro da Amazon. Tente de novo.',
              error
            );
            return;
          }
          console.log({ s3Result });
          let backendResult;
          try {
            backendResult = await deleteDocument(
              this.props.userId,
              this.props.record?.id
            );
            console.log(backendResult);
          } catch (error) {
            DetailedMessage.error(
              'Erro a apagar ficheiro do backend. Tente de novo.',
              error
            );
            return;
          }
          //const pathName = this.props.history.location.pathname;
          // this.props.history.goBack();
          // setTimeout(() => this.props.history.goForward(), 50);
          return (await this.props?.onSuccess?.()) ?? true;
        }}
      >
        Delete
      </Button>
    );
  }
}

const bucketName = 'finpartner-userfiles-mobilehub-920305426';
const folderName = '/public';

class UploadFile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      error: false,
      fileName: '',
      file: '',
      response: ''
    };
  }

  uploadFile = async () => {
    const random = uuidv4();
    const fileID = random + this.state.fileName;

    this.setState({ uploading: true });
    try {
      const s3Result = await Storage.put(fileID, this.state.file, {
        contentType: this.upload.files[0].type
      });
      this.upload = null;
      this.setState({ response: 'Success uploading file!' });
      const userInfo = await Auth.currentAuthenticatedUser();
      await fetch(apiUrl + '/documents/' + this.props.record?.id, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          jwt: userInfo.signInUserSession.accessToken.jwtToken
        },
        body: JSON.stringify({
          id: fileID,
          source: 'admin',
          name: this.state.fileName,
          url:
            'https://' +
            bucketName +
            '.s3.amazonaws.com' +
            folderName +
            '/' +
            s3Result.key,
          description: 'test description'
        })
      });
      this.setState({
        uploading: false,
        response: 'Sucesso!'
      });
      window.location.reload(false);
    } catch (err) {
      console.error(err);
      this.setState({
        response: `Erro no upload: ${err}`,
        error: err,
        uploading: false
      });
    }
  };

  render() {
    return (
      <div
        style={{
          width: '100%',
          padding: 0,
          paddingTop: 0,
          display: 'flex',
          alignItems: 'center',
          ...this.props.containerStyle
        }}
      >
        {this.state.show ? (
          <div
            style={{ ...styles.modal.body, ...(this.props.bodyStyle ?? null) }}
          >
            <div
              style={{
                minWidth: '80%',
                maxWidth: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: `0 0`
              }}
            >
              <input
                type="file"
                accept="*"
                style={{ display: 'none' }}
                ref={ref => (this.upload = ref)}
                onChange={e =>
                  this.setState({
                    file: this.upload.files[0],
                    fileName: this.upload.files[0].name
                  })
                }
              />
              <input
                style={{
                  ...styles.modal.component,
                  width: '80%',
                  marginLeft: 0
                }}
                disabled
                value={this.state.fileName}
                placeholder="Nome do ficheiro"
              />

              <Button
                onClick={e => {
                  this.upload.value = null;
                  this.upload.click();
                }}
                style={{ ...styles.modal.component }}
                loading={this.state.uploading}
              >
                Seleccionar Ficheiro
              </Button>

              <Button
                disabled={!this.state.file}
                loading={this.state.uploading}
                onClick={this.uploadFile}
              >
                Carregar Ficheiro
              </Button>
            </div>

            {!!this.state.response && (
              <div
                style={{ marginTop: `${globalStyles.global.baseline * 3}px` }}
              >
                {this.state.response}
              </div>
            )}
          </div>
        ) : (
          <Button onClick={() => this.setState({ show: true })}>
            {this.props.uploadButtonLabel || 'Add New File'}
          </Button>
        )}
      </div>
    );
  }
}

export default UploadFile;
export { GetFileButton, DeleteFileButton };
