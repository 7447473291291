import React, { useState } from 'react';
import { Button } from 'antd';

const ConfirmDelete = ({
  deleteLabel = 'Delete',
  confirmLabel = 'Confirm!',
  cancelLabel = 'Cancel',
  buttonStyle = {},
  onDelete = () => {},
  isConfirm = false,
  buttonProps = {},
  showCancel = false
}) => {
  const [confirm, setConfirm] = useState(isConfirm);

  return confirm ? (
    <>
      <Button
        type={'danger'}
        style={buttonStyle}
        onClick={async () => {
          try {
            const result = await onDelete();
            setConfirm(false);
            return result;
          } catch (err) {
            setConfirm(false);
            return err;
          }
        }}
        {...buttonProps}
      >
        {confirmLabel}
      </Button>
      {showCancel && (
        <Button
          type={'default'}
          style={buttonStyle}
          onClick={async () => {
            setConfirm(false);
          }}
          {...buttonProps}
        >
          {cancelLabel}
        </Button>
      )}
    </>
  ) : (
    <Button
      type={'danger'}
      ghost
      style={buttonStyle}
      onClick={() => setConfirm(true)}
      {...buttonProps}
    >
      {deleteLabel}
    </Button>
  );
};

export default ConfirmDelete;
