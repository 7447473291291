import React from 'react';
import Card from '@material-ui/core/Card';
//import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
//import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
//import HomeIcon from '@material-ui/icons/Home';
//import CodeIcon from '@material-ui/icons/Code';
import { withStyles } from '@material-ui/core/styles';
import finpartnerSvg from '../images/finpartner.svg';

import { translate } from 'react-admin';

const styles = {
    media: {
        height: '18em'
    }
};

const Welcome = ({ classes, translate }) => (
    <Card
        style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: '#fff'
        }}
    >
        <CardMedia
            image={finpartnerSvg}
            className={classes.media}
            style={{
                backgroundSize: 'contain',
                width: '100%',
                height: '280px',
                margin: '20px'
            }}
        />
        <CardContent>
            <Typography
                style={{ color: '#444' }}
                variant="headline"
                component="h2"
            >
                {translate('pos.dashboard.welcome.title')}
            </Typography>
            <Typography component="p">
                {translate('pos.dashboard.welcome.subtitle')}
            </Typography>
        </CardContent>
    </Card>
);

export default withStyles(styles)(translate(Welcome));
