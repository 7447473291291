import React from 'react';
import pure from 'recompose/pure';

const FullNameField = ({ record = {} }) => (
    <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
        {record.name}
    </div>
);

const PureFullNameField = pure(FullNameField);

PureFullNameField.defaultProps = {
    source: 'name',
    label: 'resources.admins.fields.name'
};

export default PureFullNameField;
