import React from 'react';

import { Storage, Auth } from 'aws-amplify';

import 'antd/dist/antd.css';
import { Icon, Button } from 'antd';
import uuidv4 from 'uuid/v4';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import moment from 'moment';

import DetailedMessage from '../components/DetailedMessage';
import globalStyles from '../common/globalStyles';
import { deleteCompanyDocument, deleteDocument } from '../common/network';

import { apiUrl } from '../dataProvider/rest';

const styles = {
  modal: {
    body: {
      paddingTop: globalStyles.global.baseline * 2,
      paddingBottom: globalStyles.global.baseline * 2,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: globalStyles.global.baseline * 1.2
    },
    component: {
      margin: '0 ' + globalStyles.global.baseline + 'px'
    },
    list: {
      listStylePosition: 'inside',
      padding: 0,
      paddingLeft: globalStyles.global.baseline
    },
    message: {
      marginBottom: globalStyles.global.baseline
    },
    error: {
      marginBottom: globalStyles.global.baseline,
      color: globalStyles.colors.feedback.error
    }
  }
};

class GetFileButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: false };
  }
  render() {
    if (this.state.error) {
      return <span>Error!</span>;
    }
    if (!this.props.fileId) {
      return <span>Loading</span>;
    }
    return (
      <Button
        style={this.props.style}
        className={this.props.className ?? ''}
        disabled={this.props.disabled}
        type="primary"
        onClick={async () => {
          try {
            const result = await Storage.get(this.props.fileId);
            window.location.href = result;
          } catch (err) {
            this.setState({ error: true });
          }
        }}
      >
        {this.props.label ?? 'Download'}
      </Button>
    );
  }
}

class DeleteFileButton extends React.Component {
  render() {
    return (
      <Button
        style={this.props.style}
        className={this.props.className ?? ''}
        disabled={this.props.disabled}
        type="danger"
        ghost
        onClick={async e => {
          e.preventDefault();
          e.stopPropagation();
          let s3Result;
          try {
            s3Result = await Storage.remove(this.props.fileId);
            console.log(s3Result);
          } catch (error) {
            console.error(error);
            if (this.props.onError) {
              await this.props.onError(s3Result);
            } else {
              DetailedMessage.error(
                'Erro a apagar ficheiro da Amazon. Tente de novo.',
                error
              );
            }
            return;
          }
          console.log({ s3Result });
          let backendResult;
          try {
            backendResult = await deleteCompanyDocument(
              this.props.groupId,
              this.props.fileId
            );

            await this.props?.onSuccess?.(backendResult);
            console.log(backendResult);
          } catch (error) {
            console.error(error);
            if (this.props.onError) {
              await this.props.onError(s3Result);
            } else {
              DetailedMessage.error(
                'Erro a apagar ficheiro do backend. Tente de novo.',
                error
              );
            }
            return;
          }
        }}
      >
        {this.props.label ?? 'Delete'}
      </Button>
    );
  }
}

class GetMultipleFilesButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
  }
  render() {
    return (
      <Button
        title={this.props.title}
        style={this.props.style}
        loading={this.state.loading}
        className={this.props.className ?? ''}
        disabled={this.props.disabled || !this.props.fileInfo}
        type="primary"
        onClick={async () => {
          if (!this.props.fileInfo?.length) {
            DetailedMessage.error(
              'Erro a descarregar ficheiros (nenhum ficheiro selecionado).'
            );
            return;
          }
          this.setState({ loading: true });

          const zip = JSZip();
          let fileCount = 0;
          const fileNames = [];
          const errors = [];
          for (const { id, name } of this.props.fileInfo) {
            try {
              const file = await Storage.get(id, { download: true });
              if (fileNames.includes(name)) {
                const randomString = (Math.random() + 1)
                  .toString(36)
                  .substring(6);
                const newName = randomString + name;
                zip.file(newName, file.Body, { date: file.LastModified });
                fileNames.push(newName);
              } else {
                zip.file(name, file.Body, { date: file.LastModified });
                fileNames.push(name);
              }
              fileCount++;
            } catch (err) {
              errors.push(`Erro obter ficheiro ${name}. Ignorado`);
            }
          }

          if (errors.length) {
            DetailedMessage.error(errors.join('\n'));
          }

          if (fileCount) {
            try {
              const blob = await zip.generateAsync({ type: 'blob' });
              saveAs(
                blob,
                `${moment().format(
                  'YYYY-MM-DD'
                )}finpartner_${fileCount}_files.zip`
              );
            } catch (err) {
              errors.push(`Erro a criar ou descarregar zip: ${err.message}`);
              console.error(err);
            }
          }

          this.setState({ loading: false });
        }}
      >
        {this.props.label ?? 'Download'}
      </Button>
    );
  }
}

class DeleteMultipleFilesButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
  }
  render() {
    return (
      <Button
        title={this.props.title}
        style={this.props.style}
        loading={this.state.loading}
        className={this.props.className ?? ''}
        disabled={this.props.disabled}
        type="danger"
        ghost
        onClick={async e => {
          e.preventDefault();
          e.stopPropagation();

          if (!this.props.fileInfo?.length) {
            DetailedMessage.error(
              'Erro a apagar ficheiro da Amazon (nenhum ficheiro selecionado).'
            );
            return;
          }

          this.setState({ loading: true });

          let fileCount = 0;
          const errors = [];
          for (const { id, name } of this.props.fileInfo) {
            try {
              await Storage.remove(id);
            } catch (error) {
              console.error(error);
              errors.push(`Erro a apagar ficheiro ${name}.`, error?.message);
              continue;
            }
            try {
              if (this.props.groupId) {
                await deleteCompanyDocument(this.props.groupId, id);
              } else {
                await deleteDocument(this.props.userId, id);
              }
              fileCount++;
            } catch (error) {
              console.error(error);
              errors.push(
                `Erro a apagar ficheiro do backend finpartner: ${name}`,
                error?.message
              );

              continue;
            }
          } // end for
          DetailedMessage.success(`${fileCount} ficheiros eliminados.`);
          this.setState({ loading: false });
          if (this.props.onSuccess) {
            return this.props.onSuccess();
          }
        }}
      >
        {this.props.label ?? 'Delete'}
      </Button>
    );
  }
}

const bucketName = 'finpartner-userfiles-mobilehub-920305426';
const folderName = '/public';

class UploadFile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      error: false,
      fileName: '',
      file: '',
      response: ''
    };
  }

  uploadFile = async () => {
    const random = uuidv4();
    const fileID = random + this.state.fileName;

    this.setState({ uploading: true });
    try {
      let s3Result = await Storage.put(fileID, this.state.file, {
        contentType: this.upload.files[0].type
      });
      console.log('uploaded file: ', s3Result);
      this.upload = null;
      this.setState({
        response: 'Success uploading file!'
      });
      const userInfo = await Auth.currentAuthenticatedUser();

      const doc = {
        id: fileID,
        source: 'admin',
        name: this.state.fileName,
        url:
          'https://' +
          bucketName +
          '.s3.amazonaws.com' +
          folderName +
          '/' +
          s3Result.key,
        description: ''
      };
      await fetch(apiUrl + '/groups/' + this.props.companyId + '/documents', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          jwt: userInfo.signInUserSession.accessToken.jwtToken
        },
        body: JSON.stringify(doc)
      });
      this.setState({
        uploading: false,
        response: 'Sucesso!'
      });
      if (this.props.onSuccess) {
        await this.props.onSuccess(doc);
      }
    } catch (err) {
      console.error(err);
      this.setState({
        response: `Erro no upload: ${err}`,
        error: err,
        uploading: false
      });
    }
  };

  render() {
    return (
      <div
        style={{
          width: '100%',
          padding: 0,
          display: 'flex',
          alignItems: 'center',
          ...this.props.containerStyle
        }}
      >
        {this.state.show ? (
          <div
            style={{ ...styles.modal.body, ...(this.props.bodyStyle ?? null) }}
          >
            <div
              style={{
                minWidth: '80%',
                maxWidth: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: `${globalStyles.global.baseline}px 0`
              }}
            >
              <input
                type="file"
                accept="*"
                style={{ display: 'none' }}
                ref={ref => (this.upload = ref)}
                onChange={e =>
                  this.setState({
                    file: this.upload.files[0],
                    fileName: this.upload.files[0].name
                  })
                }
              />
              <input
                style={{ ...styles.modal.component, width: '80%' }}
                disabled
                value={this.state.fileName}
                placeholder="Nome do ficheiro"
              />

              <Button
                onClick={e => {
                  this.upload.value = null;
                  this.upload.click();
                }}
                style={{ ...styles.modal.component }}
                loading={this.state.uploading}
              >
                Seleccionar Ficheiro
              </Button>

              <Button
                disabled={!this.state.file}
                loading={this.state.uploading}
                onClick={this.uploadFile}
              >
                Carregar Ficheiro
              </Button>
            </div>

            {!!this.state.response && (
              <div
                style={{ marginTop: `${globalStyles.global.baseline * 3}px` }}
              >
                {this.state.response}
              </div>
            )}
          </div>
        ) : (
          <Button
            style={{ borderRadius: 0, height: '2.3rem' }}
            onClick={() => this.setState({ show: true })}
          >
            {this.props.uploadButtonLabel || <Icon type="plus"></Icon>}
          </Button>
        )}
      </div>
    );
  }
}

export default UploadFile;
export {
  GetFileButton,
  GetMultipleFilesButton,
  DeleteFileButton,
  DeleteMultipleFilesButton
};
