import 'babel-polyfill';
import React, { Component } from 'react';
import { Admin, Resource } from 'react-admin';

import './App.css';
import './index.css';

import authProvider from './authProvider';
import sagas from './sagas';
import themeReducer from './themeReducer';
import Login from './Login';
import Recover from './Recover';
import Layout from './Layout';
import Menu from './Menu';
import { Dashboard } from './dashboard';
import customRoutes from './routes';
import englishMessages from './i18n/en';
import portugueseMessages from './i18n/pt';

import { VisitorList, VisitorEdit, VisitorIcon } from './visitors';
import { AdminList, AdminEdit, AdminCreate, AdminIcon } from './admins';
import { CommandList, CommandEdit, CommandIcon } from './commands';
import { ChargeCreate, ChargeList, ChargeIcon } from './charges';
import { ProductList, ProductEdit, ProductIcon } from './products';

import dataProviderFactory from './dataProvider';

import Amplify from 'aws-amplify';
import awsmobile from './aws-exports';
Amplify.configure(awsmobile);
Amplify.Logger.LOG_LEVEL = 'WARN';

const i18nProvider = locale => {
  if (locale === 'pt') {
    return portugueseMessages;
  }
  // Always fallback on english
  return englishMessages;
};

class App extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = { dataProvider: null };
  }

  async componentDidMount() {
    const dataProvider = await dataProviderFactory(
      process.env.REACT_APP_DATA_PROVIDER
    );

    this.setState({ dataProvider });
  }

  componentWillUnmount() {
    this.restoreFetch();
  }
  render() {
    const { dataProvider } = this.state;

    if (!dataProvider) {
      return (
        <div className="loader-container">
          <div className="loader">Loading...</div>
        </div>
      );
    }

    return (
      <Admin
        title="Finpartner Backoffice"
        dataProvider={dataProvider}
        customReducers={{ theme: themeReducer }}
        customSagas={sagas}
        customRoutes={customRoutes}
        authProvider={authProvider}
        dashboard={Dashboard}
        loginPage={Login}
        recoverPage={Recover}
        appLayout={Layout}
        menu={Menu}
        locale="pt"
        i18nProvider={i18nProvider}
      >
        <Resource
          name="admins"
          list={AdminList}
          edit={AdminEdit}
          create={AdminCreate}
          icon={AdminIcon}
        />
        <Resource
          name="users"
          list={VisitorList}
          edit={VisitorEdit}
          icon={VisitorIcon}
        />
        <Resource
          name="products"
          list={ProductList}
          edit={ProductEdit}
          icon={ProductIcon}
        />
        <Resource
          name="sales"
          list={CommandList}
          edit={CommandEdit}
          icon={CommandIcon}
          options={{ label: 'Orders' }}
        />
        <Resource
          name="charges"
          create={ChargeCreate}
          list={ChargeList}
          icon={ChargeIcon}
        />
      </Admin>
    );
  }
}

export default App;
