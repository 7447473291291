import React from 'react';
import { connect } from 'react-redux';
import { translate, changeLocale } from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import compose from 'recompose/compose';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import { message, Button } from 'antd';
import { getNews, postNews, deleteNews, updateNews } from '../common/network';
import g from '../common/globalStyles';
import DetailedMessage from '../components/DetailedMessage';
import SingleNews from './SingleNews';

const styles = {
  newsList: {
    height: '80vh',
    ...g.layout.flexVertical,
    ...g.layout.flexStart,
    ...g.layout.alignStart
  },
  actionBar: {
    ...g.layout.flexHorizontal,
    ...g.layout.flexStart,
    ...g.layout.alignCenter,
    paddingBottom: g.global.baseline
  },
  label: { width: '10em', display: 'inline-block' },
  button: { margin: '1em' },
  modal: {
    body: {
      width: '70vw',
      borderTop: '1px solid #e2e2e2',
      borderBottom: '1px solid #e2e2e2',
      marginTop: g.global.baseline,
      paddingTop: g.global.baseline,
      paddingBottom: g.global.baseline * 2,
      ...g.layout.flexVertical,
      ...g.layout.flexCenter,
      ...g.layout.alignCenter,
      textAlign: 'left',
      fontSize: g.global.baseline * 1.2
    },
    list: {
      listStylePosition: 'inside',
      padding: 0,
      paddingLeft: g.global.baseline
    },
    message: {
      marginBottom: g.global.baseline
    },
    error: {
      marginBottom: g.global.baseline,
      color: g.colors.feedback.error
    }
  }
};

class NewsList extends React.Component {
  constructor(props) {
    super(props);
    this.refresh = this.refresh.bind(this);
    this.state = { events: [], eventModalVisible: false };
  }

  async componentDidMount() {
    await this.refresh();
  }

  async componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      await this.refresh();
    }
  }

  async refresh() {
    const response = await getNews();
    if (response && response.result === 'OK') {
      response.data.forEach(news => {
        news.date = moment(news.date);
      });
      response.data.sort((a, b) =>
        moment(b.createdAt).diff(moment(a.createdAt))
      ); //a & b must be moment objects
      this.setState({ news: response.data });
    } else {
      DetailedMessage.error(
        'Ocorreu um erro, não foi possível consultar as notícias.',
        response
      );
    }
  }

  render() {
    return (
      <div style={styles.newsList}>
        <div style={styles.actionBar}>
          <Button
            type="primary"
            ghost
            onClick={async () => {
              const response = await postNews({});
              if (response && response.result === 'OK') {
                await this.refresh();
              } else {
                DetailedMessage.error(
                  'Ocorreu um erro, não foi possível criar nova notícia.',
                  response
                );
              }
            }}
          >
            Criar nova
          </Button>
        </div>
        {this.state.news?.map?.(news => {
          return (
            <SingleNews
              key={news._id}
              data={news}
              onDelete={async _id => {
                const response = await deleteNews(_id);

                if (response && response.result === 'OK') {
                  message.success('Notícia removida');
                  return await this.refresh();
                } else {
                  DetailedMessage.error(
                    'Ocorreu um erro, não foi possível remover a notícia.',
                    response
                  );
                }
              }}
              onUpdate={async data => {
                console.log('NewsList update data: ', data);
                return await updateNews(data._id, data);
              }}
              onError={response => {
                DetailedMessage.error(
                  'Ocorreu um erro, não foi possível consultar a informação da notícia.',
                  response
                );
              }}
              onCancel={async () => {
                await this.refresh();
              }}
              onSuccess={async () => {
                message.success('Alterações guardadas', 4);
                await new Promise(resolve => {
                  setTimeout(resolve, 300);
                });
                await this.refresh();
              }}
            ></SingleNews>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  theme: state.theme,
  locale: state.i18n.locale
});

export default compose(
  connect(
    mapStateToProps,
    {
      changeLocale
    }
  ),
  translate,
  withStyles(styles)
)(NewsList);
