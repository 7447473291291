import ExcelJS from 'exceljs';
import moment from 'moment';

export async function exportClients(clients) {
  let workbook = new ExcelJS.Workbook();

  workbook.creator = 'Backoffice App Finpartner';
  workbook.lastModifiedBy = 'Backoffice App Finpartner';
  workbook.created = new Date();
  workbook.modified = new Date();
  workbook.lastPrinted = new Date();
  workbook.views = [
    {
      x: 0,
      y: 0,
      width: 10000,
      height: 20000,
      firstSheet: 0,
      activeTab: 1,
      visibility: 'visible'
    }
  ];

  let worksheet = workbook.addWorksheet('Clientes App');
  worksheet.columns = [
    { header: 'Nome', key: 'name', width: 30 },
    { header: 'Email', key: 'email', width: 30 },
    { header: 'Criado em', key: 'createdAt', width: 30 },
    { header: 'Nº Docs', key: 'documents', width: 15 },
    { header: 'Nível', key: 'gamificationPoints', width: 15 }
  ];

  formatColors(worksheet);
  console.log(clients?.[0]);

  clients.forEach(client => {
    let row = worksheet.addRow({
      name: client.name,
      email: client.email,
      createdAt: moment(client.createdAt).format('YYYY-MM-DD'),
      documents: client.documents?.length ?? 0,
      gamificationPoints: client.gamification?.level?.name ?? '-'
    });
    row.eachCell(cell => {
      cell.font = {};
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'F8F2F8FF' }
      };
      cell.border = {
        top: { style: 'thin', color: { argb: 'FF444444' } },
        left: { style: 'thin', color: { argb: 'FF444444' } },
        bottom: { style: 'thin', color: { argb: 'FF444444' } },
        right: { style: 'thin', color: { argb: 'FF444444' } }
      };
    });
  });

  return await downloadExcel(workbook);
}

function formatColors(worksheet) {
  //Paint in gray from A1 to L1
  ['A1', 'B1', 'C1', 'D1', 'E1'].forEach(key => {
    worksheet.getCell(key).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'e8e8e8' }
    };
    worksheet.getCell(key).font = {
      size: 13,
      underline: true,
      bold: true
    };
  });
  //create filters
  worksheet.autoFilter = {
    from: 'A1',
    to: 'E1'
  };
}

async function downloadExcel(workbook) {
  try {
    let blob = await workbook2Blob(workbook);

    let filename =
      'app_clients_' + moment().format('YYYY-MM-DD_HH:ss') + '.xlsx';
    return anchorDownload(blob, filename);
  } catch (err) {
    console.error(err);
    return null;
  }
}

const workbook2Blob = async workbook => {
  let buffer = await workbook.xlsx.writeBuffer();
  let blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });
  return blob;
};
const anchorDownload = (blob, filename) => {
  let a = document.createElement('a');
  a.download = filename;
  a.href = URL.createObjectURL(blob);
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  setTimeout(function() {
    URL.revokeObjectURL(a.href);
  }, 1500);
};
