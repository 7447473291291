import React from 'react';
import 'antd/dist/antd.css';
import { Link } from 'react-router-dom';

import g from '../common/globalStyles';

import { getGroup } from '../common/network';

const addResult = (result, targetArray) => {
  if (result && result.result === 'OK' && result.data) {
    targetArray.push(result.data);
  }
};

class CompanyInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      companies: []
    };
  }

  async componentDidMount() {
    const companies = [];
    for (const companyId of this.props.record.usergroups) {
      try {
        const result = await getGroup(companyId);
        addResult(result, companies);
      } catch (error) {
        continue;
      }
    }
    this.setState({ companies });
  }

  render() {
    return (
      <div
        style={{
          width: '100%',
          padding: 12,
          paddingLeft: 0,
          display: 'flex',
          ...g.layout.flexVertical,
          ...g.layout.flexStart,
          ...g.layout.alignStart,
          ...this.props.containerStyle
        }}
      >
        <h3>Companies</h3>
        {this.state.companies.length
          ? this.state.companies.map(company => (
              <Link key={company._id} to={'/companies/' + company._id}>
                {company.name}
              </Link>
            ))
          : '-'}
      </div>
    );
  }
}

export default CompanyInfo;
