import React from 'react';
import autobind from 'auto-bind/react';
import { Checkbox, Tabs, Button, Input, DatePicker, message } from 'antd';
import { translate } from 'react-admin';
import compose from 'recompose/compose';
import moment from 'moment';

import g from '../common/globalStyles';
import ConfirmDelete from '../components/ConfirmDelete';

import UploadFile, { deleteFile } from './UploadFile';

const { TabPane } = Tabs;

const placeholderImage =
  'https://via.placeholder.com/728x400.png?text=No+Image';

const styles = {
  news: {
    border: '1px solid #eaeaea',
    backgroundColor: '#ffffff',
    borderRadius: g.global.baseline / 2,
    width: '100%',
    ...g.layout.flexVertical,
    ...g.layout.flexStart,
    ...g.layout.alignStart,
    marginBottom: g.global.baseline,
    padding: g.global.baseline
  },
  header: {
    width: '100%',
    ...g.layout.flexHorizontal,
    ...g.layout.flexStart,
    ...g.layout.alignStart,
    flexWrap: 'nowrap',
    paddingBottom: g.global.baseline
  },
  headerLeft: {
    position: 'relative',
    flex: 1,
    ...g.layout.flexVertical,
    ...g.layout.flexStart,
    ...g.layout.alignCenter,
    paddingRight: g.global.baseline
  },
  headerImageContainer: {
    minHeight: 100,
    width: '100%',
    paddingBottom: g.global.baseline
  },
  headerImage: {
    width: '100%'
  },
  uploadBar: {
    ...g.layout.flexHorizontal,
    ...g.layout.flexStart,
    ...g.layout.alignCenter,
    width: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    paddingTop: g.global.baseline,
    paddingLeft: g.global.baseline,
    zIndex: 10000
  },
  headerRight: {
    flex: 2,
    ...g.layout.flexVertical,
    ...g.layout.flexStart,
    ...g.layout.alignStart
  },
  tabs: {
    width: '100%'
  },
  body: {
    width: '100%',
    ...g.layout.flexHorizontal,
    ...g.layout.flexStart,
    ...g.layout.alignStart
  },
  newsRow: {
    width: '100%',
    ...g.layout.flexHorizontal,
    ...g.layout.flexStart,
    ...g.layout.alignStart,
    flexWrap: 'nowrap',
    paddingBottom: g.global.baseline
  },
  newsDateContainer: {
    ...g.layout.flexHorizontal,
    ...g.layout.flexStart,
    ...g.layout.alignCenter,
    paddingRight: g.global.baseline
  },
  newsDate: {
    display: 'inline-block',
    borderBottom: '1px solid #e8e8e8'
  },
  newsRowTitleContainer: {
    ...g.layout.flexHorizontal,
    ...g.layout.flexStart,
    ...g.layout.alignCenter,
    minWidth: '15%'
  },
  newsRowTitle: {
    display: 'inline-block',
    borderBottom: '1px solid #e8e8e8'
  }
};

const languages = ['pt_PT', 'en_US', 'cn_CN', 'fr_FR', 'sp_SP'];

class SingleNews extends React.Component {
  constructor(props) {
    super(props);
    this.state = { editing: false, data: props.data };
    autobind(this);
  }

  renderViewComponent() {
    let date = moment(this.state.data?.date);
    if (date.isValid()) {
      date = date.format('YYYY/MM/DD');
    } else {
      date = null;
    }

    const createdAt = this.state.data?.createdAt
      ? moment(this.state.data.createdAt).format('YYYY/MM/DD')
      : null;
    return (
      <div style={styles.header}>
        <div style={styles.headerLeft}>
          <div style={styles.headerImageContainer}>
            <img
              alt=""
              style={styles.headerImage}
              src={
                this.state.data?.image
                  ? this.state.data.image
                  : placeholderImage
              }
            ></img>
            <div style={styles.uploadBar}>
              {this.state.data?.image ? (
                <Button
                  type="danger"
                  loading={this.state.loadingImage}
                  icon="delete"
                  onClick={this.deleteS3Image}
                />
              ) : (
                <UploadFile
                  newsId={this.props.data._id}
                  onSuccess={imageUrl => {
                    this.setState({ loadingImage: true });
                    this.setState(state => {
                      const newData = { ...state.data };
                      newData.image = imageUrl;
                      return { data: newData };
                    });
                    this.setState({ loadingImage: false });
                  }}
                ></UploadFile>
              )}
            </div>
          </div>
          {createdAt && (
            <div style={{ ...styles.newsRow, ...g.layout.alignCenter }}>
              <div style={styles.newsDateContainer}>
                <span style={styles.newsDate}>Criada em</span>
              </div>
              <span>{createdAt}</span>
            </div>
          )}
          <div style={{ ...styles.newsRow, ...g.layout.alignCenter }}>
            <div style={styles.newsDateContainer}>
              <span style={styles.newsDate}>Data da Notícia</span>
            </div>
            <span>{date}</span>
          </div>
          <div style={styles.newsRow}>
            <div style={styles.newsDateContainer}>
              <span style={styles.newsDate}>Estado</span>
            </div>
            <span>{this.state.data?.published ? 'Publicada' : 'Draft'}</span>
          </div>
        </div>

        <div style={styles.headerRight}>
          <Tabs style={styles.tabs} defaultActiveKey="pt_PT">
            {languages.map(lang => {
              if (!this.state.data?.lang?.[lang]?.enabled) {
                return null;
              }
              let title = this.state.data?.lang?.[lang]?.title;
              if (!title) {
                title = 'Sem título';
              }
              let subtitle = this.state.data?.lang?.[lang]?.subtitle;
              if (!subtitle) {
                subtitle = 'Sem subtítulo';
              }
              let fullText = this.state.data?.lang?.[lang]?.fullText;
              if (!fullText) {
                fullText = 'Sem corpo';
              }
              return (
                <TabPane tab={this.props.translate('lang.' + lang)} key={lang}>
                  <div style={styles.newsRow}>
                    <div style={styles.newsRowTitleContainer}>
                      <span style={styles.newsRowTitle}>Título</span>
                    </div>
                    <span>{title}</span>
                  </div>
                  <div style={styles.newsRow}>
                    <div style={styles.newsRowTitleContainer}>
                      <span style={styles.newsRowTitle}>Subtítulo </span>
                    </div>
                    <span>{subtitle}</span>
                  </div>
                  <div style={styles.newsRow}>
                    <div style={styles.newsRowTitleContainer}>
                      <span style={styles.newsRowTitle}>Corpo</span>
                    </div>
                    <span>{fullText}</span>
                  </div>
                </TabPane>
              );
            })}
          </Tabs>
        </div>
      </div>
    );
  }

  async deleteS3Image() {
    this.setState({ loadingImage: true });
    try {
      const s3Response = await deleteFile(this.state.data?.image);

      console.log(s3Response);
    } catch (err) {
      this.setState({ loadingImage: false });
      message.error('Ocorreu um erro, não foi possível apagar a imagem.');
      return;
    }

    const newData = {
      ...this.state.data,
      image: ''
    };
    const response = await this.props.onUpdate(newData);
    console.log(response);
    if (!response || !(response.result === 'OK')) {
      message.error('Ocorreu um erro, não foi possível guardar as alterações.');
      this.setState({
        loadingImage: false
      });
      return;
    }

    this.setState({ data: newData, loadingImage: false });
  }

  renderEditComponent() {
    let date = moment(this.state.data?.date);
    if (!date.isValid()) {
      date = null;
    }
    return (
      <div style={styles.header}>
        <div style={styles.headerLeft}>
          <div style={styles.headerImageContainer}>
            <img
              alt=""
              style={styles.headerImage}
              src={
                this.state.data?.image
                  ? this.state.data.image
                  : placeholderImage
              }
            ></img>
            <div style={styles.uploadBar}>
              {this.state.data?.image ? (
                <Button
                  type="danger"
                  loading={this.state.loadingImage}
                  icon="delete"
                  onClick={this.deleteS3Image}
                />
              ) : (
                <UploadFile
                  newsId={this.props.data._id}
                  onSuccess={imageUrl => {
                    this.setState({ loadingImage: true });
                    this.setState(state => {
                      const newData = { ...state.data };
                      newData.image = imageUrl;
                      return { data: newData };
                    });
                    this.setState({ loadingImage: false });
                  }}
                ></UploadFile>
              )}
            </div>
          </div>

          <div style={{ ...styles.newsRow, ...g.layout.alignCenter }}>
            <div style={styles.newsDateContainer}>
              <span style={styles.newsDate}>Data</span>
            </div>
            <DatePicker
              value={date}
              onChange={(date, dateString) => {
                // date is already a moment object
                this.setState(state => {
                  const newState = { ...state };
                  newState.data.date = date;
                  return newState;
                });
              }}
            />
          </div>
          <div style={styles.newsRow}>
            <div style={styles.newsDateContainer}>
              <span style={styles.newsDate}>Estado</span>
            </div>
            <span>Publicada</span>
            <Checkbox
              style={{ marginLeft: g.global.baseline }}
              checked={this.state.data.published}
              disabled={!this.state.editing}
              onChange={e => {
                const checked = e.target.checked;
                this.setState(state => {
                  const newState = { ...state };
                  newState.data.published = checked;
                  return newState;
                });
              }}
            ></Checkbox>
          </div>
        </div>

        <div style={styles.headerRight}>
          <div>
            {languages.map(lang => {
              const enabled = this.state.data?.lang?.[lang]?.enabled;
              return (
                <Checkbox
                  key={lang}
                  checked={enabled}
                  onChange={async e => {
                    const checked = e.target.checked;
                    if (
                      !checked &&
                      !checkMinimumLanguages(this.state.data?.lang)
                    ) {
                      message.error(
                        'Necessária pelo menos uma linguagem activa'
                      );
                      return;
                    }

                    if (this.state.data)
                      this.setState(state => {
                        const newData = { ...state.data };
                        newData.lang[lang].enabled = checked;
                        return { data: newData };
                      });
                  }}
                >
                  {this.props.translate('lang.' + lang)}
                </Checkbox>
              );
            })}
          </div>
          <Tabs style={styles.tabs} defaultActiveKey="pt_PT">
            {languages.map(lang => {
              if (!this.state.data?.lang?.[lang]?.enabled) {
                return null;
              }
              let title = this.state.data?.lang?.[lang]?.title;
              if (!title) {
                title = '';
              }
              let subtitle = this.state.data?.lang?.[lang]?.subtitle;
              if (!subtitle) {
                subtitle = '';
              }
              let fullText = this.state.data?.lang?.[lang]?.fullText;
              if (!fullText) {
                fullText = '';
              }
              return (
                <TabPane tab={this.props.translate('lang.' + lang)} key={lang}>
                  <div style={styles.newsRow}>
                    <div style={styles.newsRowTitleContainer}>
                      <span style={styles.newsRowTitle}>Título</span>
                    </div>
                    <Input
                      defaultValue={title}
                      allowClear
                      onChange={e => {
                        const value = e.target.value;
                        this.setState(state => {
                          const newState = { ...state };
                          newState.data.lang[lang].title = value;
                          return newState;
                        });
                      }}
                    />
                  </div>
                  <div style={styles.newsRow}>
                    <div style={styles.newsRowTitleContainer}>
                      <span style={styles.newsRowTitle}>Subtítulo </span>
                    </div>
                    <Input
                      defaultValue={subtitle}
                      allowClear
                      onChange={e => {
                        const value = e.target.value;
                        this.setState(state => {
                          const newState = { ...state };
                          newState.data.lang[lang].subtitle = value;
                          return newState;
                        });
                      }}
                    />
                  </div>
                  <div style={styles.newsRow}>
                    <div style={styles.newsRowTitleContainer}>
                      <span style={styles.newsRowTitle}>Corpo</span>
                    </div>
                    <Input.TextArea
                      defaultValue={fullText}
                      allowClear
                      onChange={e => {
                        const value = e.target.value;
                        this.setState(state => {
                          const newState = { ...state };
                          newState.data.lang[lang].fullText = value;
                          return newState;
                        });
                      }}
                    />
                  </div>
                </TabPane>
              );
            })}
          </Tabs>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div
        style={{
          ...styles.news,
          backgroundColor: this.state.data?.published ? '#EEF7FF' : '#ffffff'
        }}
      >
        {this.state.editing
          ? this.renderEditComponent()
          : this.renderViewComponent()}
        <div>
          {this.state.editing ? (
            <>
              <Button
                type="primary"
                style={{ marginRight: g.global.baseline }}
                onClick={async () => {
                  const response = await this.props.onUpdate(this.state.data);
                  if (response && response.result === 'OK') {
                    this.setState({ editing: false });
                    await this.props.onSuccess?.();
                  } else {
                    message.error(
                      'Ocorreu um erro, não foi possível guardar as alterações.'
                    );
                  }
                }}
              >
                Gravar
              </Button>
              <Button
                type="default"
                style={{ marginRight: g.global.baseline }}
                onClick={async () => {
                  await this.props.onCancel?.();
                  this.setState({ editing: false, data: this.props.data });
                }}
              >
                Cancelar
              </Button>
            </>
          ) : (
            <Button
              type="primary"
              ghost
              style={{ marginRight: g.global.baseline }}
              onClick={() => {
                this.setState({ editing: true });
              }}
            >
              Editar
            </Button>
          )}

          <ConfirmDelete
            isConfirm={false}
            showCancel={true}
            deleteLabel={'Apagar'}
            cancelLabel={'Cancelar'}
            confirmLabel={'Confirmar!'}
            buttonStyle={{
              marginRight: g.global.baseline,
              float: 'right'
            }}
            onDelete={async () => {
              try {
                this.setState({ loadingImage: true });
                const s3Response = await deleteFile(this.props.data?.image);
                console.log('S3 delete response: ', s3Response);
                this.setState({ loadingImage: false });
              } catch (err) {
                message.error(
                  'Ocorreu um erro, não foi possível apagar a imagem associada à notícia.'
                );
                this.setState({ loadingImage: false });
                return;
              }
              if (this.props.onDelete) {
                await this.props.onDelete(this.props.data._id);
              }
            }}
          />
        </div>
      </div>
    );
  }
}

const checkMinimumLanguages = newsLanguages => {
  // At least one language must be enabled
  let count = 0;
  for (const key in newsLanguages) {
    const lang = newsLanguages[key];
    if (lang.enabled) {
      count++;
    }
  }
  return count > 1;
};

export default compose(translate)(SingleNews);
