const constants = {
  languages: [
    { name: 'Português', code: 'pt-PT' },
    { name: 'Chinês', code: 'zh-CN' },
    { name: 'Inglês', code: 'en-US' },
    { name: 'Francês', code: 'fr-FR' },
    { name: 'Espanhol', code: 'es-ES' }
  ]
};

export default constants;
