import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_CHECK, AUTH_ERROR } from 'react-admin';
import { Auth } from 'aws-amplify';

export default (type, params) => {
    if (type === AUTH_LOGIN) {
        const { username, password } = params;
        return Auth.signIn(username, password)
            .then(userSession => {
                localStorage.setItem(
                    'access_token',
                    userSession.signInUserSession.accessToken.jwtToken
                );

                return Promise.resolve();
            })
            .catch(err => {
                return Promise.reject(err);
            });
    }
    if (type === AUTH_LOGOUT) {
        localStorage.removeItem('username');
        localStorage.removeItem('access_token');
        Auth.signOut();
        return Promise.resolve();
    }
    if (type === AUTH_ERROR) {
        return Promise.resolve();
    }
    if (type === AUTH_CHECK) {
        return Auth.currentAuthenticatedUser()
            .then(
                () =>
                    localStorage.getItem('access_token')
                        ? Promise.resolve()
                        : Promise.reject()
            )
            .catch(() => Promise.reject());
    }
    return Promise.reject('Unkown method');
};
