import React from 'react';
import { connect } from 'react-redux';
import { translate, changeLocale } from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import compose from 'recompose/compose';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import { message, Modal } from 'antd';
import {
  getFiscalEvents,
  postFiscalEvent,
  deleteFiscalEvent,
  updateFiscalEvent
} from '../common/network';
import globalStyles from '../common/globalStyles';
import DetailedMessage from '../components/DetailedMessage';
import Event from './Event';
import CreateEvent from './CreateEvent';

const styles = {
  label: { width: '10em', display: 'inline-block' },
  button: { margin: '1em' },
  modal: {
    body: {
      width: '70vw',
      borderTop: '1px solid #e2e2e2',
      borderBottom: '1px solid #e2e2e2',
      marginTop: globalStyles.global.baseline,
      paddingTop: globalStyles.global.baseline,
      paddingBottom: globalStyles.global.baseline * 2,
      ...globalStyles.layout.flexVertical,
      ...globalStyles.layout.flexCenter,
      ...globalStyles.layout.alignCenter,
      textAlign: 'left',
      fontSize: globalStyles.global.baseline * 1.2
    },
    list: {
      listStylePosition: 'inside',
      padding: 0,
      paddingLeft: globalStyles.global.baseline
    },
    message: {
      marginBottom: globalStyles.global.baseline
    },
    error: {
      marginBottom: globalStyles.global.baseline,
      color: globalStyles.colors.feedback.error
    }
  }
};

const messages = {
  date: 'Data',
  time: 'Hora',
  event: 'Evento',
  allDay: 'Dia Inteiro',
  week: 'Semana',
  work_week: 'Semana de Trabalho',
  day: 'Dia',
  month: 'Mês',
  previous: 'Anterior',
  next: 'Seguinte',
  yesterday: 'Ontem',
  tomorrow: 'Amanhã',
  today: 'Hoje',
  agenda: 'Agenda',
  noEventsInRange: 'Sem resultados nesta gama de datas.',
  showMore: function showMore(total) {
    return '+' + total + 'mais';
  }
};

const localizer = momentLocalizer(moment); // or globalizeLocalizer

class FiscalCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.refresh = this.refresh.bind(this);
    this.state = { events: [], eventModalVisible: false };
  }

  components = {
    event: props => (
      <Event
        {...props}
        onDelete={async id => {
          const response = await deleteFiscalEvent(id);

          if (response && response.result === 'OK') {
            message.success('Evento apagado');
            return await this.refresh();
          } else {
            DetailedMessage.error(
              'Ocorreu um erro, não foi possível apagar o evento.',
              response
            );
          }
        }}
        action={async data => await updateFiscalEvent(data._id, data)}
        onError={response => {
          DetailedMessage.error(
            'Ocorreu um erro, não foi possível consultar a informação do evento.',
            response
          );
        }}
        onCancel={() => {}}
        onSuccess={async () => {
          message.success('Alterações guardadas', 4);
          await new Promise(resolve => {
            setTimeout(resolve, 500);
          });
          await this.refresh();
        }}
      ></Event>
    )
  };

  async componentDidMount() {
    await this.refresh();
  }

  async componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      await this.refresh();
    }
  }

  async refresh() {
    const response = await getFiscalEvents();
    if (response && response.result === 'OK') {
      response.data.forEach(event => {
        event.start = moment(event.start);
        event.start.hour(13);
        event.end = moment(event.end);
        event.end.hour(13);
        event.slots.forEach(slot => (slot = moment(slot)));
      });
      this.setState({ events: response.data });
    } else {
      DetailedMessage.error(
        'Ocorreu um erro, não foi possível consultar os eventos.',
        response
      );
    }
  }

  render() {
    return (
      <div style={{ height: '80vh' }}>
        <Calendar
          components={this.components}
          selectable={true}
          onSelectSlot={slotInfo =>
            this.setState({
              selectedDateInfo: slotInfo
            })
          }
          localizer={localizer}
          events={this.state.events}
          startAccessor="start"
          endAccessor="end"
          messages={messages}
        />
        {this.state.selectedDateInfo && (
          <Modal
            bodyStyle={styles.modal.body}
            visible={!!this.state.selectedDateInfo}
            closeable
            onCancel={() => {
              this.setState({ selectedDateInfo: null });
            }}
            style={{ minWidth: '70vw' }}
            footer={null}
          >
            {this.state.selectedDateInfo && (
              <CreateEvent
                data={{
                  start: this.state.selectedDateInfo.start,
                  end: this.state.selectedDateInfo.end,
                  slots: this.state.selectedDateInfo.slots,
                  allDay: true
                }}
                onError={response => {
                  DetailedMessage.error(
                    'Ocorreu um erro, não foi possível consultar a informação do evento.',
                    response
                  );
                }}
                onCancel={() =>
                  this.setState({
                    selectedDateInfo: null
                  })
                }
                onSuccess={async () => {
                  message.success('Alterações guardadas', 4);
                  await new Promise(resolve => {
                    setTimeout(resolve, 500);
                  });
                  await this.refresh();
                  this.setState({ selectedDateInfo: null });
                }}
                action={async data => await postFiscalEvent(data)}
              />
            )}
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  theme: state.theme,
  locale: state.i18n.locale
});

export default compose(
  connect(
    mapStateToProps,
    {
      changeLocale
    }
  ),
  translate,
  withStyles(styles)
)(FiscalCalendar);
