import React from 'react';
import { Route } from 'react-router-dom';
import Configuration from './configuration/Configuration';
import FiscalCalendar from './calendar/FiscalCalendar';
import NewsList from './news/NewsList';
import Meetings from './meetings';
import Companies from './companies';
import Fini from './fini';
import NifRequests from './nifRequests';
import SingleCompany from './companies/SingleCompany';
import Recover from './Recover';

export default [
  <Route
    exact
    key="configuration"
    path="/configuration"
    component={Configuration}
  />,
  <Route exact key="recover" path="/recover" component={Recover} noLayout />,
  <Route exact key="calendar" path="/calendar" component={FiscalCalendar} />,
  <Route exact key="news" path="/news" component={NewsList} />,
  <Route exact key="meetings" path="/meetings" component={Meetings} />,
  <Route exact key="fini" path="/fini" component={Fini} />,
  <Route key="nif" path="/nif" component={NifRequests} />,
  <Route exact key="companies" path="/companies" component={Companies} />,
  <Route
    path="/companies/:id"
    children={({ match }) => (
      <SingleCompany id={match.params.id}></SingleCompany>
    )}
  />
];
