// JS-language related stuff goes in here
import moment from 'moment';
export const debug = true;
//eslint-disable-next-line
export const log = debug ? console.log.bind(window.console) : () => {};

export const get = (p, o) => {
  if (typeof p === 'string') {
    p = p.split('.');
  }
  return p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o);
};

export const mrg = array => {
  if (!Array.isArray(array)) {
    throw new Error('styles must be an array');
  }

  return array.reduce(
    (previous, current) => Object.assign(previous, current),
    {}
  );
};

export const validateHTTPMethod = (method, validMethods) => {
  if (!validMethods.includes(method)) {
    throw new Error(
      'Invalid method. Method should be one of: ' +
        validMethods.reduce((prev, curr) => prev + ', ' + curr, '')
    );
  }
  return method;
};

const formats = [
  'YYYY-MM-DD',
  'M-D-YYYY',
  'MM-DD-YYYY',
  'DD-MM-YYYY',
  'D/MM/YYYY',
  'DD/MM/YY',
  'M-D-YY',
  'DD-MM-YY',
  'D/MM/YY',
  'DD/MM/YY'
];
export const tryParseDate = (string, options = {}) => {
  let result;
  const basic = moment(string);
  if (basic.isValid()) {
    return basic;
  }
  for (let format of formats) {
    result = moment(string, format);
    if (result.isValid()) {
      return result;
    }
  }
  if (options.nullable) {
    return null;
  }
  return moment('invalid date');
};

export const handleRequest = (request, erroMessage) => {
  if (!request || request.result !== 'OK' || !request.data) {
    throw request;
  }
  return request;
};

export const anchorLinkDownload = url => {
  let a = document.createElement('a');
  a.href = url;
  a.target = '_blank';
  a.rel = 'noopener noreferrer';
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
