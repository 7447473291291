import { Auth } from 'aws-amplify';
import { address } from '../common/variables';

export { address };

const BE_ADDR = address;

// JS way of creating Enums:
const errors = Object.freeze({
  noResponse: {
    ok: false,
    error: -100,
    result: 'failure',
    message: 'Client: No response from server'
  },
  deserializationError: {
    ok: false,
    error: -200,
    result: 'failure',
    message: 'Client: error deserializing JSON data'
  },
  internalServerError: {
    ok: false,
    error: 500,
    result: 'failure',
    message: 'Internal Server Error'
  },
  notFound: { ok: false, error: 404, result: 'failure', message: 'Not found' },
  unauthorized: {
    ok: false,
    error: 403,
    result: 'failure',
    message: 'unauthorized'
  }
});

const handleResponse = async (response, awsInfo = null) => {
  if (!response || !response.status) {
    return errors.noResponse;
  } else if (response.status === 404) {
    return errors.notFound;
  } else if (response.status === 403) {
    return errors.unauthorized;
  } else if (response.status === 500) {
    return errors.internalServerError;
  } else {
    let body;
    try {
      body = await response.json();
    } catch (err) {
      return errors.deserializationError;
    }

    let result = { result: 'OK', ok: true, data: body };

    if (awsInfo) {
      result.AWSinfo = awsInfo;
    }

    return result;
  }
};

const authenticate = async () => {
  let userInfo;
  try {
    userInfo = await Auth.currentAuthenticatedUser({
      bypassCache: false // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    });
  } catch (err) {
    return null;
  }
  return userInfo;
};

export { handleResponse, authenticate };

/* Useful stuff starts here */

export async function getAllUsers() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'users', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

export async function getFiscalEvents() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'events', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

export async function postFiscalEvent(data) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'events', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(data)
  });
  return await handleResponse(response);
}

export async function postFiniMessage(finiMessage) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'fini', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(finiMessage)
  });
  return await handleResponse(response);
}

export async function deleteFiniMessage(messageId) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'fini/' + messageId, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

export async function markFiniMessageRead(messageId) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'fini/seen/' + messageId, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

export async function deleteFiscalEvent(id) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'events/' + id, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

export async function updateFiscalEvent(id, data) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'events/' + id, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(data)
  });
  return await handleResponse(response);
}

export async function postMeeting(userId, data) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + `users/${userId}/meeting`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(data)
  });
  return await handleResponse(response);
}

export async function deleteMeeting(userId, meetingId) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(
    BE_ADDR + `users/${userId}/meeting/${meetingId}`,
    {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        jwt: userInfo.signInUserSession.accessToken.jwtToken
      }
    }
  );

  return await handleResponse(response);
}

export async function updateMeeting(userId, meetingId, data) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const target = `users/${userId}/meeting/${meetingId}`;

  const response = await fetch(BE_ADDR + target, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(data)
  });
  return await handleResponse(response);
}

export async function getAllMeetings() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'users/meetings', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

/* User Groups*/

export async function putGroup(groupId, data) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const target = `groups/${groupId}`;

  const response = await fetch(BE_ADDR + target, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(data)
  });
  return await handleResponse(response);
}

export async function putUser(userId, data) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const target = `users/${userId}`;

  const response = await fetch(BE_ADDR + target, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(data)
  });
  return await handleResponse(response);
}

export async function getGroups() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const target = 'groups';

  const response = await fetch(BE_ADDR + target, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

export async function getGroup(_id) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const target = 'groups/' + _id;

  const response = await fetch(BE_ADDR + target, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

export async function postGroup(data) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'groups', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(data)
  });
  return await handleResponse(response);
}

export async function deleteDocument(userId, documentId) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(
    BE_ADDR + `documents/user/${userId}/${documentId}`,
    {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        jwt: userInfo.signInUserSession.accessToken.jwtToken
      }
    }
  );

  return await handleResponse(response);
}

export async function deleteCompanyDocument(userGroupId, documentId) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(
    BE_ADDR + `groups/${userGroupId}/documents/${documentId}`,
    {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        jwt: userInfo.signInUserSession.accessToken.jwtToken
      }
    }
  );

  return await handleResponse(response);
}

export async function deleteCompany(companyId) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + `groups/${companyId}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });

  return await handleResponse(response);
}

export async function getNews() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'news/all', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

export async function getSingleNews(_id) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'news/' + _id, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

export async function postNews(data) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'news', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(data)
  });
  return await handleResponse(response);
}

export async function deleteNews(id) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'news/' + id, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

export async function updateNews(id, data) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'news/' + id, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(data)
  });
  return await handleResponse(response);
}

export async function postUserAction(data) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'useractions', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(data)
  });
  return await handleResponse(response);
}

export async function deleteUserAction(_id) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'useractions/' + _id, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

export async function putReward(rewardId, data) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'rewards/' + rewardId, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(data)
  });
  return await handleResponse(response);
}

export async function markRewardAsRead(rewardIds) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'rewards/seen', {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(rewardIds)
  });
  return await handleResponse(response);
}

export async function getAllNifRequests() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'nifrequest/all', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

export async function putNifRequest(_id, body) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'nifrequest/' + _id, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(body)
  });
  return await handleResponse(response);
}
