import React from 'react';
import ReactDOM from 'react-dom';
//import YellowBox from 'yellowbox-react';
import App from './App';
import './index.css';

console.ignoredYellowBox = ['[HMR]'];

ReactDOM.render(
  <div>
    <App />
    {/* <YellowBox /> */}
  </div>,
  document.getElementById('root')
);
