const actionTypes = {
  usedPayments: {
    points: 10,
    type: 'usedPayments',
    label: 'Utilização de Pagamentos'
  },
  documentsBeforeDay: {
    points: 10,
    type: 'documentsBeforeDay',
    label: 'Documentos Antes de Dia 10'
  },
  referral: { points: 10, type: 'referral', label: 'Referenciação de Cliente' },
  serviceAcquired: {
    points: 10,
    type: 'serviceAcquired',
    label: 'Aquisição de Serviço'
  }
};

const levels = {
  starter: { id: 0, name: 'starter', min: 0, max: 29 },
  explorer: { id: 1, name: 'explorer', min: 30, max: 49 },
  adventurer: { id: 2, name: 'adventurer', min: 50, max: 69 },
  pioneer: { id: 3, name: 'pioneer', min: 70, max: Infinity }
};

const getLevel = points => {
  if (points < 0) {
    return levels.starter; //jsut in case
  }
  for (const levelName in levels) {
    const level = levels[levelName];
    if (points >= level.min && points <= level.max) {
      return level;
    }
  }
};

const dateRangeDays = 90;

const getPointsFromActions = actions => {
  let points = 0;
  for (const action of actions) {
    points += action.points;
  }
  if (isNaN(points)) {
    points = 0;
  }

  return points;
};

export { actionTypes, levels, getLevel, dateRangeDays, getPointsFromActions };
