import React from 'react';
import {
    translate,
    TextField,
    DateField,
    Edit,
    ReferenceField,
    SelectInput,
    SimpleForm
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';

import Basket from './Basket';

const CommandTitle = translate(({ record, translate }) => (
    <span>
        {translate('resources.sales.name', { smart_count: 1 })} #{
            record.reference
        }
    </span>
));

const editStyles = {
    clear: { clear: 'both' }
};

const CommandEdit = ({ classes, ...props }) => (
    <Edit title={<CommandTitle />} {...props} actions={null}>
        <SimpleForm>
            <Basket />
            <DateField source="date" />
            <ReferenceField source="userId" reference="users">
                <TextField source="name" />
            </ReferenceField>
            <SelectInput
                source="status"
                choices={[
                    { id: 'processed', name: 'processed' },
                    { id: 'ordered', name: 'ordered' }
                ]}
            />
        </SimpleForm>
    </Edit>
);

export default withStyles(editStyles)(CommandEdit);
