import React from 'react';
import autobind from 'auto-bind';
import { Empty, Checkbox } from 'antd';

import { AutoSizer, Table, Column } from 'react-virtualized';
import op from 'object-path';
import moment from 'moment';

import './RewardList.scss';

const defaultRowHeight = 80;
const defaultHeaderHeight = 60;

const cellRenderer = ({ cellData, dataKey }) => {
  return <div className={`inner-cell`}>{cellData}</div>;
};

const dateRenderer = ({ cellData }) => {
  const date = moment(cellData);
  const cellContent = date.isValid() ? date.format('YYYY-MM-DD') : '';
  return <div className={`inner-cell`}>{cellContent}</div>;
};

const columnProps = {
  index: {
    style: {
      width: 20,
      flexGrow: 0,
      paddingLeft: 10
    },
    props: {}
  },

  date: {
    style: {
      width: 80,
      flexGrow: 1
    },
    props: { cellRenderer: dateRenderer }
  },
  type: {
    style: {
      width: 80,
      flexGrow: 2
    },
    props: { cellRenderer }
  },
  done: {
    style: {
      width: 20,
      flexGrow: 2
    },
    props: {}
  },
  seenByUser: {
    style: {
      width: 20,
      flexGrow: 2
    },
    props: {}
  }
};

export default class RewardList extends React.Component {
  tableRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {};

    autobind(this);
  }

  rewardDoneRenderer({ rowData }) {
    return (
      <div className={`inner-cell`}>
        <Checkbox
          checked={rowData.done}
          onChange={async e => {
            await this.props.update?.(rowData._id, {
              done: !rowData.done
            });
          }}
        ></Checkbox>
      </div>
    );
  }

  rewardViewedRenderer({ rowData }) {
    return (
      <div className={`inner-cell`}>
        <Checkbox
          checked={rowData.seenByUser}
          onChange={async e => {
            if (process.env.NODE_ENV === 'development') {
              await this.props.update?.(rowData._id, {
                seenByUser: !rowData.seenByUser
              });
            }
          }}
        ></Checkbox>
      </div>
    );
  }

  rowGetter({ index }) {
    return this.props.data?.[index] ?? {};
  }

  cellDataGetter({ dataKey, rowData }) {
    return op.get(rowData, dataKey);
  }

  headerRenderer({ label }) {
    return (
      <div
        className={
          'd-flex flex-col justify-content-start align-items-center w-100 h-100'
        }
      >
        <div
          className={
            'inner-header d-flex justify-content-start align-items-center w-100 '
          }
        >
          <div>{label}</div>
        </div>
      </div>
    );
  }

  rowRenderer(props) {
    const {
      className,
      columns,
      index,
      key,
      onRowClick,
      onRowDoubleClick,
      onRowMouseOut,
      onRowMouseOver,
      onRowRightClick,
      rowData,
      style
    } = props;

    const a11yProps = { 'aria-rowindex': index + 1 };

    if (
      onRowClick ||
      onRowDoubleClick ||
      onRowMouseOut ||
      onRowMouseOver ||
      onRowRightClick
    ) {
      a11yProps['aria-label'] = 'row';
      a11yProps.tabIndex = 0;

      if (onRowClick) {
        a11yProps.onClick = event => onRowClick({ event, index, rowData });
      }
      if (onRowDoubleClick) {
        a11yProps.onDoubleClick = event =>
          onRowDoubleClick({ event, index, rowData });
      }
      if (onRowMouseOut) {
        a11yProps.onMouseOut = event =>
          onRowMouseOut({ event, index, rowData });
      }
      if (onRowMouseOver) {
        a11yProps.onMouseOver = event =>
          onRowMouseOver({ event, index, rowData });
      }
      if (onRowRightClick) {
        a11yProps.onContextMenu = event =>
          onRowRightClick({ event, index, rowData });
      }
    }

    return (
      <div
        {...a11yProps}
        className={className}
        key={key}
        role="row"
        style={style}
      >
        {columns}
      </div>
    );
  }

  getRowHeight({ index }) {
    return defaultRowHeight;
  }

  render() {
    return (
      <div id="rewards" className="d-block pos-relative w-100 h-100">
        <div className="triangle"></div>
        <AutoSizer>
          {({ width, height }) => (
            <Table
              ref={this.tableRef}
              headerHeight={defaultHeaderHeight}
              height={height}
              noRowsRenderer={() => (
                <Empty
                  className="w-100 h-100 d-flex flex-col justify-content-center align-items-center"
                  description={<span>Sem dados</span>}
                ></Empty>
              )}
              overscanRowCount={10}
              rowHeight={this.getRowHeight}
              rowGetter={this.rowGetter}
              rowRenderer={this.rowRenderer}
              rowClassName={({ index }) => {
                return index % 2 === 0 ? 'even-row' : 'odd-row';
              }}
              rowCount={this.props.data?.length ?? 0}
              width={width}
            >
              <Column
                label="N"
                cellDataGetter={this.cellDataGetter}
                headerRenderer={this.headerRenderer}
                cellRenderer={({ rowIndex, rowData }) => (
                  <div className="inner-cell text-muted index-cell">
                    {rowIndex + 1}
                  </div>
                )}
                dataKey="index"
                disableSort={true}
                {...columnProps['index'].style}
                {...columnProps['index'].props}
              />
              <Column
                dataKey="createdAt"
                label="Data"
                cellDataGetter={this.cellDataGetter}
                disableSort={true}
                headerRenderer={this.headerRenderer}
                {...columnProps['date'].style}
                {...columnProps['date'].props}
              />
              <Column
                disableSort={true}
                cellDataGetter={this.cellDataGetter}
                label="Tipo"
                dataKey="type"
                headerRenderer={this.headerRenderer}
                {...columnProps['type'].style}
                {...columnProps['type'].props}
              />
              <Column
                disableSort={true}
                cellDataGetter={this.cellDataGetter}
                label="Tratada"
                dataKey="done"
                headerRenderer={this.headerRenderer}
                {...columnProps['done'].style}
                {...columnProps['done'].props}
                cellRenderer={this.rewardDoneRenderer}
              />
              <Column
                disableSort={true}
                cellDataGetter={this.cellDataGetter}
                label="Vista pelo Utilizador"
                dataKey="seenByUser"
                headerRenderer={this.headerRenderer}
                {...columnProps['seenByUser'].style}
                {...columnProps['seenByUser'].props}
                cellRenderer={this.rewardViewedRenderer}
              />
            </Table>
          )}
        </AutoSizer>
      </div>
    );
  }
}
