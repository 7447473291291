import React, { Fragment } from 'react';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import 'moment/locale/pt';
import { Storage } from 'aws-amplify';
import { Switch, Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon, XIcon } from '@heroicons/react/outline';

import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import { matchSearch } from '../common/search';
import exportNifRequests from './exportExcel';

import { getAllNifRequests, putNifRequest } from '../common/network';
import awsconfig from '../aws-exports';
import { anchorLinkDownload } from '../common/util';
import { DatePicker } from 'antd';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';

const customPrefix = { public: 'nifrequests/' };
const storageConfig = {
  bucket: awsconfig.aws_user_files_s3_bucket,
  region: awsconfig.aws_user_files_s3_bucket_region
  // customPrefix
};

Storage.configure(storageConfig);

const itemsPerPage = 5;

const investmentTypes = {
  investment_portugal: {
    key: 'investment_portugal',
    label: 'Portugal',
    price: 390
  },
  investment_abroad: {
    key: 'investment_abroad',
    label: 'Estrangeiro',
    price: 150
  },
  all: {
    key: 'all',
    label: 'Todos'
  }
};

// async function readFileAsync(file) {
//   return new Promise((resolve, reject) => {
//     let reader = new FileReader();

//     reader.readAsArrayBuffer(file);
//     reader.onload = e => {
//       const content = e.target.result;

//       // console.log('onload', content);
//       resolve(content);
//     };
//     reader.onerror = reject;
//   });
// }

//     fullName: 'Cliente Teste',
//     idCard: '123456',
//     fullAddress: 'Rua do Exemplo, 0000-000 Lisboa',
//     email: 'exemplo@nomail.com',
//     telephone: '+351 912345678',
//     wechat: '@exemplo',
//     documents: [
//       {
//         s3Id: 'dummy',
//         title: 'Resident Title'
//       },
//       {
//         s3Id: 'dummy2',
//         title: 'Blabla'
//       }
//     ],
//     finpartnerComments: '',
//     maritalStatus: 'Single',
function parseDocFile(e, userInfo) {
  let content = e.target.result;
  try {
    //console.log('content', content);
    let zip = new PizZip(content);
    let doc = new Docxtemplater(zip);

    let todayDate = moment();
    let fullDateEN = todayDate.locale('en').format('MMMM Do YYYY');
    let fullDatePT = todayDate.locale('pt').format('DD MMMM YYYY');
    // Render the document (Replace {first_name} by John, {last_name} by Doe, ...)
    doc.render({
      // fullName: userInfo.fullName,
      // fullAddress: userInfo.fullAddress,
      // idCard: userInfo.idCard,
      ...userInfo,
      fullDateEN,
      fullDatePT
    });

    let out = doc.getZip().generate({
      type: 'blob',
      mimeType:
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      // compression: DEFLATE adds a compression step.
      // For a 50MB output document, expect 500ms additional CPU time
      compression: 'DEFLATE'
    });

    saveByteArray(userInfo.idCard + '.docx', out);
  } catch (e) {
    console.error('Please select a valid docx file.', e);

    return null;
  }
}

function saveByteArray(reportName, byte) {
  var blob = new Blob([byte]);
  var link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  var fileName = reportName;
  link.download = fileName;
  link.click();
}
function handleFileSelect(uploadFiles, userInfo) {
  console.log('analyzing uploadFiles: ' + uploadFiles);
  let files = uploadFiles.files;
  if (files.length < 1) {
    //  message.error('Please select a valid docx file.');
    return;
  }
  if (files > 1) {
    console.log('more then one file');
  } else {
    let file = files[0];
    console.log('analyzing file: ' + file);
    var reader = new FileReader();
    reader.onload = e => parseDocFile(e, userInfo);
    reader.readAsArrayBuffer(file);
  }
}

function Items({
  currentItems,
  className = '',
  setPaid,
  setProcessFinished,
  setComments,
  setAssignedNif
}) {
  return (
    <div className={`flex flex-col ${className}`}>
      {currentItems &&
        currentItems.map((item, index) => {
          const hasDate = moment(item.createdAt).isValid();
          return (
            <div
              key={item?.fullName ?? index}
              className={`w-full min-h-1/5 p-3 bg-white mb-6 rounded-md inline-flex flex-wrap content-start shadow-md transform transition hover:shadow-lg`}
            >
              <div className="w-1/2 flex flex-col h-auto pr-3 border-r border-gray-100">
                <div className="text-lg pb-2 mb-3 border-b border-gray-100">
                  Dados de Cliente
                </div>

                <div className="w-full h-auto flex items-center pb-3">
                  <div
                    className={`w-1/2 h-auto flex items-center text-gray-800 `}
                  >
                    <div className="w-full flex items-center ">
                      <div className="mr-3 min-w-12 text-gray-500 font-normal select-none uppercase bg-gray-100 rounded-full px-3 py-1">
                        Nome
                      </div>
                      <h3 className="text-base font-normal">{item.fullName}</h3>
                    </div>
                  </div>
                  {hasDate && (
                    <div className="w-1/2 h-auto flex items-center text-gray-800 ">
                      <div className="w-full flex items-center ">
                        <div className="mr-3 min-w-12 text-gray-500 font-normal select-none uppercase bg-gray-100 rounded-full px-3 py-1">
                          Criado em
                        </div>
                        <h3 className="text-base font-normal">
                          {moment(item.createdAt).format('YYYY/MM/DD')}
                        </h3>
                      </div>
                    </div>
                  )}
                </div>
                <div className="w-full h-auto flex items-center text-gray-800">
                  <div className="w-full flex items-center ">
                    <div className="mr-3 min-w-12 text-gray-500 font-normal select-none uppercase bg-gray-100 rounded-full px-3 py-1">
                      Morada
                    </div>
                    <div className="text-base font-normal">
                      {item.fullAddress}
                    </div>
                  </div>
                </div>

                <div className="w-full flex flex-wrap items-center content-start pt-3 mb-3">
                  <div className="w-full h-auto flex items-center pb-3">
                    <div className="min-w-1/2 h-auto text-gray-800 pr-3 pb-3">
                      <div className="w-full flex items-center ">
                        <div className="mr-3 text-gray-500  font-normal select-none uppercase bg-gray-100 px-3 py-1 rounded-full">
                          ID Cliente
                        </div>
                        <div className="text-base font-normal">
                          {item.idCard}
                        </div>
                      </div>
                    </div>

                    <div className="w-1/2 h-auto flex items-center text-gray-800 ">
                      <div className="w-full flex items-center ">
                        <div className="mr-3 min-w-12 text-gray-500 font-normal select-none uppercase bg-gray-100 rounded-full px-3 py-1">
                          ID Único
                        </div>
                        <h3 className="text-base font-normal">
                          {item.uniqueId}
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div className="min-w-1/2 h-auto text-gray-800 pb-3">
                    <div className="w-full flex items-center ">
                      <div className="mr-3 text-gray-500 font-normal select-none uppercase bg-gray-100 px-3 py-1 rounded-full">
                        Email
                      </div>
                      <div className="text-base font-normal">{item.email}</div>
                    </div>
                  </div>
                  <div className="min-w-1/2 h-auto text-gray-800 pr-3">
                    <div className="w-full flex items-center ">
                      <div className="mr-3 text-gray-500 font-normal select-none uppercase bg-gray-100 px-3 py-1 rounded-full">
                        Tel
                      </div>
                      <div className="text-base font-normal">
                        {item.telephone}
                      </div>
                    </div>
                  </div>
                  <div className="min-w-1/2 h-auto text-gray-800">
                    <div className="w-full flex items-center ">
                      <div className="mr-3 text-gray-500 font-normal select-none uppercase bg-gray-100 px-3 py-1 rounded-full">
                        WeChat
                      </div>
                      <div className="text-base font-normal">{item.wechat}</div>
                    </div>
                  </div>
                  <div className="min-w-1/2 h-auto text-gray-800">
                    <div className="w-full flex items-center ">
                      <div className="mr-3 text-gray-500 font-normal select-none uppercase bg-gray-100 px-3 py-1 rounded-full">
                        Tip. Investimento
                      </div>
                      <div className="text-base font-normal">
                        {!item.investmentType
                          ? investmentTypes.investment_portugal.label
                          : investmentTypes[item.investmentType]?.label ?? '-'}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="min-w-1/2 h-auto text-gray-800">
                  <div className="w-full flex items-center ">
                    <div className="mr-3 text-gray-500 font-normal select-none uppercase bg-gray-100 px-3 py-1 rounded-full">
                      Preço
                    </div>
                    <div className="text-base font-normal">
                      {investmentTypes[item.investmentType]?.price ?? '-'}
                    </div>
                  </div>
                </div>

                <div className="w-full pt-3 flex flex-wrap items-center content-start">
                  <div className="w-full flex flex-col px-3 py-2 text-gray-800 bg-gray-100 rounded-xl">
                    <div className="pr-3 text-gray-500 min-w-12 pb-1 mb-1 font-normal select-none border-b uppercase">
                      Documentos
                    </div>
                    {item?.documents?.map(doc => {
                      return (
                        <div
                          key={doc.s3Key}
                          className="text-base font-normal underline cursor-pointer hover:translate-x-1 transform transition"
                          onClick={async () => {
                            const s3SignedUrl = await Storage.get(doc.s3Key, {
                              level: 'public',
                              customPrefix,
                              download: false
                            });
                            // const fileUrl =
                            //   'https://' +
                            //   storageConfig.bucket +
                            //   '.s3.amazonaws.com/' +
                            //   customPrefix +
                            //   '/' +
                            //   doc.s3Key;

                            console.log(s3SignedUrl);
                            anchorLinkDownload(s3SignedUrl);
                          }}
                        >
                          {doc.originalFileName}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="w-1/2 flex flex-col justify-between items-start h-auto pl-3">
                <div className="w-full flex flex-col h-auto ">
                  <div className="w-full  pb-2 mb-3 border-b border-gray-100 flex justify-between items-center">
                    <div className="text-lg">Validação Finpartner</div>

                    <input
                      type="file"
                      name="file"
                      id="myFile"
                      hidden
                      onChange={() => {
                        handleFileSelect(
                          window.document.getElementById('myFile'),
                          item
                        );
                      }}
                    />

                    <button
                      className="flex px-3 py-1 rounded-full text-white bg-sky-600 hover:bg-sky-500 transition items-center relative"
                      onClick={() => {
                        //Hack to avoid file modifications - file was not accepted by pizzip
                        window.document.getElementById('myFile').value = '';
                        window.document.getElementById('myFile').click();
                      }}
                    >
                      Exportar minuta
                    </button>
                  </div>

                  <div className="w-full mb-3 flex flex-wrap items-center">
                    <div
                      style={{ minHeight: '6rem' }}
                      className="w-full flex flex-col px-3 py-2 text-gray-800 bg-gray-100 rounded-xl"
                    >
                      <div className="pr-3 text-gray-500 min-w-12 pb-1 mb-1 font-normal select-none border-b uppercase">
                        Comentários p/ Utilizador
                      </div>
                      <textarea
                        className="block w-full my-2 px-1 py-1 text-gray-700 bg-transparent transition ease-in-out m-0 border rounded-md border-transparent focus:border-sky-500 focus:outline-none mb-2"
                        id="exampleFormControlTextarea1"
                        rows="4"
                        placeholder="Comentário para utilizador"
                        value={item?.finpartnerComments ?? ''}
                        onChange={e => {
                          const value = e.target.value;
                          if (value !== item?.finpartnerComments) {
                            return setComments(item, value);
                          }
                        }}
                      ></textarea>
                    </div>
                    <input
                      className="block w-full my-2 px-3 py-1 text-gray-800 bg-gray-100 bg-transparent transition ease-in-out m-0 border rounded-md border-transparent focus:border-sky-500 focus:outline-none "
                      rows="4"
                      placeholder="NIF Atribuido"
                      value={item?.assignedNif ?? ''}
                      onChange={e => {
                        const value = e.target.value;
                        if (value !== item.assignedNif) {
                          return setAssignedNif(item, value);
                        }
                      }}
                    ></input>
                  </div>
                </div>
                <div className="w-full flex items-center justify-end">
                  <div className="w-1/2 h-auto flex justify-between items-center pb-3 text-gray-800 pl-3 mr-3 pr-1 py-1 bg-gray-100 rounded-full">
                    <div className="mr-3 text-gray-500 select-none uppercase">
                      Pago
                    </div>
                    <Switch
                      checked={item?.paid}
                      onChange={() => {
                        setPaid(item, !item?.paid);
                        // paidByAdminId, datePaid
                      }}
                      className={`${
                        item?.paid ? 'bg-green-600' : 'bg-red-400'
                      } relative transition inline-flex items-center rounded-full`}
                      style={{ height: '2rem', width: '4rem' }}
                    >
                      <span
                        style={{ height: '1.5rem', width: '1.5rem' }}
                        className={`${
                          item.paid ? 'translate-x-9' : 'translate-x-1'
                        } inline-block transform rounded-full transition bg-white`}
                      />
                    </Switch>
                  </div>
                  <div className="w-1/2  h-auto flex justify-between items-center pb-3 text-gray-800 pl-3 pr-1 py-1 bg-gray-100 rounded-full">
                    <div className="mr-3 text-gray-500 select-none uppercase">
                      Finalizado
                    </div>
                    <Switch
                      checked={item?.processFinished}
                      onChange={() => {
                        setProcessFinished(item, !item?.processFinished);
                        // finishedByAdminId, dateFinished
                      }}
                      className={`${
                        item?.processFinished ? 'bg-green-600' : 'bg-red-400'
                      } relative transition inline-flex items-center rounded-full`}
                      style={{ height: '2rem', width: '4rem' }}
                    >
                      <span
                        style={{ height: '1.5rem', width: '1.5rem' }}
                        className={`${
                          item.processFinished
                            ? 'translate-x-9'
                            : 'translate-x-1'
                        } inline-block transform rounded-full transition bg-white`}
                      />
                    </Switch>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

const NifRequests = ({ location }) => {
  // ---- BE fetch

  const [nifCache, setNifCache] = React.useState([]);
  const [nifRequests, setNifRequests] = React.useState([]);
  const fetchNifRequests = async () => {
    let isFinished;
    if (location?.pathname === '/nif/archive') {
      isFinished = true;
    } else {
      isFinished = false;
    }

    try {
      const response = await getAllNifRequests();

      const filtered = response?.data?.filter(req => {
        return req.processFinished === isFinished;
      });

      console.log(response, filtered);

      setNifCache(filtered);
      setNifRequests(filtered);
    } catch (err) {
      // nothing, leave empty array
      setNifCache([]);
    }
  };
  React.useEffect(() => {
    fetchNifRequests();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  React.useEffect(() => {
    fetchNifRequests();
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  // ---- FE Pagination
  const [currentItems, setCurrentItems] = React.useState(null);
  const [pageCount, setPageCount] = React.useState(0);
  const [itemOffset, setItemOffset] = React.useState(0);

  const computeCurrentItems = () => {
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const current = nifRequests.slice(itemOffset, endOffset);
    setCurrentItems(current);
    setPageCount(Math.ceil(nifRequests.length / itemsPerPage));
  };
  React.useEffect(() => {
    computeCurrentItems();
  }, [itemOffset, itemsPerPage, nifCache, nifRequests]); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePageClick = event => {
    const newOffset = (event.selected * itemsPerPage) % nifCache.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  // ---- Search
  const [search, setSearch] = React.useState('');
  const [dateAfter, setDateAfter] = React.useState(null);
  const [investmentType, setInvestmentType] = React.useState(
    investmentTypes.all
  );

  React.useEffect(() => {
    if (!search || !search?.trim()) {
      computeCurrentItems();
      return;
    }
    setCurrentItems(nifCache.filter(d => matchSearch(search, d)));
  }, [search, itemOffset]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (!dateAfter) {
      computeCurrentItems();
      return;
    }
    setCurrentItems(
      nifRequests.filter(d =>
        moment(d.createdAt).isSameOrAfter(moment(dateAfter))
      )
    );
  }, [dateAfter, itemOffset]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (!investmentType) {
      computeCurrentItems();
      return;
    }
    setCurrentItems(
      nifRequests.filter(d => d.investmentType === investmentType.key)
    );
  }, [investmentType, itemOffset]); // eslint-disable-line react-hooks/exhaustive-deps

  // ---- Mutations and network

  const setField = fieldName => async (targetItem, fieldValue) => {
    const response = await putNifRequest(targetItem._id, {
      [fieldName]: fieldValue
    });

    if (!response.ok) {
      console.error(response);
      return;
    }

    console.log(response);

    const updateNifList = prevItems => {
      const newItems = prevItems.map(item => {
        if (!item._id || item?._id !== targetItem?._id) {
          return item;
        }
        return { ...item, [fieldName]: fieldValue };
      });

      return newItems;
    };

    setNifCache(updateNifList);
    setNifRequests(updateNifList);
  };

  const searchSection = (
    <>
      <input
        placeholder="Pesquisar"
        className="bg-white border px-2 mr-2 rounded-md border-gray-300 text-base w-full h-full "
        style={{ height: '2rem', maxWidth: '15rem' }}
        onChange={e => {
          const value = e?.target?.value;
          setSearch(value);
        }}
      ></input>
      <Listbox
        value={investmentType ?? investmentTypes.all}
        onChange={setInvestmentType}
      >
        <div className="relative z-50 min-w-36 mr-2">
          <Listbox.Button className="relative w-full h-full px-2 cursor-default rounded-md border border-gray-300 bg-white py-1 pl-1 pr-10 text-left font-base font-medium focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
            <span className="block truncate">{investmentType?.label}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 w-full rounded-md bg-white py-1 text-base shadow-lg focus:outline-none sm:text-sm">
              {Object.values(investmentTypes).map(iType => {
                return (
                  <Listbox.Option
                    key={iType.key}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 text-gray-900`
                    }
                    value={iType}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? 'font-medium' : 'font-normal'
                          }`}
                        >
                          {iType.label}
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-aky-600">
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                );
              })}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>

      <DatePicker
        placeholder={'Criado depois de'}
        value={dateAfter}
        showTime={false}
        mode="date"
        format="YYYY-MM-DD HH:mm"
        onChange={date => {
          setDateAfter(date?.minute(0).hour(0));
        }}
      />
      <div
        className={`flex justify-center items-center text-gray-300 cursor-pointer hover:text-gray-700 transition`}
        title="Limpar Filtros e Pesquisa"
        onClick={() => {
          setSearch('');
          setDateAfter(null);
          setInvestmentType(investmentTypes.all);
        }}
      >
        <XIcon
          style={{ height: '2rem' }}
          className={'w-auto h-full mr-2 stroke-current'}
        ></XIcon>
      </div>
    </>
  );

  return (
    <div className="w-full h-full flex pt-8 lg:pt-0 flex-col text-gray-800">
      <div className="w-full flex-0 flex justify-between items-center mb-3">
        <div className="flex items-center">
          <div className="text-2xl mr-4">Pedidos de NIF</div>
          <Link
            to="/nif"
            className={`flex h-16 px-2 py-2 rounded-md border border-sky-500 hover:bg-sky-500 ${
              location?.pathname === '/nif' ? 'bg-sky-500 text-white' : ''
            } hover:text-white transition items-center relative mr-2`}
          >
            Ativos
          </Link>
          <Link
            Link
            to="/nif/archive"
            className={`flex h-16 px-2 py-2 rounded-md border border-sky-500 hover:bg-sky-500 ${
              location?.pathname === '/nif/archive'
                ? 'bg-sky-500 text-white'
                : ''
            } hover:text-white transition items-center relative mr-2`}
          >
            Terminados
          </Link>
        </div>
        <div className="flex items-center">
          <button
            className="flex h-16 px-2 py-2 rounded-md text-gray-700 border border-sky-600 hover:bg-sky-600 hover:text-white transition items-center relative mr-3"
            onClick={() => {
              setCurrentItems([]);
              fetchNifRequests();
            }}
          >
            Atualizar
          </button>
          <button
            className="flex h-16 px-2 py-2 rounded-md text-white bg-sky-600 hover:bg-sky-500 transition items-center relative mr-2"
            onClick={() => exportNifRequests(currentItems)}
          >
            Exportar XLS
          </button>
        </div>
      </div>
      <div className="w-full flex-0 flex justify-between items-center mb-3">
        <div className="flex items-center">
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            previousLabel="<"
            style={{ paddingBottom: '6rem' }}
            breakClassName="rounded-md z-50 bg-sky-50 border-sky-500 text-sky-600 p-2 mx-2 border text-sm font-medium"
            pageCount={pageCount}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            hrefAllControls
            containerClassName="flex justify-center items-center text-sm font-medium"
            pageLinkClassName="rounded-md bg-sky-50 border-sky-300 py-2 px-3 mx-2 border transform transition hover:bg-sky-500 hover:border-sky-500 hover:text-white hover:scale-105"
            previousLinkClassName="rounded-md bg-sky-50 border-sky-300 py-2 px-3 mx-2 border transform transition hover:bg-sky-500 hover:border-sky-500 hover:text-white hover:scale-105"
            nextLinkClassName="rounded-md bg-sky-50 border-sky-300 py-2 px-3 mx-2 border transform transition hover:bg-sky-500 hover:border-sky-500 hover:text-white hover:scale-105"
            activeLinkClassName="bg-sky-500 border-sky-500 text-white "
          />
        </div>
        <div className="flex items-stretch justify-end">{searchSection}</div>
      </div>

      <div
        style={{ paddingBottom: '8rem' }}
        className="w-full flex-1 flex flex-col rounded-xl"
      >
        <Items
          className=""
          currentItems={currentItems}
          setPaid={setField('paid')}
          setProcessFinished={setField('processFinished')}
          setComments={setField('finpartnerComments')}
          setAssignedNif={setField('assignedNif')}
        />
      </div>
    </div>
  );
};

export default withRouter(NifRequests);

// const dummies = [
//   {
//     fullName: 'Cliente Teste',
//     idCard: '123456',
//     fullAddress: 'Rua do Exemplo, 0000-000 Lisboa',
//     email: 'exemplo@nomail.com',
//     telephone: '+351 912345678',
//     wechat: '@exemplo',
//     documents: [
//       {
//         s3Id: 'dummy',
//         title: 'Resident Title'
//       },
//       {
//         s3Id: 'dummy2',
//         title: 'Blabla'
//       }
//     ],
//     finpartnerComments: '',
//     maritalStatus: 'Single',
//     paid: false,
//     datePaid: new Date(),
//     paidByAdminId: new Date(),
//     processFinished: false,
//     dateFinished: new Date(),
//     finishedByAdminId: new Date(),
//     userId: '3f8c0239-1a9d-4a0f-a99e-6b31f2b30b23',
//     _id: '0',
//     createdAt: new Date(),
//     updatedAt: new Date()
//   }
// ];

// dummies.push({ ...dummies[0], _id: '1' });
// dummies.push({ ...dummies[0], _id: '2' });
// dummies.push({ ...dummies[0], _id: '3' });
// dummies.push({ ...dummies[0], _id: '4' });
// dummies.push({ ...dummies[0], _id: '5' });
// dummies.push({ ...dummies[0], _id: '6' });
// dummies.push({ ...dummies[0], _id: '7' });
// dummies.push({ ...dummies[0], _id: '8' });
// dummies.push({ ...dummies[0], _id: '9' });
// dummies.push({ ...dummies[0], _id: '10' });
