import { connect } from 'react-redux';
import { Layout } from 'react-admin';

const darkTheme = {
  palette: {
    type: 'dark', // Switching the dark mode on is a single property value change.
    primary: {
      light: '#7986cb',
      main: '#118ab2',
      dark: '#118ab2',
      contrastText: '#fff'
    },
    secondary: {
      main: '#118ab2'
    }
  }
};

const lightTheme = {
  palette: {
    secondary: {
      light: 'rgb(44,55,128)',
      main: 'rgb(44,55,128)',
      dark: '#001064',
      contrastText: '#fff'
    }
  }
};

export default connect(
  state => ({
    theme: state.theme === 'dark' ? darkTheme : lightTheme
  }),
  {}
)(Layout);
