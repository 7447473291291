import React from 'react';
import { connect } from 'react-redux';
import { translate, changeLocale } from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import compose from 'recompose/compose';
import { Tabs } from 'antd';

import CreateGroup from './Create';
import ListGroups from './List';
import g from '../common/globalStyles';

const { TabPane } = Tabs;

const styles = {
  label: { width: '10em', display: 'inline-block' },
  button: { margin: '1em' },
  modal: {
    body: {
      width: '70vw',
      borderTop: '1px solid #e2e2e2',
      borderBottom: '1px solid #e2e2e2',
      marginTop: g.global.baseline,
      paddingTop: g.global.baseline,
      paddingBottom: g.global.baseline * 2,
      ...g.layout.flexVertical,
      ...g.layout.flexCenter,
      ...g.layout.alignCenter,
      textAlign: 'left',
      fontSize: g.global.baseline * 1.2
    },
    list: {
      listStylePosition: 'inside',
      padding: 0,
      paddingLeft: g.global.baseline
    },
    message: {
      marginBottom: g.global.baseline
    },
    error: {
      marginBottom: g.global.baseline,
      color: g.colors.feedback.error
    }
  },
  list: {
    body: {
      width: '100%',
      paddingBottom: g.global.baseline * 4
    },
    itemContainer: {
      width: '100%',
      ...g.layout.flexHorizontal,
      ...g.layout.flexBetween,
      paddingLeft: g.global.baseline * 2,
      paddingRight: g.global.baseline * 2,
      paddingTop: g.global.baseline,
      paddingBottom: g.global.baseline
    },
    item: {
      width: '100%',
      borderBottom: '1px solid #E3E7ED'
    },
    itemMeta: { width: '90%', height: '100%', alignItems: 'center' },
    column: {
      position: 'relative',
      ...g.global.flexVertical,
      ...g.global.flexStart,
      ...g.global.alignCenter,
      paddingBottom: g.global.baseline
    }
  },
  form: {
    width: '100%',
    paddingRight: g.global.baseline
  },
  item: {
    ...g.global.flexHorizontal,
    ...g.global.flexStart,
    ...g.global.alignCenter,
    width: '100%',
    fontWeight: 100
  }
};

class Meetings extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  async componentDidMount() {}

  render() {
    return (
      <div
        style={{
          height: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <div>
          <h2>Companies</h2>
        </div>
        <Tabs style={{ flex: 1 }} defaultActiveKey="manage">
          <TabPane style={{ height: '100%' }} tab="Manage" key="manage">
            <ListGroups />
          </TabPane>
          <TabPane style={{ height: '100%' }} tab="Create" key="create">
            <CreateGroup></CreateGroup>
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  theme: state.theme,
  locale: state.i18n.locale
});

export default compose(
  connect(mapStateToProps, {
    changeLocale
  }),
  translate,
  withStyles(styles)
)(Meetings);
