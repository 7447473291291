import React from 'react';
import { Form, Input, Tabs, Button, DatePicker } from 'antd';
import moment from 'moment';
import globalStyles from '../common/globalStyles';
import { languages } from '../common/variables';

import { translate } from 'react-admin';
import compose from 'recompose/compose';

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const styles = {
  body: {
    width: '100%',
    padding: globalStyles.global.baseline
  },
  simpleContainer: {
    width: '100%',
    marginBottom: globalStyles.global.baseline
  },
  container: {
    width: '100%',
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexStart,
    ...globalStyles.layout.alignCenter,
    marginBottom: globalStyles.global.baseline
  },

  item: {
    fontWeight: 100,
    marginRight: globalStyles.global.baseline
  },
  tab: {
    ...globalStyles.layout.flexVertical,
    ...globalStyles.layout.flexStart,
    ...globalStyles.layout.alignStart,
    fontWeight: 100
  },
  form: {
    description: {
      width: '100%'
    }
  }
};

const generateSlots = function(startDate, endDate) {
  var dates = [];

  var currDate = moment(startDate).startOf('day');
  var lastDate = moment(endDate).startOf('day');


  dates.push(currDate.format());
  while (currDate.add(1, 'days').diff(lastDate) <= 0) { 
    dates.push(currDate.format());
  }

  console.log(dates);

  return dates;
};

class CreateEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      eventData: { slots: props.data.slots },
      sending: false
    };
    console.log('slots: ', props.data);
    this.submit = this.submit.bind(this);
    this.clearValues = this.clearValues.bind(this);
  }

  clearValues() {
    this.setState({ data: { start: null, end: null } });
  }

  async submit(event) {
    event.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        this.setState({ sending: true });
        const event = {
          ...this.state.data,
          lang: values
        };

        const response = await this.props.action(event);
        if (response && response.result === 'OK') {
          this.setState({ data: event });
          if (this.props.onSuccess) {
            await this.props.onSuccess(response, event);
            this.setState({ sending: false });
          }
        } else {
          if (this.props.onError) {
            await this.props.onError(response);
          } else {
            this.setState({ sending: false });
          }
        }
      }
    });
  }

  render() {
    const data = this.state.data;
    const { getFieldDecorator } = this.props.form;

    return (
      <Form onSubmit={this.submit} style={styles.body}>
        <div style={styles.container}>
          <span style={styles.item}>Datas</span>
          <RangePicker
            onChange={dates =>
              this.setState({
                data: {
                  ...this.state.data,
                  slots: generateSlots(dates[0], dates[1]),
                  start: dates[0],
                  end: dates[1]
                }
              })
            }
            defaultValue={[moment(data.start), moment(data.end)]}
          />
        </div>
        <div style={{ width: '100%' }}>
          <Tabs defaultActiveKey="pt_PT">
            {languages.map(lang => {
              return (
                <TabPane
                  tabBarStyle={styles.tab}
                  tab={this.props.translate('lang.' + lang)}
                  key={lang}
                >
                  <div style={styles.tab}>
                    <Form.Item>
                      {getFieldDecorator(lang + '.title', {
                        initialValue:
                          (data.lang &&
                            data.lang[lang] &&
                            data.lang[lang].title) ||
                          '',
                        rules: [
                          {
                            required: lang === 'pt_PT',
                            message: 'Obrigatório'
                          }
                        ]
                      })(
                        <Input
                          disabled={this.state.sending}
                          style={{
                            width: '100%',
                            paddingRight: globalStyles.global.baseline
                          }}
                          placeholder="Título"
                        />
                      )}
                    </Form.Item>
                    <Form.Item style={styles.form.description}>
                      {getFieldDecorator(lang + '.description', {
                        initialValue:
                          (data.lang &&
                            data.lang[lang] &&
                            data.lang[lang].title) ||
                          '',
                        rules: [
                          {
                            required: lang === 'pt_PT',
                            message: 'Obrigatório'
                          }
                        ]
                      })(
                        <Input.TextArea
                          disabled={this.state.sending}
                          style={{
                            width: '100%',
                            paddingRight: globalStyles.global.baseline
                          }}
                          placeholder="Descrição"
                        />
                      )}
                    </Form.Item>
                  </div>
                </TabPane>
              );
            })}
          </Tabs>
        </div>
        <div style={styles.container}>
          <Button
            style={styles.item}
            loading={this.state.sending}
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Enviar
          </Button>
          {this.props.onClose && (
            <Button
              style={styles.item}
              loading={this.state.sending}
              type="danger"
              ghost
              onClick={() => this.props.onClose()}
            >
              Cancelar
            </Button>
          )}
        </div>
      </Form>
    );
  }
}

const EventForm = Form.create({ name: 'event_form' })(CreateEvent);
export default compose(translate)(EventForm);
