import React from 'react';
import { connect } from 'react-redux';
import { translate, changeLocale } from 'react-admin';
import { Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import compose from 'recompose/compose';
import { Tabs, List, Row, Col, Checkbox, Button, InputNumber } from 'antd';
import moment from 'moment';
import autobind from 'auto-bind/react';
import '../index.css';

import EditableField from '../components/EditableField';
import UploadFile, {
  GetFileButton,
  DeleteFileButton,
  GetMultipleFilesButton,
  DeleteMultipleFilesButton
} from './UploadFile';
import g from '../common/globalStyles';
import { getGroup, putGroup } from '../common/network';
import TagSelector from '../components/TagSelector';
import { XIcon } from '@heroicons/react/outline';

const { TabPane } = Tabs;

const styles = {
  label: { width: '10em', display: 'inline-block' },
  button: { margin: '1em' },
  modal: {
    body: {
      width: '70vw',
      borderTop: '1px solid #e2e2e2',
      borderBottom: '1px solid #e2e2e2',
      marginTop: g.global.baseline,
      paddingTop: g.global.baseline,
      paddingBottom: g.global.baseline * 2,
      ...g.layout.flexVertical,
      ...g.layout.flexCenter,
      ...g.layout.alignCenter,
      textAlign: 'left',
      fontSize: g.global.baseline * 1.2
    },
    list: {
      listStylePosition: 'inside',
      padding: 0,
      paddingLeft: g.global.baseline
    },
    message: {
      marginBottom: g.global.baseline
    },
    error: {
      marginBottom: g.global.baseline,
      color: g.colors.feedback.error
    }
  },

  list: {
    body: {
      width: '100%',
      paddingBottom: g.global.baseline * 4
    },
    itemContainer: {
      width: '100%',
      ...g.layout.flexHorizontal,
      ...g.layout.flexBetween,
      paddingLeft: g.global.baseline * 2,
      paddingRight: g.global.baseline * 2,
      paddingTop: g.global.baseline,
      paddingBottom: g.global.baseline
    },
    item: {
      width: '100%',
      borderBottom: '1px solid #E3E7ED'
    },
    itemMeta: { width: '90%', height: '100%', alignItems: 'center' },
    column: {
      position: 'relative',
      ...g.global.flexVertical,
      ...g.global.flexStart,
      ...g.global.alignCenter,
      paddingBottom: g.global.baseline
    }
  },
  form: {
    width: '100%',
    paddingRight: g.global.baseline
  },
  item: {
    ...g.global.flexHorizontal,
    ...g.global.flexStart,
    ...g.global.alignCenter,
    width: '100%',
    fontWeight: 100
  },
  flexVertical: {
    width: '100%',
    height: 'auto',
    overflowY: 'scroll',
    ...g.layout.flexVertical,
    ...g.layout.flexStart,
    ...g.layout.alignStart,
    paddingBottom: g.global.baseline
  },
  fileList: {
    width: '100%',
    borderTop: '1px solid #efefef',
    borderBottom: '1px solid #efefef'
  },
  ...g
};

class SingleCompany extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      data: null,
      selected: [],
      tagState: { error: false, message: '' },
      searchTags: [],
      filteredDocuments: [],
      yearSearch: undefined,
      monthSearch: undefined
    };
    this.onSaveSuccess = this.props.onSaveSuccess || (() => {});
    this.onSaveError = this.props.onSaveError || (() => {});

    autobind(this);
  }

  async refresh() {
    const request = await getGroup(this.props.id);
    if (request && request.result === 'OK' && request.data) {
      this.setState({
        company: request.data,
        currentDocuments: request.data.data.documents
      });
    }
  }

  async componentDidMount() {
    return await this.refresh();
  }

  async updateDocumentTags(document, tagValueArray, replaceTags = true) {
    console.log(this.state.company.data.documents);
    const docIndex = this.state.company.data.documents?.findIndex(
      doc => !!doc.id && doc.id === document.id
    );

    if (docIndex < 0) {
      return;
    }

    const targetDoc = this.state.company.data.documents?.[docIndex];
    console.log(docIndex, targetDoc);
    if (replaceTags) {
      targetDoc.tags = tagValueArray;
    } else {
      console.log({ doctags: targetDoc.tags, tagValueArray });
      const tagSet = new Set();
      for (const tag of targetDoc.tags ?? []) {
        tagSet.add(tag);
      }
      for (const tag of tagValueArray ?? []) {
        tagSet.add(tag);
      }
      targetDoc.tags = Array.from(tagSet);
      console.log({ settags: Array.from(tagSet) });
    }

    const result = await putGroup(this.props.id, {
      [`data.documents.${docIndex}.tags`]: replaceTags
        ? tagValueArray
        : targetDoc.tags
    });

    if (!result.ok) {
      this.setState({
        tagState: { error: true, message: 'Erro a actualizar tags' }
      });
      return;
    } else {
      this.setState({
        tagState: { error: false, message: 'Documento actualizado' }
      });
      const that = this;
      setTimeout(function() {
        that.setState({ tagState: { error: false, message: '' } });
      }, 500);
    }

    this.setState(prevState => {
      const newDocs = [];
      for (const oldDoc of prevState.currentDocuments) {
        newDocs.push({ ...oldDoc });
      }
      return {
        company: { ...prevState.company },
        currentDocuments: newDocs
      };
    });

    return result;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.yearSearch !== this.state.yearSearch ||
      prevState.monthSearch !== this.state.monthSearch ||
      prevState.searchTags !== this.state.searchTags
    ) {
      let baseDocuments = this.state.company?.data?.documents;
      // year
      if (this.state.yearSearch) {
        baseDocuments = baseDocuments?.filter(doc => {
          return (
            doc.createdAt &&
            moment(doc.createdAt).year() === this.state.yearSearch
          );
        });
      }

      // month
      if (!isNaN(this.state.monthSearch)) {
        baseDocuments = baseDocuments?.filter(doc => {
          return (
            doc.createdAt &&
            moment(doc.createdAt).month() === this.state.monthSearch
          );
        });
      }

      // tags
      if (this.state.searchTags?.length) {
        baseDocuments = baseDocuments?.filter(doc => {
          let hasAllTags = true;
          for (const searchTag of this.state.searchTags) {
            if (!doc?.tags?.some(docTag => searchTag === docTag)) {
              hasAllTags = false;
              break;
            }
          }
          return hasAllTags;
        });
      }
      this.setState({ currentDocuments: baseDocuments });
    }

    if (prevState.company !== this.state.company) {
      this.setState({ currentDocuments: this.state.company.data.documents });
    }
  }

  render() {
    const hasDocumentsSelected = this.state.selected?.length > 0;
    const canDeleteMany = !!this.state.selected?.length;
    return (
      <div style={{ height: '100%', position: 'relative' }}>
        <div>
          <h2>{this.state.company ? this.state.company.name : 'Company'}</h2>
        </div>
        <Tabs style={{ height: '100%' }} defaultActiveKey="documents">
          <TabPane tab="Ficheiros &amp; Dados" key="documents">
            {this.state.company && (
              <div style={styles.flexVertical}>
                <div style={styles.flexVertical}>
                  <h3>Notas da Empresa</h3>
                  <EditableField
                    type={EditableField.Types.textarea}
                    minWidthBefore={0}
                    innerStyle={{ marginBottom: 0 }}
                    span={24}
                    autosize={{ minRows: 2, maxRows: 2 }}
                    key={this.props.id}
                    editable
                    title={'Notas'}
                    value={this.state.company.notes}
                    onSave={async value => {
                      const result = await putGroup(this.props.id, {
                        notes: value
                      });
                      return result;
                    }}
                    onSaveSuccess={async result => {
                      await this.onSaveSuccess(result);
                    }}
                    onSaveError={async err => {
                      await this.onSaveError(err);
                    }}
                  ></EditableField>
                </div>
                <div style={{ ...g.layout.flexVertical, width: '100%' }}>
                  <div
                    style={{
                      ...g.layout.flexHorizontal,
                      ...g.layout.flexBetween,
                      flexGrow: 1,
                      paddingBottom: '1em'
                    }}
                  >
                    <div style={{ ...g.layout.flexVertical }}>
                      <h3>Operações de Grupo</h3>
                      <div style={{ ...g.layout.flexHorizontal, flexGrow: 1 }}>
                        <GetMultipleFilesButton
                          disabled={!hasDocumentsSelected}
                          style={{ marginRight: '1em' }}
                          fileInfo={this.state.selected}
                          label="Descarregar"
                          key="download"
                        />

                        <DeleteMultipleFilesButton
                          disabled={!canDeleteMany}
                          fileInfo={this.state.selected}
                          groupId={this.state.company._id}
                          label="Apagar"
                          key="delete"
                          onSuccess={async () => await this.refresh()}
                        />
                        <Button
                          className="ml-2"
                          onClick={() => {
                            this.setState(prevState => {
                              if (prevState.selected?.length) {
                                return { selected: [] };
                              } else {
                                return {
                                  selected: this.state.currentDocuments ?? []
                                };
                              }
                            });
                          }}
                        >
                          Selecionar Visíveis
                        </Button>
                      </div>
                    </div>
                    <div className="flex items-center justify-end">
                      {!!this.state.selected?.length && (
                        <div
                          className="pr-1 w-auto min-w-0"
                          style={{ ...g.layout.flexVertical, width: '10rem' }}
                        >
                          <h3 className=" flex w-auto">
                            Tags<br></br>múltiplas
                          </h3>
                          <TagSelector
                            placeholder=""
                            document={{ tags: [] }} // don't show any tags here
                            onChange={async (document, tagValueArray) => {
                              for (const selectedDoc of this.state.selected) {
                                const document = this.state.currentDocuments?.find(
                                  doc => doc.id === selectedDoc.id
                                );
                                console.log('DOCUMENT: ', document);
                                if (!document) {
                                  console.log(
                                    'doc not found in state: ',
                                    selectedDoc
                                  );
                                  continue;
                                }
                                await this.updateDocumentTags(
                                  document,
                                  tagValueArray,
                                  false
                                );
                              }
                            }}
                          ></TagSelector>
                        </div>
                      )}

                      <div
                        className="pr-1 w-auto items-start min-w-0"
                        style={{
                          ...g.layout.flexVertical,
                          width: '10rem'
                        }}
                      >
                        <h3 className=" flex w-auto">
                          Ano de <br></br>Upload
                        </h3>
                        <div className="flex items-center">
                          <InputNumber
                            value={this.state.yearSearch}
                            onChange={value => {
                              this.setState({ yearSearch: value });
                            }}
                            min={2020}
                            max={2100}
                            style={{
                              minWidth: '4rem',
                              flex: 1,
                              height: '2.4rem',
                              borderRadius: 0
                            }}
                          ></InputNumber>
                          <XIcon
                            className="stroke-1 cursor-pointer hover:scale-110 transform transition"
                            style={{ height: '2rem', width: '2rem' }}
                            onClick={() => {
                              this.setState({
                                yearSearch: undefined
                              });
                            }}
                          ></XIcon>
                        </div>
                      </div>
                      <div
                        className="pr-1 w-auto min-w-0"
                        style={{ ...g.layout.flexVertical, maxWidth: '8rem' }}
                      >
                        <h3 className="w-auto">
                          Mês de <br></br>Upload
                        </h3>
                        <div className="flex items-center">
                          <InputNumber
                            value={
                              !isNaN(this.state.monthSearch)
                                ? this.state.monthSearch + 1
                                : undefined
                            }
                            onChange={value => {
                              this.setState({ monthSearch: value - 1 });
                            }}
                            min={1}
                            max={12}
                            style={{
                              minWidth: '4rem',
                              height: '2.4rem',
                              borderRadius: 0
                            }}
                          ></InputNumber>
                          <XIcon
                            className="stroke-1 cursor-pointer hover:scale-110 transform transition"
                            style={{ height: '2rem', width: '2rem' }}
                            onClick={() => {
                              this.setState({
                                monthSearch: undefined
                              });
                            }}
                          ></XIcon>
                        </div>
                      </div>
                      <div
                        className="pr-1 w-auto"
                        style={{ ...g.layout.flexVertical }}
                      >
                        <h3 className="w-auto">
                          Filtrar<br></br>tags
                        </h3>
                        <TagSelector
                          document={{ tags: this.state.searchTags }}
                          onChange={async (document, tagValueArray) => {
                            this.setState({ searchTags: [...tagValueArray] });
                          }}
                        ></TagSelector>
                      </div>
                      <div className=" flex flex-col items-end">
                        <h3>
                          &nbsp;<br></br>Ficheiros
                        </h3>
                        <div className="flex justify-end items-center">
                          <div
                            className={`${
                              this.state.tagState?.error
                                ? 'text-red-600 '
                                : ' text-green-600'
                            } mr-4 whitespace-nowrap`}
                          >
                            {this.state.tagState.message ?? ''}
                          </div>
                          <UploadFile
                            onSuccess={doc => {
                              console.log('onSuccess doc: ', doc);
                              this.setState(state => {
                                const company = state.company;
                                let docs = company.data.documents;
                                if (!docs) {
                                  docs = [];
                                }
                                docs.push(doc);
                                company.data.documents = docs;
                                return { company: { ...company } };
                              });
                            }}
                            companyId={this.state.company._id}
                            uploadButtonLabel={'Novo Ficheiro'}
                            bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
                            containerStyle={{
                              padding: 0,
                              margin: 0
                            }}
                            key="upload"
                            source="documents"
                            onFile
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <List
                    style={{ ...styles.fileList, minHeight: '24rem' }}
                    itemLayout="horizontal"
                    dataSource={this.state.currentDocuments}
                    renderItem={item => {
                      return (
                        <List.Item
                          actions={[
                            <GetFileButton
                              fileId={item.id}
                              label="Descarregar"
                              key="download"
                            />,
                            <DeleteFileButton
                              fileId={item.id}
                              groupId={this.state.company._id}
                              label="Apagar"
                              key="delete"
                              onSuccess={async () => await this.refresh()}
                            />
                          ]}
                        >
                          <Checkbox
                            style={{
                              marginRight: '1em'
                            }}
                            checked={this.state.selected?.some(
                              doc => doc.id === item.id
                            )}
                            onChange={e => {
                              if (e.target.checked) {
                                console.log('CHECKED ' + item.id);
                                this.setState(prevState => {
                                  const newSelected = prevState.selected;
                                  newSelected.push({
                                    name: item.name,
                                    id: item.id
                                  });
                                  return { selected: newSelected };
                                });
                              } else {
                                console.log('unchecked ' + item.id);
                                this.setState(prevState => {
                                  const newSelected = prevState.selected?.filter(
                                    x => x.id !== item.id
                                  );
                                  return { selected: newSelected };
                                });
                              }
                            }}
                          ></Checkbox>
                          <List.Item.Meta title={<span>{item.name}</span>} />
                          <List.Item.Meta
                            title={
                              <span>
                                {item.createdAt
                                  ? moment(item.createdAt).format(
                                      'YYYY-MM-DD HH:mm'
                                    )
                                  : 'no date'}
                              </span>
                            }
                          />
                          <List.Item.Meta
                            title={
                              <TagSelector
                                document={item}
                                onChange={this.updateDocumentTags}
                              ></TagSelector>
                            }
                          />
                          <div>
                            <span></span>
                          </div>
                        </List.Item>
                      );
                    }}
                  />
                </div>
              </div>
            )}
          </TabPane>
          <TabPane tab="Compras" key="Sales">
            {this.state.company && (
              <div style={styles.flexVertical}>
                <h3>Company Sales</h3>
                <List
                  style={styles.fileList}
                  itemLayout="horizontal"
                  dataSource={this.state.company.data.products}
                  renderItem={(item, index) => {
                    return (
                      <List.Item actions={[]}>
                        <List.Item.Meta
                          description={
                            <>
                              <Row>
                                <Col style={styles.list.column} span={6}>
                                  <h3>User</h3>
                                  <span>
                                    <Link
                                      style={{ paddingLeft: 1 }}
                                      to={`/users/${item.userId}`}
                                    >
                                      {item.user.name}
                                    </Link>
                                  </span>
                                </Col>
                                <Col style={styles.list.column} span={12}>
                                  <h3>Date</h3>
                                  <span>
                                    {moment(item.date).format(
                                      'YYYY-MM-DD HH:mm'
                                    )}
                                  </span>
                                </Col>
                                <Col style={styles.list.column} span={12}>
                                  <h3>Estado</h3>
                                  <span>{item.status}</span>
                                </Col>
                              </Row>
                              <Row>
                                <Col style={styles.list.column} span={6}>
                                  <h3>Preço</h3>
                                  <span>{item.price}</span>
                                </Col>
                                <Col style={styles.list.column} span={6}>
                                  <h3>produtos</h3>
                                  <span>
                                    {item.products.map(product => (
                                      <span key={product.id}>
                                        {product.title}
                                      </span>
                                    ))}
                                  </span>
                                </Col>
                              </Row>
                            </>
                          }
                        />
                      </List.Item>
                    );
                  }}
                />
              </div>
            )}
          </TabPane>
          <TabPane tab="Pagamentos" key="charges">
            {this.state.company && (
              <div style={styles.flexVertical}>
                <h3>Company Charges</h3>
                <List
                  style={styles.fileList}
                  itemLayout="horizontal"
                  dataSource={this.state.company.data.charges}
                  renderItem={(item, index) => {
                    console.log(item);
                    return (
                      <List.Item actions={[]}>
                        <List.Item.Meta
                          title={<span>{index}</span>}
                          description={
                            <Row>
                              <Col style={styles.list.column} span={8}>
                                <h3>User</h3>
                                <span>
                                  <Link
                                    style={{ paddingLeft: 1 }}
                                    to={`/users/${item.userId}`}
                                  >
                                    {item.user.name}
                                  </Link>
                                </span>
                              </Col>
                              <Col style={styles.list.column} span={16}>
                                <h3>Description</h3>
                                <span>{item.description}</span>
                              </Col>
                              <Col style={styles.list.column} span={8}>
                                <h3>Date</h3>
                                <span>
                                  {moment(item.createdAt).format(
                                    'YYYY-MM-DD HH:mm'
                                  )}
                                </span>
                              </Col>
                              <Col style={styles.list.column} span={8}>
                                <h3>Status</h3>
                                <span>{item.status}</span>
                              </Col>
                              <Col style={styles.list.column} span={8}>
                                <h3>Value</h3>
                                <span>{item.value}</span>
                              </Col>
                            </Row>
                          }
                        />
                      </List.Item>
                    );
                  }}
                />
              </div>
            )}
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  theme: state.theme,
  locale: state.i18n.locale
});

export default compose(
  connect(mapStateToProps, {
    changeLocale
  }),
  translate,
  withStyles(styles)
)(SingleCompany);
