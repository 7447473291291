import React from 'react';
import moment from 'moment';
import autobind from 'auto-bind';
import { withRouter } from 'react-router-dom';

import 'react-virtualized/styles.css';
import '../../index.css';

import { List, Checkbox, Button, InputNumber } from 'antd';
import { XIcon } from '@heroicons/react/outline';
import {
  GetMultipleFilesButton,
  DeleteMultipleFilesButton
} from '../../companies/UploadFile';
import { putUser } from '../../common/network';

import UploadFile, { GetFileButton, DeleteFileButton } from '../UploadFile';

import g from '../../common/globalStyles';
import './Files.scss';
import TagSelector from '../../components/TagSelector';

const styles = {
  label: { width: '10em', display: 'inline-block' },
  button: { margin: '1em' },
  modal: {
    body: {
      width: '70vw',
      borderTop: '1px solid #e2e2e2',
      borderBottom: '1px solid #e2e2e2',
      marginTop: g.global.baseline,
      paddingTop: g.global.baseline,
      paddingBottom: g.global.baseline * 2,
      ...g.layout.flexVertical,
      ...g.layout.flexCenter,
      ...g.layout.alignCenter,
      textAlign: 'left',
      fontSize: g.global.baseline * 1.2
    },
    list: {
      listStylePosition: 'inside',
      padding: 0,
      paddingLeft: g.global.baseline
    },
    message: {
      marginBottom: g.global.baseline
    },
    error: {
      marginBottom: g.global.baseline,
      color: g.colors.feedback.error
    }
  },

  list: {
    body: {
      width: '100%',
      paddingBottom: g.global.baseline * 4
    },
    itemContainer: {
      width: '100%',
      ...g.layout.flexHorizontal,
      ...g.layout.flexBetween,
      paddingLeft: g.global.baseline * 2,
      paddingRight: g.global.baseline * 2,
      paddingTop: g.global.baseline,
      paddingBottom: g.global.baseline
    },
    item: {
      width: '100%',
      borderBottom: '1px solid #E3E7ED'
    },
    itemMeta: { width: '90%', height: '100%', alignItems: 'center' },
    column: {
      position: 'relative',
      ...g.global.flexVertical,
      ...g.global.flexStart,
      ...g.global.alignCenter,
      paddingBottom: g.global.baseline
    }
  },
  form: {
    width: '100%',
    paddingRight: g.global.baseline
  },
  item: {
    ...g.global.flexHorizontal,
    ...g.global.flexStart,
    ...g.global.alignCenter,
    width: '100%',
    fontWeight: 100
  },
  flexVertical: {
    width: '100%',
    height: 'auto',
    overflowY: 'scroll',
    ...g.layout.flexVertical,
    ...g.layout.flexStart,
    ...g.layout.alignStart,
    paddingBottom: g.global.baseline
  },
  fileList: {
    width: '100%',
    borderTop: '1px solid #efefef',
    borderBottom: '1px solid #efefef'
  },
  ...g
};

// Upload
// Name, type, source, Download, Delete

class Files extends React.Component {
  constructor(props) {
    super(props);

    console.log('Files PROPS: ', props);

    this.state = {
      currentDocuments: this.props.record?.documents,
      selected: [],
      loading: false,
      searchTags: [],
      tagState: { error: false, message: '' },
      yearSearch: undefined,
      monthSearch: undefined
    };

    autobind(this);
  }

  async updateDocumentTags(document, tagValueArray, replaceTags = true) {
    const docIndex = this.state.currentDocuments?.findIndex(
      doc => !!doc.id && doc.id === document.id
    );

    if (docIndex < 0) {
      return;
    }

    const targetDoc = this.state.currentDocuments?.[docIndex];
    console.log(docIndex, targetDoc);
    if (replaceTags) {
      targetDoc.tags = tagValueArray;
    } else {
      console.log({ doctags: targetDoc.tags, tagValueArray });
      const tagSet = new Set();
      for (const tag of targetDoc.tags ?? []) {
        tagSet.add(tag);
      }
      for (const tag of tagValueArray ?? []) {
        tagSet.add(tag);
      }
      targetDoc.tags = Array.from(tagSet);
      console.log({ settags: Array.from(tagSet) });
    }

    console.log('before put');

    const result = await putUser(this.props.record.id, {
      [`documents.${docIndex}.tags`]: replaceTags
        ? tagValueArray
        : targetDoc.tags
    });

    console.log('put result: ', result);

    if (!result.ok) {
      this.setState({
        tagState: { error: true, message: 'Erro a actualizar tags' }
      });
      return;
    } else {
      this.setState({
        tagState: { error: false, message: 'Documento actualizado' }
      });
      const that = this;
      setTimeout(function() {
        that.setState({ tagState: { error: false, message: '' } });
      }, 500);
    }

    this.setState(prevState => {
      const newDocs = [];
      for (const oldDoc of prevState.currentDocuments) {
        newDocs.push({ ...oldDoc });
      }
      return {
        currentDocuments: newDocs
      };
    });

    return result;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.yearSearch !== this.state.yearSearch ||
      prevState.monthSearch !== this.state.monthSearch ||
      prevState.searchTags !== this.state.searchTags
    ) {
      let baseDocuments = this.props.record?.documents;
      // year
      if (this.state.yearSearch) {
        baseDocuments = baseDocuments?.filter(doc => {
          // No date, or year of date is the same as search
          return (
            doc.createdAt &&
            moment(doc.createdAt).year() === this.state.yearSearch
          );
        });
      }

      // month
      if (!isNaN(this.state.monthSearch)) {
        baseDocuments = baseDocuments?.filter(doc => {
          return (
            doc.createdAt &&
            moment(doc.createdAt).month() === this.state.monthSearch
          );
        });
      }

      // tags
      if (this.state.searchTags?.length) {
        baseDocuments = baseDocuments?.filter(doc => {
          let hasAllTags = true;
          for (const searchTag of this.state.searchTags) {
            if (!doc?.tags?.some(docTag => searchTag === docTag)) {
              hasAllTags = false;
              break;
            }
          }
          return hasAllTags;
        });
      }
      this.setState({ currentDocuments: baseDocuments });
    }

    if (prevProps.record !== this.props.record) {
      this.setState({ currentDocuments: this.props.record?.documents });
    }
  }

  render() {
    const hasDocumentsSelected = this.state.selected?.length > 0;
    const canDeleteMany = !!this.state.selected?.length;

    return (
      <div id="files" style={{ ...styles.flexVertical, minHeight: '40rem' }}>
        <div style={{ ...g.layout.flexVertical, width: '100%' }}>
          <div
            style={{
              ...g.layout.flexHorizontal,
              ...g.layout.flexBetween,
              flexGrow: 1,
              paddingBottom: '1em'
            }}
          >
            <div style={{ ...g.layout.flexVertical }}>
              <h3>Operações de Grupo</h3>
              <div style={{ ...g.layout.flexHorizontal, flexGrow: 1 }}>
                <GetMultipleFilesButton
                  disabled={!hasDocumentsSelected}
                  title={'Descarregar'}
                  style={{ marginRight: '1em' }}
                  fileInfo={this.state.selected}
                  label="Descarregar"
                  key="download"
                />

                <DeleteMultipleFilesButton
                  disabled={!canDeleteMany}
                  fileInfo={this.state.selected}
                  userId={this.props.record?.id}
                  label="Apagar"
                  key="delete"
                  onSuccess={async () => window.location.reload()}
                />
                <Button
                  className="ml-2"
                  onClick={() => {
                    this.setState(prevState => {
                      if (prevState.selected?.length) {
                        return { selected: [] };
                      } else {
                        return { selected: this.state.currentDocuments ?? [] };
                      }
                    });
                  }}
                >
                  Selecionar Visíveis
                </Button>
              </div>
            </div>
            <div className="flex items-center justify-end">
              {!!this.state.selected?.length && (
                <div
                  className="pr-1 w-auto min-w-0"
                  style={{ ...g.layout.flexVertical, width: '10rem' }}
                >
                  <h3 className=" flex w-auto">
                    Tags<br></br>múltiplas
                  </h3>
                  <TagSelector
                    placeholder=""
                    document={{ tags: [] }} // don't show any tags here
                    onChange={async (document, tagValueArray) => {
                      for (const selectedDoc of this.state.selected) {
                        const document = this.state.currentDocuments?.find(
                          doc => doc.id === selectedDoc.id
                        );
                        console.log('DOCUMENT: ', document);
                        if (!document) {
                          console.log('doc not found in state: ', selectedDoc);
                          continue;
                        }
                        await this.updateDocumentTags(
                          document,
                          tagValueArray,
                          false
                        );
                      }
                    }}
                  ></TagSelector>
                </div>
              )}

              <div
                className="pr-1 w-auto items-start min-w-0"
                style={{
                  ...g.layout.flexVertical,
                  width: '10rem'
                }}
              >
                <h3 className=" flex w-auto">
                  Ano de <br></br>Upload
                </h3>
                <div className="flex items-center">
                  <InputNumber
                    value={this.state.yearSearch}
                    onChange={value => {
                      this.setState({ yearSearch: value });
                    }}
                    min={2020}
                    max={2100}
                    style={{
                      minWidth: '4rem',
                      flex: 1,
                      height: '2.4rem',
                      borderRadius: 0
                    }}
                  ></InputNumber>
                  <XIcon
                    className="stroke-1 cursor-pointer hover:scale-110 transform transition"
                    style={{ height: '2rem', width: '2rem' }}
                    onClick={() => {
                      this.setState({
                        yearSearch: undefined
                      });
                    }}
                  ></XIcon>
                </div>
              </div>
              <div
                className="pr-4 w-auto min-w-0"
                style={{ ...g.layout.flexVertical, maxWidth: '8rem' }}
              >
                <h3 className="w-auto">
                  Mês de <br></br>Upload
                </h3>
                <div className="flex items-center">
                  <InputNumber
                    value={
                      !isNaN(this.state.monthSearch)
                        ? this.state.monthSearch + 1
                        : undefined
                    }
                    onChange={value => {
                      this.setState({ monthSearch: value - 1 });
                    }}
                    min={1}
                    max={12}
                    style={{
                      minWidth: '4rem',
                      height: '2.4rem',
                      borderRadius: 0
                    }}
                  ></InputNumber>
                  <XIcon
                    className="stroke-1 cursor-pointer hover:scale-110 transform transition"
                    style={{ height: '2rem', width: '2rem' }}
                    onClick={() => {
                      this.setState({
                        monthSearch: undefined
                      });
                    }}
                  ></XIcon>
                </div>
              </div>
              <div className="pr-1 w-auto" style={{ ...g.layout.flexVertical }}>
                <h3 className="w-auto">
                  Filtrar<br></br>tags
                </h3>
                <TagSelector
                  document={{ tags: this.state.searchTags }}
                  onChange={async (document, tagValueArray) => {
                    this.setState({ searchTags: [...tagValueArray] });
                  }}
                ></TagSelector>
              </div>
              <div className="items-start" style={g.layout.flexVertical}>
                <h3>
                  &nbsp;<br></br>Ficheiros
                </h3>
                <div className="flex items-center">
                  <div
                    className={`${
                      this.state.tagState?.error
                        ? 'text-red-600 '
                        : ' text-green-600'
                    } mr-4 whitespace-nowrap`}
                  >
                    {this.state.tagState.message ?? ''}
                  </div>
                  <UploadFile
                    record={this.props.record}
                    uploadButtonLabel={'Novo Ficheiro'}
                    bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
                    containerStyle={{
                      padding: 0,
                      margin: 0
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <List
            style={styles.fileList}
            itemLayout="horizontal"
            dataSource={this.state.currentDocuments}
            renderItem={item => {
              return (
                <List.Item
                  actions={[
                    <GetFileButton
                      record={item}
                      label="Descarregar"
                      key="download"
                    />,
                    <DeleteFileButton
                      record={item}
                      userId={this.props.record?.id}
                      label="Apagar"
                      key="delete"
                      onSuccess={async () => {
                        // await this.refresh();
                        window.location.reload();
                      }}
                    />
                  ]}
                >
                  <Checkbox
                    checked={this.state.selected?.some(
                      doc => doc.id === item.id
                    )}
                    style={{
                      marginRight: '1em'
                    }}
                    onChange={e => {
                      if (e.target.checked) {
                        console.log('CHECKED ' + item.id);
                        this.setState(prevState => {
                          const newSelected = prevState.selected;
                          newSelected.push({
                            name: item.name,
                            id: item.id
                          });
                          return { selected: newSelected };
                        });
                      } else {
                        console.log('unchecked ' + item.id);
                        this.setState(prevState => {
                          const newSelected = prevState.selected?.filter(
                            x => x.id !== item.id
                          );
                          return { selected: newSelected };
                        });
                      }
                    }}
                  ></Checkbox>
                  <List.Item.Meta title={<span>{item.name}</span>} />
                  <List.Item.Meta
                    title={
                      <span>
                        {console.log(item)}
                        {item.createdAt
                          ? moment(item.createdAt).format('YYYY-MM-DD HH:mm')
                          : 'no date'}
                      </span>
                    }
                  />
                  <List.Item.Meta
                    title={
                      <div className="flex flex-row flex-wrap items-center">
                        <TagSelector
                          document={item}
                          onChange={this.updateDocumentTags}
                        ></TagSelector>
                      </div>
                    }
                  />

                  <div>
                    <span></span>
                  </div>
                </List.Item>
              );
            }}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(Files);
