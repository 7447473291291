import React from 'react';
import { Layout, Button, message } from 'antd';
import { Link } from 'react-router-dom';
import {
  AutoSizer,
  Column,
  Table,
  SortIndicator,
  SortDirection
} from 'react-virtualized';
import 'react-virtualized/styles.css';
import sort from 'fast-sort';
import op from 'object-path';
import autobind from 'auto-bind/react';

import globalStyles from '../common/globalStyles';
import { mrg } from '../common/util';
import { getGroups, deleteCompany } from '../common/network';

import ManagePermissions from './ManagePermissions';
import FinpartnerPermissions from './permissions/FinpartnerPermissions';

import DetailedMessage from '../components/DetailedMessage';
import NoData from '../components/NoData';
import Loading from '../components/Loading';
import ConfirmDelete from '../components/ConfirmDelete';

const { Content } = Layout;

const styles = {
  fullWidth: {
    width: '100%'
  },
  fullHeight: {
    height: '100%'
  },
  full: {
    width: '100%',
    height: '100%'
  },
  content: {
    width: '100%',
    height: '100%',
    overflowY: 'scroll'
  },
  action: {
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexCenter,
    width: '100%',
    marginBottom: globalStyles.global.baseline,
    fontSize: globalStyles.global.baseline * 1.2
  },
  info: {
    body: {
      ...globalStyles.layout.flexVertical,
      ...globalStyles.layout.flexStart,
      ...globalStyles.layout.alignCenter
    },
    item: {
      marginBottom: globalStyles.global.baseline
    },
    icon: { marginRight: globalStyles.global.baseline / 2 }
  },
  tag: {
    marginBottom: globalStyles.global.baseline * 0.5,
    fontSize: globalStyles.global.baseline * 0.8,
    padding: '0 ' + globalStyles.global.baseline * 0.5 + 'px'
  },

  pageHeaderContainer: {
    width: '100%',
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexBetween,
    ...globalStyles.layout.alignCenter
  },
  pageHeaderContainerLeft: {
    width: '40%',
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexStart,
    ...globalStyles.layout.alignCenter
  },
  pageHeaderContainerRight: {
    width: '60%',
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexEnd,
    ...globalStyles.layout.alignCenter
  },
  pageHeaderTitle: {
    fontSize: globalStyles.global.baseline * 2,
    fontWeight: 100,
    color: '#666666',
    textTransform: 'uppercase',
    paddingTop: globalStyles.global.baseline,
    paddingBottom: globalStyles.global.baseline,
    paddingLeft: globalStyles.global.baseline,
    paddingRight: globalStyles.global.baseline / 2,
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexStart,
    ...globalStyles.layout.alignCenter
  },
  pageHeaderBox: {
    paddingBottom: globalStyles.global.baseline,
    paddingTop: globalStyles.global.baseline,
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexStart,
    ...globalStyles.layout.alignCenter
  },
  pageHeaderContent: {
    fontSize: globalStyles.global.baseline * 2,
    fontWeight: 100,
    color: '#444444',
    textTransform: 'none',
    marginLeft: globalStyles.global.baseline
  },
  menuIcon: {
    fontSize: globalStyles.typography.size.base * 1.5,
    fontWeight: 100,
    margin: '0 ' + globalStyles.global.baseline + 'px'
  },
  permissionData: {
    fontWeight: 100,
    ...globalStyles.layout.flexVertical,
    ...globalStyles.layout.flexStart,
    ...globalStyles.layout.alignStart
  },
  table: {
    body: {},
    evenRow: {
      borderRight: '1px solid #e0e0e0',
      borderBottom: '1px solid #e0e0e0'
    },
    oddRow: {
      borderRight: '1px solid #e0e0e0',
      backgroundColor: '#fafafa',
      borderBottom: '1px solid #e0e0e0'
    },
    descendantRow: {
      borderRight: '1px solid #e0e0e0',
      borderBottom: '1px solid #e0e0e0',
      backgroundColor: '#e0f0ff'
    },
    headerRow: {
      textTransform: 'none',
      backgroundColor: '#c0c0ca',
      fontSize: globalStyles.global.baseline,
      fontWeight: 500,
      height: globalStyles.global.baseline * 4
    },
    column: {
      fontWeight: 100,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      borderRight: '1px solid #e0e0e0'
    },
    overflowColumn: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    checkboxLabel: {
      marginLeft: '5rem'
    },
    noRows: {
      position: 'absolute',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '1em',
      color: '#bdbdbd'
    }
  }
};

const baseColumnWidth = globalStyles.global.baseline * 4;

const labelMap = {
  id: 'ID',
  name: 'Name'
};
const mapHeaderLabel = label => {
  if (labelMap[label]) {
    return labelMap[label];
  }
  return label;
};

class List extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = { modalGroupId: null };

    this.tableRef = null;

    autobind(this);
  }

  async componentDidMount() {
    if (!this.state.sortedList) {
      await this.refresh();
    }
    await this.sort({
      sortBy: this.state.sortBy,
      sortDirection: this.state.sortDirection
    });
  }

  onSaveSuccess() {
    return message.success('Values saved successfully');
  }
  onSaveError(err) {
    return DetailedMessage.error('Error saving values', err);
  }

  showPermissions() {
    const groups = this.state.sortedList.filter(
      group => group._id === this.state.modalGroupId
    );
    if (Array.isArray(groups) && groups.length === 1) {
      const group = groups[0];
      return (
        <FinpartnerPermissions
          handleGoBack={this.handleGoBack}
          groupData={group}
        ></FinpartnerPermissions>
      );
    }
    return 'Unknown Error';
  }
  handleGoBack = async () => {
    this.setState({ modalGroupId: null });
    return await this.refresh();
  };

  render() {
    return (
      <Content
        style={mrg([
          styles.fullWidth,
          styles.fullHeight,
          globalStyles.layout.flexVertical,
          globalStyles.layout.flexStart,
          globalStyles.layout.alignStart,
          styles.content
        ])}
      >
        {this.state.sortedList ? (
          <AutoSizer>
            {({ height, width }) => (
              <Table
                ref={ref => (this.tableRef = ref)}
                style={styles.table.body}
                height={height - globalStyles.global.baseline * 10}
                rowHeight={this.rowHeight}
                rowStyle={({ index }) => this.rowStyle({ index })}
                noRowsRenderer={NoData}
                rowGetter={({ index }) => {
                  return this.state.sortedList[index];
                }}
                rowCount={this.state.sortedList.length}
                sort={this.sort}
                sortBy={this.state.sortBy}
                sortDirection={this.state.sortDirection}
                width={width}
              >
                <Column
                  width={baseColumnWidth}
                  flexGrow={2}
                  dataKey="name"
                  disableSort={false}
                  style={styles.table.column}
                  headerRenderer={this.columnHeader}
                />
                <Column
                  width={baseColumnWidth}
                  flexGrow={3}
                  dataKey="name"
                  disableSort={false}
                  style={styles.table.column}
                  headerRenderer={() => 'Data'}
                  cellRenderer={({ cellData, rowData }) => (
                    <div
                      style={{
                        ...styles.permissionData,
                        paddingLeft: globalStyles.global.baseline
                      }}
                    >
                      <div style={styles.permissionData}>
                        <span>
                          Company users:{' '}
                          {rowData.users ? rowData.users.length : 0}
                        </span>
                        <span>
                          Documents uploaded:{' '}
                          {rowData.data.documents
                            ? rowData.data.documents.length
                            : 0}
                        </span>
                      </div>
                    </div>
                  )}
                />
                <Column
                  width={baseColumnWidth}
                  flexGrow={3}
                  dataKey="_id"
                  disableSort={true}
                  style={styles.table.column}
                  cellRenderer={({ cellData, rowData }) => {
                    return (
                      <div
                        style={{
                          ...styles.permissionData,
                          paddingLeft: globalStyles.global.baseline
                        }}
                      >
                        <div style={styles.permissionData}>
                          <Button
                            type="ghost"
                            shape="round"
                            style={{
                              padding: '6px 12px',
                              marginBottom: 12,
                              fontWeight: 100
                            }}
                            onClick={() =>
                              this.setState({ modalGroupId: cellData })
                            }
                          >
                            Manage Company Users
                          </Button>
                          <Button
                            type="ghost"
                            shape="round"
                            style={{
                              padding: '6px 12px',
                              marginBottom: 12,
                              fontWeight: 100
                            }}
                          >
                            <Link
                              style={{ paddingLeft: 1 }}
                              to={`/companies/${rowData._id}`}
                            >
                              Manage Company Data
                            </Link>
                          </Button>
                          <ConfirmDelete
                            buttonProps={{ shape: 'round' }}
                            buttonStyle={{
                              padding: '6px 12px',
                              marginBottom: 12,
                              fontWeight: 100
                            }}
                            deleteLabel={'Delete Company'}
                            confirmLabel={'Confirm deletion!'}
                            onDelete={async () => {
                              const response = await deleteCompany(rowData._id);
                              if (response && response.result === 'OK') {
                                message.success('Company Deleted');
                                await this.refresh();
                              } else {
                                DetailedMessage.error(
                                  'Error deleting company',
                                  response
                                );
                              }
                            }}
                          />
                        </div>
                      </div>
                    );
                  }}
                  headerRenderer={() => 'Actions'}
                />
              </Table>
            )}
          </AutoSizer>
        ) : (
          <Loading></Loading>
        )}
        {this.state.modalGroupId && (
          <ManagePermissions handleGoBack={this.handleGoBack}>
            {this.showPermissions()}
          </ManagePermissions>
        )}
      </Content>
    );
  }
  columnHeader({ dataKey, sortBy, sortDirection }) {
    return (
      <div>
        {
          <span
            onClick={() =>
              this.setState((state, props) => ({
                sortBy: dataKey
              }))
            }
          >
            {mapHeaderLabel(dataKey).toUpperCase()}
          </span>
        }
        {sortBy === dataKey && (
          <SortIndicator
            onClick={() =>
              this.setState((state, props) => ({
                sortDirection: state.sortDirection === 'ASC' ? 'DESC' : 'ASC'
              }))
            }
            sortDirection={sortDirection}
          />
        )}
      </div>
    );
  }

  async refresh() {
    this.setState({ loading: true });
    let groups = await getGroups();

    if (groups && groups.result === 'OK' && groups.data.length > 0) {
      this.setState({
        sortedList: groups.data
      });
    } else if (groups && groups.result === 'OK') {
      this.setState({
        sortedList: []
      });
    } else {
      DetailedMessage.error('Error loading Data', groups);
      this.setState({
        sortedList: []
      });
    }
    this.setState({ loading: false });
  }

  rowStyle({ index }) {
    if (index === -1) {
      return styles.table.headerRow;
    }
    if (index % 2 === 0) {
      return styles.table.evenRow;
    } else {
      return styles.table.oddRow;
    }
  }

  rowHeight({ index }) {
    return globalStyles.global.baseline * 16;
  }

  async search() {
    this.setState({ searching: true });
    if (!this.state.sortedList || this.state.searching || !this.state.search) {
      this.setState({ searching: false, sortedList: this.userCache });
      return;
    }

    const users = this.userCache.filter(
      kase => JSON.stringify(kase).indexOf(this.state.search) !== -1
    );
    this.setState({ sortedList: users, searching: false });
    if (this.tableRef) {
      await this.tableRef.recomputeRowHeights();
    }
  }

  async sort({ sortBy, sortDirection }) {
    const sortedUsers = this.sortList({
      list: this.state.sortedList,
      sortBy,
      sortDirection
    });

    this.setState({ sortBy, sortDirection, sortedUsers });
    if (this.tableRef) {
      await this.tableRef.recomputeRowHeights();
    }
  }

  sortList({ list, sortBy, sortDirection }) {
    const toSort = sort(list);

    if (sortBy === 'unread') {
      switch (sortDirection) {
        case SortDirection.ASC:
          return toSort.asc(
            user => user.unreadAri + user.unreadCrm + user.unreadAudit
          );
        case SortDirection.DESC:
          return toSort.desc(
            user => user.unreadAri + user.unreadCrm + user.unreadAudit
          );
        default:
          return list;
      }
    }

    let sb = sortBy;

    if (sortBy && sortBy.includes('.')) {
      sb = data => op.get(data, sortBy);
    }

    switch (sortDirection) {
      case SortDirection.ASC:
        return toSort.asc(sb);
      case SortDirection.DESC:
        return toSort.desc(sb);
      default:
        return list;
    }
  }
}

export default List;
