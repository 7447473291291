import React from 'react';
import { Empty } from 'antd';
import { mrg } from '../common/util';
import g from '../common/globalStyles';

const styles = {
  full: {
    width: '100%',
    height: '100%',
    padding: g.global.baseline
  }
};

function NoData(props) {
  const style = props && props.style ? props.style : {};
  return (
    <div
      style={mrg([
        styles.full,
        g.layout.flexVertical,
        g.layout.flexCenter,
        g.layout.alignCenter,
        style,
        { height: 'auto' }
      ])}
    >
      <Empty description={props?.text || 'No Data'} />
    </div>
  );
}

export default NoData;
