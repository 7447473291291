import React from 'react';
import Select from 'react-select';
import sort from 'fast-sort';
import { documentTags } from '../common/variables';

const TagSelector = ({
  document,
  placeholder = <span className="text-gray-500">Select Tags</span>,
  onChange: onChangeProp = (document, tagValueArray) => {}
}) => {
  const [docTags, setDocTags] = React.useState(document?.tags ?? []);

  React.useEffect(() => {
    setDocTags(document?.tags ?? []);
  }, [document]);

  const onChange = (selectedOptions, metaAction) => {
    // console.log('selected, meta: ', selectedOptions, metaAction);
    const tagValues = selectedOptions?.map(tagOption => tagOption.value) ?? [];
    if (onChangeProp) {
      onChangeProp(document, tagValues);
    } else {
      // hard update. This ensures form values are saved on backend.
      // We don't have access to RA's setState here, so change directly in the object that RA gives us
      document.tags = tagValues;
    }

    setDocTags(tagValues);

    // console.log('currenttags ', docTags);
    // console.log('available ', sort(documentTags).desc('label'));
  };

  const currentOptions = documentTags.filter(tagObj =>
    docTags?.includes(tagObj.value)
  );

  return (
    <Select
      className={`flex-grow h-full bg-white border border-gray-300`}
      closeMenuOnSelect={false}
      value={sort(currentOptions).desc(t => t.label)}
      isMulti
      hideSelectedOptions={true}
      //menuIsOpen={true}
      placeholder={placeholder}
      styles={tagSelectStyles}
      options={sort(documentTags).desc(t => t.label)}
      onChange={onChange}
    />
  );
};

const tagSelectStyles = {
  control: (provided, state) => {
    return {
      ...provided,
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
      color: '#000000',
      height: '100%',
      border: '0px',
      minHeight: 'unset',
      borderRadius: 'unset',
      flexWrap: 'nowrap'
    };
  },
  dropdownIndicator: (provided, state) => {
    return {
      ...provided,
      height: '50%',
      width: '50%',
      padding: 0,
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem'
    };
  },
  clearIndicator: (provided, state) => {
    return {
      ...provided,
      padding: 0,
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem'
    };
  },
  placeholder: (provided, state) => {
    return {
      ...provided,
      color: 'rgba(0,0,0, 1)'
    };
  },
  valueContainer: (provided, state) => {
    return {
      ...provided,
      height: '100%',
      padding: '0.25rem',
      display: 'flex'
    };
  },
  menu: (provided, state) => {
    return {
      ...provided,
      backgroundColor: 'rgba(255, 255, 255)',
      border: '1px solid hsl(0, 0%, 80%);',
      color: '#000000',
      maxHeight: '16rem'
    };
  },
  menuList: (provided, state) => {
    return {
      ...provided,
      backgroundColor: 'rgba(255, 255, 255)',
      color: '#000000',
      maxHeight: '15.8rem'
    };
  },
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: 'rgba(255, 255, 255)',
      color: '#000000'
    };
  }
};

export default TagSelector;
