import React from 'react';

import { Storage, Auth } from 'aws-amplify';

import 'antd/dist/antd.css';
import { Icon, Button, Upload, message } from 'antd';
import Compressor from 'compressorjs';

import { apiUrl } from '../dataProvider/rest';

const deleteFile = async s3id => {
  const s3Key = s3id.replace(/.+amazonaws\.com\/news\//g, '');
  const s3Result = await Storage.remove(s3Key, {
    level: 'public',
    customPrefix: {
      public: 'news/'
    }
  });

  console.log({ s3Result });
  return s3Result;
};

const bucketName = 'finpartner-userfiles-mobilehub-920305426';
const folderName = '/news';
const fileSizeLimit = 2000000; // bytes

const compressImage = fileData => {
  return new Promise((resolve, reject) => {
    new Compressor(fileData, {
      quality: 0.6,
      success: resolve,
      error: reject,
      convertSize: 1000000
    });
  });
};

class UploadFile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Upload
        showUploadList={false}
        customRequest={() => {}}
        accept="image/png, image/jpeg"
        transformFile={async file => {
          const fileID = file.uid + file.name;

          if (file.size && file.size > fileSizeLimit) {
            message.error(
              'Tamanho de ficheiro excedido! São permitidos ficheiros PNG e JPG/JPEG até 2MB'
            );
            return;
          }
          this.setState({ uploading: true });
          try {
            const compressedFile = await compressImage(file);
            //console.log(compressedFile);
            let s3Result = await Storage.put(fileID, compressedFile, {
              contentType: file.type,
              level: 'public',
              customPrefix: {
                public: 'news/'
              },
              ACL: 'public-read'
            });
            console.log('uploaded file: ', s3Result);

            const userInfo = await Auth.currentAuthenticatedUser();

            const news = {
              image:
                'https://' +
                bucketName +
                '.s3.amazonaws.com' +
                folderName +
                '/' +
                s3Result.key
            };
            await fetch(apiUrl + '/news/' + this.props.newsId, {
              method: 'PUT',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                jwt: userInfo.signInUserSession.accessToken.jwtToken
              },
              body: JSON.stringify(news)
            });
            this.setState({
              uploading: false
            });
            if (this.props.onSuccess) {
              await this.props.onSuccess(news.image);
            }
          } catch (err) {
            console.error(err);
            this.setState({
              uploading: false
            });
            if (this.props.onError) {
              await this.props.onError(err);
            }
          }
          return false;
        }}
      >
        <Button
          loading={this.state.uploading}
          style={this.props.buttonStyle ?? {}}
        >
          <Icon type="upload" />
        </Button>
      </Upload>
    );
  }
}

export default UploadFile;
export { deleteFile };
