import React from 'react';
import { connect } from 'react-redux';
import { translate, changeLocale } from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import compose from 'recompose/compose';
import moment from 'moment';
import deburr from 'lodash.deburr';
import autobind from 'auto-bind/react';
import {
  Tabs,
  List,
  Row,
  Col,
  Avatar,
  Form,
  Input,
  DatePicker,
  Button,
  Icon,
  Divider
} from 'antd';

import CreateMeeting, { DeleteMeeting } from './CreateMeeting';
import { getAllMeetings, updateMeeting, getAllUsers } from '../common/network';
import g from '../common/globalStyles';
import DetailedMessage from '../components/DetailedMessage';
import constants from './constants';

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const styles = {
  label: { width: '10em', display: 'inline-block' },
  button: { margin: '1em' },
  modal: {
    body: {
      width: '70vw',
      borderTop: '1px solid #e2e2e2',
      borderBottom: '1px solid #e2e2e2',
      marginTop: g.global.baseline,
      paddingTop: g.global.baseline,
      paddingBottom: g.global.baseline * 2,
      ...g.layout.flexVertical,
      ...g.layout.flexCenter,
      ...g.layout.alignCenter,
      textAlign: 'left',
      fontSize: g.global.baseline * 1.2
    },
    list: {
      listStylePosition: 'inside',
      padding: 0,
      paddingLeft: g.global.baseline
    },
    message: {
      marginBottom: g.global.baseline
    },
    error: {
      marginBottom: g.global.baseline,
      color: g.colors.feedback.error
    }
  },
  list: {
    body: {
      width: '100%',
      paddingBottom: g.global.baseline * 4
    },
    itemContainer: {
      width: '100%',
      ...g.layout.flexHorizontal,
      ...g.layout.flexBetween,
      paddingLeft: g.global.baseline * 2,
      paddingRight: g.global.baseline * 2,
      paddingTop: g.global.baseline,
      paddingBottom: g.global.baseline
    },
    item: {
      width: '100%',
      borderBottom: '1px solid #E3E7ED'
    },
    itemMeta: { width: '90%', height: '100%', alignItems: 'center' },
    column: {
      position: 'relative',
      ...g.global.flexVertical,
      ...g.global.flexStart,
      ...g.global.alignCenter,
      paddingBottom: g.global.baseline
    }
  },
  form: {
    width: '100%',
    paddingRight: g.global.baseline
  },
  item: {
    ...g.global.flexHorizontal,
    ...g.global.flexStart,
    ...g.global.alignCenter,
    width: '100%',
    fontWeight: 100
  }
};
const mapTarget = target => {
  switch (target) {
    case constants.targets.backend:
      return 'Finpartner';
    case constants.targets.app:
      return 'Client';
    default:
      return 'Unknown';
  }
};

class Meetings extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      accepted: [],
      pending: [],
      past: [],
      search: '',
      searching: false,
      searchIn: null
    };

    autobind(this);
  }

  async componentDidMount() {
    await this.refresh();
  }

  async componentDidUpdate(prevProps, prevState) {
    const newTextSearch = prevState.search !== this.state.search;
    const newDateSearch =
      prevState.searchDateRange !== this.state.searchDateRange;
    if (newTextSearch || newDateSearch) {
      await this.search();
    }
  }

  async search() {
    if (!this.meetingCache || this.state.searching) {
      console.log('Clearing search');
      return this.setState({
        searching: false,
        ...this.meetingCache
      });
    }
    console.log('Searching');
    this.setState({ searching: true });
    this.setState(currentState => {
      let pending = this.meetingCache.pending;
      let accepted = this.meetingCache.accepted;
      let past = this.meetingCache.past;
      if (currentState.search) {
        pending = pending.filter(meeting =>
          this.matchSearch(meeting, currentState.search)
        );

        accepted = accepted.filter(meeting =>
          this.matchSearch(meeting, currentState.search)
        );
        past = past.filter(meeting =>
          this.matchSearch(meeting, currentState.search)
        );
      }
      if (currentState.searchDateRange) {
        pending = pending.filter(meeting =>
          this.matchDate(meeting, currentState.searchDateRange)
        );

        accepted = accepted.filter(meeting =>
          this.matchDate(meeting, currentState.searchDateRange)
        );
        past = past.filter(meeting =>
          this.matchDate(meeting, currentState.searchDateRange)
        );
      }

      return { pending, accepted, past, searching: false };
    });
  }

  matchSearch(meeting, term) {
    return (
      deburr(JSON.stringify(Object.values(meeting)))
        .toLowerCase()
        .indexOf(deburr(term).toLowerCase()) !== -1
    );
  }

  matchDate(meeting, dateRange) {
    return moment(meeting.date).isBetween(dateRange[0], dateRange[1]);
  }

  processMeetings(data) {
    const now = moment();
    const past = [];
    const future = [];
    data.forEach(meeting => {
      let username = '?';
      if (meeting.owner && this.state.users) {
        username = this.state.users.find(user => user.id === meeting.owner)
          .name;
      }
      meeting.username = username;
      const date = moment(meeting.date);
      const isBefore = date.isBefore(now);

      if (isBefore) {
        past.push(meeting);
      } else {
        future.push(meeting);
      }
    });
    const pending = future.filter(meeting => meeting.accepted !== true);
    const accepted = future.filter(meeting => meeting.accepted === true);
    return { pending, accepted, past };
  }

  async refresh() {
    console.log('refreshing');
    this.setState({ editing: null });

    let response = await getAllUsers();

    if (response && response.result === 'OK') {
      this.setState({ users: response.data });
    } else {
      DetailedMessage.error('Error: cannot fetch users', response);
    }

    response = await getAllMeetings();
    if (response && response.result === 'OK') {
      const meetings = response.data;
      const { pending, accepted, past } = await this.processMeetings(meetings);

      this.meetingCache = { pending, accepted, past };
      this.setState({ pending, accepted, past });
    } else {
      DetailedMessage.error('Error: cannot fetch meetings', response);
    }
  }

  renderMeeting = (record, index) => {
    const isAccepted = record.accepted;
    const canRespond =
      !record.accepted && record.target === constants.targets.backend;

    return (
      <List.Item
        style={{
          position: 'relative',
          ...styles.list.item,
          background: index % 2 === 0 ? '#ebebeb' : 'initial'
        }}
      >
        <div style={styles.list.itemContainer}>
          <List.Item.Meta
            style={styles.list.itemMeta}
            avatar={
              <div
                style={{
                  height: '100%',
                  maxWidth: '15vw',
                  ...g.layout.flexVertical,
                  ...g.layout.flexCenter,
                  ...g.layout.alignCenter,
                  textAlign: 'center'
                }}
              >
                <Avatar
                  style={{
                    backgroundColor: '#D0D7E1'
                  }}
                  size={g.global.baseline * 6}
                >
                  {record.username?.[0]}
                </Avatar>
                <div
                  style={{
                    ...g.layout.flexVertical,
                    ...g.layout.flexCenter,
                    ...g.layout.alignCenter,
                    fontSize: g.global.baseline * 1.5,
                    padding: g.global.baseline,
                    width: '100%',
                    height: 'auto',
                    color: '#444',
                    whitespace: 'normal',
                    wordWrap: 'normal'
                  }}
                >
                  {record.username}
                </div>
              </div>
            }
            description={
              <>
                <span
                  style={{
                    position: 'absolute',
                    top: g.global.baseline,
                    left: g.global.baseline,
                    fontSize: g.global.baseline * 1.5,
                    fontWeight: 300
                  }}
                >
                  {index}
                </span>
                <Row
                  type="flex"
                  justify="end"
                  style={{ paddingBottom: g.global.baseline }}
                >
                  {!this.state.editing && (
                    <>
                      {canRespond && (
                        <Button
                          style={{ marginRight: g.global.baseline * 2 }}
                          type={'primary'}
                          onClick={async () => {
                            const result = await updateMeeting(
                              record.owner,
                              record._id,
                              { accepted: true }
                            );
                            console.log(result);
                            await this.refresh();
                          }}
                        >
                          Accept
                        </Button>
                      )}
                      {canRespond && (
                        <Button
                          style={{ marginRight: g.global.baseline * 2 }}
                          type={'primary'}
                          ghost
                          onClick={() => {
                            this.setState({ editing: record });
                          }}
                        >
                          Counter-proposal
                        </Button>
                      )}
                      {!isAccepted && (
                        <DeleteMeeting
                          deleteLabel={'Delete'}
                          buttonStyle={{ marginRight: g.global.baseline * 2 }}
                          record={record}
                          onSuccess={this.refresh}
                          onError={response =>
                            DetailedMessage.error(
                              'Erro! Não foi possível apagar o item',
                              response
                            )
                          }
                          userId={record.owner}
                        />
                      )}
                    </>
                  )}
                </Row>
                <Row>
                  <Col style={styles.list.column} span={6}>
                    <h3>Title</h3>
                    <span>{record.title}</span>
                  </Col>
                  <Col style={styles.list.column} span={12}>
                    <h3>Description</h3>
                    <span>{record.description}</span>
                  </Col>
                  <Col
                    style={{
                      ...styles.list.column,
                      color: isAccepted ? g.colors.foreground.green : 'initial'
                    }}
                    span={6}
                  >
                    <h3>Agreed / Finalized</h3>
                    <span>{record.accepted ? 'YES' : 'No'}</span>
                  </Col>
                </Row>
                <Row>
                  <Col style={styles.list.column} span={6}>
                    <h3>Proposed By</h3>
                    <span>{mapTarget(record.source)}</span>
                  </Col>
                  <Col style={styles.list.column} span={6}>
                    <h3>{isAccepted ? 'Accepted by' : 'Waiting for'}</h3>
                    <span>{mapTarget(record.target)}</span>
                  </Col>
                  {this.renderEditableFields(record)}
                </Row>
              </>
            }
          />
        </div>
      </List.Item>
    );
  };

  renderEditableFields = record => {
    if (this.state.editing && this.state.editing._id === record._id) {
      const { getFieldDecorator } = this.props.form;
      return (
        <Col style={styles.list.column} span={24}>
          <Form style={styles.form} onSubmit={this.submit}>
            <Row>
              <Col style={styles.list.column} span={12}>
                <h3>Date</h3>
                <Form.Item style={styles.item}>
                  {getFieldDecorator('date', {
                    rules: [
                      {
                        required: true,
                        message: 'Required'
                      }
                    ],
                    initialValue: moment(record.date)
                  })(
                    <DatePicker
                      style={{ width: '70%', maxWidth: '100%' }}
                      placeholder={'Date & Time'}
                      showTime={{ format: 'HH:mm' }}
                      format="YYYY-MM-DD HH:mm"
                    />
                  )}
                </Form.Item>

                {/* {moment(record.date).format('MMMM-DD-YYYY, HH:mm ([UTC] Z)')} */}
              </Col>
              <Col style={styles.list.column} span={12}>
                <h3>Location</h3>
                <Form.Item>
                  {getFieldDecorator('location', {
                    rules: [
                      {
                        required: true,
                        message: 'Required'
                      }
                    ],
                    initialValue: record.location
                  })(
                    <Input
                      disabled={this.state.sending}
                      style={{
                        width: '100%',
                        paddingRight: g.global.baseline
                      }}
                      placeholder="Location"
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Button
                style={{ marginRight: g.global.baseline * 2 }}
                type={'primary'}
                htmlType="submit"
              >
                Send
              </Button>
              <Button
                type={'primary'}
                ghost
                onClick={() => this.setState({ editing: null })}
              >
                Cancel
              </Button>
            </Row>
          </Form>
        </Col>
      );
    } else {
      return (
        <>
          <Col style={styles.list.column} span={6}>
            <h3>Date</h3>
            {moment(record.date).format('MMMM-DD-YYYY, HH:mm ([UTC] Z)')}
          </Col>
          <Col style={styles.list.column} span={6}>
            <h3>Location</h3>
            <span>{record.location}</span>
          </Col>
        </>
      );
    }
  };

  async submit(event) {
    event.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        this.setState({ sending: true });
        const newValues = {};
        newValues.location = values.location;
        newValues.target = constants.targets.app;
        newValues.date = values.date.format();

        console.log({ newValues });
        const response = await updateMeeting(
          newValues.owner,
          this.state.editing._id,
          newValues
        );
        if (response && response.result === 'OK') {
          this.refresh();
        } else {
          DetailedMessage.error('Error updating meeting', response);
        }
        this.setState({ sending: false });
      }
    });
  }

  render() {
    return (
      <div style={{ height: '80vh' }}>
        <div
          style={{
            ...g.layout.flexHorizontal,
            ...g.layout.flexStart,
            ...g.layout.alignCenter,
            width: '100%',
            paddingBottom: g.global.baseline,
            borderBottom: '1px solid #e8e8e8'
          }}
        >
          <Input
            suffix={<Icon style={{ color: '#a8a8a8' }} type={'enter'}></Icon>}
            style={{ maxWidth: '33%', marginRight: g.global.baseline }}
            placeholder="Search Text"
            onChange={e => {
              this.setState({ search: e.target.value });
            }}
            disabled={
              this.state.loading ||
              this.state.searching ||
              this.state.meetingCache?.length
            }
            value={this.state.search}
          />
          <RangePicker
            allowClear={true}
            format="YYYY-MM-DD"
            style={{ maxWidth: '33%', marginRight: g.global.baseline }}
            onChange={dates => {
              this.setState({ searchDateRange: dates });
            }}
            disabled={
              this.state.loading ||
              this.state.searching ||
              this.state.meetingCache?.length
            }
            value={this.state.searchDateRange}
          />
          <Button
            icon="close"
            secondary
            ghost
            style={{ marginRight: 0 /*g.global.baseline*/ }}
            type={'primary'}
            onClick={async () => {
              this.setState({ search: '', searchDateRange: null });
            }}
          >
            Clear Search
          </Button>
          <Divider type="vertical" style={{ height: '2em', top: 0 }}></Divider>
          <Button
            icon="reload"
            ghost
            style={{ marginRight: g.global.baseline }}
            type={'primary'}
            onClick={async () => {
              return await this.refresh();
            }}
          >
            Refresh Meetings
          </Button>
        </div>
        <Tabs defaultActiveKey="1">
          <TabPane
            tab={<span style={{ fontWeight: 700 }}>Pending</span>}
            key="pending"
          >
            <List
              style={styles.list.body}
              itemLayout="horizontal"
              dataSource={this.state.pending}
              renderItem={this.renderMeeting}
            />
          </TabPane>
          <TabPane tab="Accepted" key="accepted">
            <List
              style={styles.list.body}
              itemLayout="horizontal"
              dataSource={this.state.accepted}
              renderItem={this.renderMeeting}
            />
          </TabPane>

          <TabPane tab="Past Meetings" key="past">
            <List
              style={styles.list.body}
              itemLayout="horizontal"
              dataSource={this.state.past}
              renderItem={this.renderMeeting}
            />
          </TabPane>
          <TabPane tab="Create New Meeting" key="create">
            <CreateMeeting
              users={this.state.users}
              key="meetings"
              source="meetings"
              onSuccess={this.refresh}
              onError={response =>
                DetailedMessage.error('Error saving meeting', response)
              }
            />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  theme: state.theme,
  locale: state.i18n.locale
});

export default compose(
  connect(
    mapStateToProps,
    {
      changeLocale
    }
  ),
  translate,
  withStyles(styles),
  Form.create({ name: 'meeting_form' })
)(Meetings);
