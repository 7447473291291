import React, { Component } from 'react';
import { GET_LIST, GET_MANY, Responsive, ViewTitle } from 'react-admin';

// eslint-disable-next-line
import Welcome from './Welcome'; 
import MonthlyRevenue from './MonthlyRevenue'; // eslint-disable-line
import NbNewOrders from './NbNewOrders';
import PendingOrders from './PendingOrders'; // eslint-disable-line
import PendingReviews from './PendingReviews'; // eslint-disable-line
import NewCustomers from './NewCustomers';
import dataProviderFactory from '../dataProvider';

const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '1em', marginBottom: '2em' }
};

class Dashboard extends Component {
    state = {};

    componentDidMount() {
        const aMonthAgo = new Date();
        aMonthAgo.setDate(aMonthAgo.getDate() - 30);

        dataProviderFactory(process.env.REACT_APP_DATA_PROVIDER).then(
            dataProvider => {
                dataProvider(GET_LIST, 'sales', {
                    filter: { date_gte: aMonthAgo.toISOString() },
                    sort: { field: 'date', order: 'DESC' },
                    pagination: { page: 1, perPage: 50 }
                })
                    .then(response =>
                        response.data.reduce(
                            (stats, order) => {
                                if (order.status !== 'cancelled') {
                                    stats.revenue += order.price;
                                    stats.nbNewOrders++;
                                }
                                if (order.status === 'ordered') {
                                    stats.pendingOrders.push(order);
                                }
                                return stats;
                            },
                            {
                                revenue: 0,
                                nbNewOrders: 0,
                                pendingOrders: []
                            }
                        )
                    )
                    .then(({ revenue, nbNewOrders, pendingOrders }) => {
                        this.setState({
                            revenue: revenue.toLocaleString(undefined, {
                                style: 'currency',
                                currency: 'EUR',
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0
                            }),
                            nbNewOrders,
                            pendingOrders
                        });
                        return pendingOrders;
                    })
                    .then(pendingOrders =>
                        pendingOrders.map(order => order.userId)
                    )
                    .then(customerIds =>
                        dataProvider(GET_MANY, 'users', {
                            ids: customerIds
                        })
                    )
                    .then(response => response.data)
                    .then(customers =>
                        customers.reduce((prev, customer) => {
                            prev[customer.id] = customer; // eslint-disable-line no-param-reassign
                            return prev;
                        }, {})
                    )
                    .then(customers =>
                        this.setState({ pendingOrdersCustomers: customers })
                    );

                /* dataProvider(GET_LIST, 'subscriptions', {
                    sort: { field: 'date', order: 'DESC' },
                    pagination: { page: 1, perPage: 100 }
                })
                    .then(response => response.data)
                    .then(reviews => {
                        const nbPendingReviews = reviews.reduce(nb => ++nb, 0);
                        const pendingReviews = reviews.slice(
                            0,
                            Math.min(10, reviews.length)
                        );
                        this.setState({ pendingReviews, nbPendingReviews });
                        return pendingReviews;
                    })
                    .then(reviews => reviews.map(review => review.userId))
                    .then(customerIds =>
                        dataProvider(GET_MANY, 'users', {
                            ids: customerIds
                        })
                    )
                    .then(response => response.data)
                    .then(customers =>
                        customers.reduce((prev, customer) => {
                            prev[customer.id] = customer; // eslint-disable-line no-param-reassign
                            return prev;
                        }, {})
                    )
                    .then(customers =>
                        this.setState({ pendingReviewsCustomers: customers })
                    )
                    .catch(err => {
                        console.log('HTTP error: ' + err);
                    }); */

                dataProvider(GET_LIST, 'users', {
                    filter: {
                        has_ordered: true,
                        created_gte: aMonthAgo
                    },
                    sort: { field: 'first_seen', order: 'DESC' },
                    pagination: { page: 1, perPage: 100 }
                })
                    .then(response => response.data)
                    .then(newCustomers => {
                        this.setState({ newCustomers });
                        this.setState({
                            nbNewCustomers: newCustomers.reduce(nb => ++nb, 0)
                        });
                    });
            }
        );
    }

    render() {
        const {
            nbNewCustomers,
            nbNewOrders,
            nbPendingReviews, // eslint-disable-line
            newCustomers,
            pendingOrders, // eslint-disable-line
            pendingOrdersCustomers, // eslint-disable-line
            pendingReviews, // eslint-disable-line
            pendingReviewsCustomers, // eslint-disable-line
            revenue // eslint-disable-line
        } = this.state;
        return (
            <Responsive
                xsmall={
                    <div>
                        <ViewTitle title="Finpartner" />
                        <div style={styles.flexColumn}>
                            <div style={{ marginBottom: '2em' }}>
                                <Welcome />
                            </div>
                            <div style={styles.flex}>
                                <MonthlyRevenue value={revenue} />
                                <NbNewOrders value={nbNewOrders} />
                            </div>
                            <div style={styles.singleCol}>
                                {/* <PendingReviews
                                    nb={nbPendingReviews}
                                    reviews={pendingReviews}
                                    customers={pendingReviewsCustomers}
                                /> */}
                                <NewCustomers
                                    nb={nbNewCustomers}
                                    visitors={newCustomers}
                                />
                            </div>
                        </div>
                    </div>
                }
                small={
                    <div style={styles.flexColumn}>
                        <div style={styles.singleCol}>
                            <Welcome />
                        </div>
                        <div style={styles.flex}>
                            <MonthlyRevenue value={revenue} />
                            <NbNewOrders value={nbNewOrders} />
                        </div>
                        <div style={styles.singleCol}>
                            {/* <PendingReviews
                                    nb={nbPendingReviews}
                                    reviews={pendingReviews}
                                    customers={pendingReviewsCustomers}
                                /> */}
                            <NewCustomers
                                nb={nbNewCustomers}
                                visitors={newCustomers}
                            />
                        </div>
                    </div>
                }
                medium={
                    <div>
                        <div style={styles.flex}>
                            <div style={styles.leftCol}>
                                <div style={styles.singleCol}>
                                    <Welcome />
                                </div>
                                <div style={styles.flex}>
                                    <MonthlyRevenue value={revenue} />
                                    <NbNewOrders value={nbNewOrders} />
                                </div>
                            </div>
                            <div style={styles.rightCol}>
                                {/* <PendingReviews
                                    nb={nbPendingReviews}
                                    reviews={pendingReviews}
                                    customers={pendingReviewsCustomers}
                                /> */}
                                <NewCustomers
                                    nb={nbNewCustomers}
                                    visitors={newCustomers}
                                />
                            </div>
                        </div>
                    </div>
                }
            />
        );
    }
}

export default Dashboard;
