import React from 'react';
import { Button, Icon, Form, Input, DatePicker, Select } from 'antd';
import 'antd/dist/antd.css';
import { translate } from 'react-admin';
import compose from 'recompose/compose';

import ConfirmDelete from '../components/ConfirmDelete';
import { postMeeting, deleteMeeting } from '../common/network';
import g from '../common/globalStyles';
import constants from './constants';

const { Option } = Select;

const styles = {
  body: {
    display: 'flex',
    alignItems: 'center'
  },
  simpleContainer: {
    marginBottom: g.global.baseline
  },
  container: {
    width: '100%',
    ...g.layout.flexHorizontal,
    ...g.layout.flexStart,
    ...g.layout.alignCenter,
    marginBottom: g.global.baseline
  },

  item: {
    width: '33%',
    fontWeight: 100,
    marginBottom: 0,
    marginRight: g.global.baseline
  },
  largeItem: {
    width: '100%',
    fontWeight: 100,
    marginBottom: 0,
    marginRight: g.global.baseline
  },
  form: {
    width: '100%',
    border: '1px solid #dddddd',
    borderRadius: 5,
    padding: g.global.baseline * 2
  }
};

class CreateMeeting extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sending: false
    };

    this.submit = this.submit.bind(this);
  }

  async submit(event) {
    event.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, meeting) => {
      if (!err) {
        this.setState({ sending: true });
        const newMeeting = {};

        newMeeting.title = meeting.title;
        newMeeting.description = meeting.description;
        newMeeting.location = meeting.location;
        newMeeting.owner = meeting.owner.key;
        newMeeting.target = constants.targets.app;
        newMeeting.source = constants.targets.backend;
        newMeeting.accepted = false;
        newMeeting.date = meeting.date.format();

        const response = await postMeeting(newMeeting.owner, newMeeting);
        if (response && response.result === 'OK') {
          this.setState({ data: event });
          if (this.props.onSuccess) {
            await this.props.onSuccess(response, event);
          }
          this.props.form.resetFields();
        } else {
          if (this.props.onError) {
            await this.props.onError(response);
          }
        }
        this.setState({ sending: false });
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div style={styles.body}>
        <Form style={styles.form} onSubmit={this.submit}>
          <div style={styles.container}>
            <Form.Item style={styles.item}>
              {getFieldDecorator('title', {
                rules: [
                  {
                    required: true,
                    message: 'Required'
                  }
                ]
              })(
                <Input
                  disabled={this.state.sending}
                  style={{
                    width: '100%',
                    paddingRight: g.global.baseline
                  }}
                  placeholder="Título"
                />
              )}
            </Form.Item>
            <Form.Item style={styles.item}>
              {getFieldDecorator('date', {
                rules: [
                  {
                    required: true,
                    message: 'Required'
                  }
                ]
              })(
                <DatePicker
                  placeholder={'Data e hora'}
                  showTime={{ format: 'HH:mm' }}
                  format="YYYY-MM-DD HH:mm"
                />
              )}
            </Form.Item>
            <Form.Item style={styles.item}>
              {getFieldDecorator('owner', {
                rules: [{ required: true, message: 'Required' }]
              })(
                <Select
                  showSearch
                  optionFilterProp="children"
                  labelInValue
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.props.users.map(users => (
                    <Option key={users.id}>{users.name}</Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </div>
          <div style={styles.container}>
            <Form.Item style={styles.largeItem}>
              {getFieldDecorator('description', {
                rules: [
                  {
                    required: true,
                    message: 'Required'
                  }
                ]
              })(
                <Input.TextArea
                  disabled={this.state.sending}
                  autosize={{ minRows: 3 }}
                  style={{
                    width: '100%'
                  }}
                  placeholder="Descrição"
                />
              )}
            </Form.Item>
          </div>
          <div style={styles.container}>
            <Form.Item style={styles.largeItem}>
              {getFieldDecorator('location', {
                rules: [
                  {
                    required: true,
                    message: 'Required'
                  }
                ]
              })(
                <Input.TextArea
                  disabled={this.state.sending}
                  autosize={{ minRows: 1 }}
                  style={{
                    width: '100%',
                    paddingRight: g.global.baseline
                  }}
                  placeholder="Localização / Notas adicionais"
                />
              )}
            </Form.Item>
          </div>
          <div style={styles.container}>
            <Button
              style={styles.item}
              loading={this.state.sending}
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Enviar
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

const MeetingForm = Form.create({ name: 'meeting_form' })(CreateMeeting);
export default compose(translate)(MeetingForm);

const DeleteMeeting = ({
  record,
  userId,
  deleteLabel = <Icon type="delete"></Icon>,
  confirmLabel = 'Confirm',
  buttonStyle = {},
  onSuccess = () => {},
  onError = () => {}
}) => {
  return (
    <ConfirmDelete
      deleteLabel={deleteLabel}
      confirmLabel={confirmLabel}
      buttonStyle={buttonStyle}
      onDelete={async () => {
        const response = await deleteMeeting(userId, record._id);
        console.log(response);
        if (response && response.result === 'OK') {
          onSuccess(response);
        } else {
          onError(response);
        }
      }}
    />
  );
};

export { DeleteMeeting };
