const awsmobile = {
    aws_app_analytics: 'disable',
    aws_cognito_identity_pool_id:
        'us-east-1:bae67e27-2905-46f7-8c81-38c2268e6149',
    aws_cognito_region: 'us-east-1',
    aws_mandatory_sign_in: 'enable',
    aws_project_id: 'b97fcdef-5558-4e4a-a42e-c59e7ce999f7',
    aws_project_name: 'finpartner-backoffice',
    aws_project_region: 'us-east-1',
    aws_resource_name_prefix: 'finpartnerbackoffice-mobilehub-1270665644',
    aws_sign_in_enabled: 'enable',
    aws_user_files: 'enable',

    aws_user_files_s3_bucket: 'finpartner-userfiles-mobilehub-920305426',

    aws_user_files_s3_bucket_region: 'us-east-1',
    aws_user_pools: 'enable',
    aws_user_pools_id: 'us-east-1_DzLzdT25t',
    aws_user_pools_web_client_id: '76bdrh2p0ljvugrmpf0a3j7o7f'
};

export default awsmobile;
