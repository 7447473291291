import React from 'react';
import MuiGridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ContentCreate from '@material-ui/icons/Create';
import { Link } from 'react-router-dom';
import { NumberField } from 'react-admin';
import { linkToRecord } from 'ra-core';
import finpartnerSvg from '../images/finpartner.svg';

const styles = {
    root: {
        margin: '-2px'
    },
    gridList: {
        width: '100%',
        margin: 0
    },
    tileBar: {
        background: 'rgba(68,68,68,0.9)',
        color: '#fff',
        textOverflow: 'unset !important'
    },
    price: {
        display: 'inline',
        fontSize: '1em'
    },
    link: {
        color: '#fff'
    },
    productImg: {
        maxHeight: '96%',
        margin: '2%'
    }
};

const GridList = ({ classes, ids, data, basePath }) => (
    <div className={classes.root}>
        <MuiGridList cellHeight={180} cols={4} className={classes.gridList}>
            {ids.map(id => (
                <GridListTile key={id}>
                    <img style={styles.productImg} src={finpartnerSvg} alt="" />
                    <GridListTileBar
                        className={classes.tileBar}
                        title={data[id].title}
                        subtitle={
                            <span>
                                <NumberField
                                    className={classes.price}
                                    source="price"
                                    record={data[id]}
                                    color="inherit"
                                    options={{
                                        style: 'currency',
                                        currency: 'EUR'
                                    }}
                                />
                            </span>
                        }
                        actionIcon={
                            <IconButton
                                to={linkToRecord(basePath, data[id].id)}
                                className={classes.link}
                                component={Link}
                            >
                                <ContentCreate />
                            </IconButton>
                        }
                    />
                </GridListTile>
            ))}
        </MuiGridList>
    </div>
);

export default withStyles(styles)(GridList);
