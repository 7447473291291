import React from 'react';
import { Create, SimpleForm, NumberInput, TextInput } from 'react-admin';
import CustomerReferenceInput from '../visitors/CustomerReferenceInput';

const validateCharge = values => {
    const errors = {};
    if (!values.description) {
        errors.description = ['required'];
    } else if (values.description && values.description.length > 110) {
        errors.description = [
            'Description can have a maximum of 110 characters.'
        ];
    }
    if (!values.value) {
        errors.value = ['required'];
    }
    if (!values.userId) {
        errors.userId = ['required'];
    }
    return errors;
};

const ChargeCreate = ({ classes, ...props }) => (
    <Create {...props}>
        <SimpleForm validate={validateCharge}>
            <CustomerReferenceInput
                style={{ maxHeight: '50vh', overflow: 'scroll' }}
                source="userId"
                key={'customer'}
            />
            <NumberInput
                key="value"
                source="value"
                options={{
                    currency: 'EUR'
                }}
            />
            <TextInput source="description" key="description" />
        </SimpleForm>
    </Create>
);

export default ChargeCreate;
