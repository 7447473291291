import React, { Fragment } from 'react';
import {
    Datagrid,
    DateField,
    TextField,
    List,
    NumberField,
    Responsive
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import Icon from '@material-ui/icons/AttachMoney';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import CustomerReferenceField from '../visitors/CustomerReferenceField';
import MobileGrid from './MobileGrid';

export const CommandIcon = Icon;

const datagridStyles = {
    total: { fontWeight: 'bold' }
};

class TabbedDatagrid extends React.Component {
    tabs = [{ id: 'created', name: 'created' }, { id: 'paid', name: 'paid' }];

    state = { created: [], paid: [] };

    static getDerivedStateFromProps(props, state) {
        if (props.ids !== state[props.filterValues.status]) {
            return { ...state, [props.filterValues.status]: props.ids };
        }
        return null;
    }

    handleChange = (event, value) => {
        const { filterValues, setFilters } = this.props;
        setFilters({ ...filterValues, status: value });
        console.log(
            'set filters. filterValues: ',
            filterValues,
            ' ||| status: ',
            value
        );
    };

    render() {
        const { classes, filterValues, ...props } = this.props;
        return (
            <Fragment>
                <Tabs
                    fullWidth
                    centered
                    value={filterValues.status}
                    indicatorColor="primary"
                    onChange={this.handleChange}
                >
                    {this.tabs.map(choice => (
                        <Tab
                            key={choice.id}
                            label={choice.name}
                            value={choice.id}
                        />
                    ))}
                </Tabs>
                <Divider />
                <Responsive
                    xsmall={
                        <MobileGrid
                            {...props}
                            ids={this.state[filterValues.status]}
                        />
                    }
                    medium={
                        <div>
                            {filterValues.status === 'created' && (
                                <Datagrid
                                    key="created"
                                    {...props}
                                    ids={this.state['created']}
                                >
                                    <DateField
                                        key="date"
                                        source="createdAt"
                                        showTime
                                    />
                                    {/* <TextField source="reference" /> */}
                                    <CustomerReferenceField key="customer" />
                                    <TextField source="status" key="status" />
                                    <TextField
                                        source="description"
                                        key="description"
                                    />
                                    <NumberField
                                        key="value"
                                        source="value"
                                        options={{
                                            style: 'currency',
                                            currency: 'EUR'
                                        }}
                                        className={classes.total}
                                    />
                                </Datagrid>
                            )}
                            {filterValues.status === 'paid' && (
                                <Datagrid
                                    key="paid"
                                    {...props}
                                    ids={this.state['paid']}
                                >
                                    <DateField
                                        key="date"
                                        source="createdAt"
                                        showTime
                                    />
                                    {/* <TextField source="reference" /> */}
                                    <CustomerReferenceField key="customer" />
                                    <TextField source="status" key="status" />
                                    <TextField
                                        source="description"
                                        key="description"
                                    />
                                    <NumberField
                                        key="value"
                                        source="value"
                                        options={{
                                            style: 'currency',
                                            currency: 'EUR'
                                        }}
                                        className={classes.total}
                                    />
                                </Datagrid>
                            )}
                        </div>
                    }
                />
            </Fragment>
        );
    }
}

const StyledTabbedDatagrid = withStyles(datagridStyles)(TabbedDatagrid);

const CommandList = ({ classes, ...props }) => (
    <List
        {...props}
        filterDefaultValues={{ status: 'created' }}
        sort={{ field: 'date', order: 'DESC' }}
        perPage={25}
    >
        <StyledTabbedDatagrid />
    </List>
);

export default CommandList;
