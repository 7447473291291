import React from 'react';
import autobind from 'auto-bind/react';
import {
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  ArrayField,
  FormTab,
  LongTextInput,
  BooleanInput,
  NumberField,
  ReferenceManyField,
  TabbedForm,
  TextField,
  TextInput
} from 'react-admin';

import withStyles from '@material-ui/core/styles/withStyles';
import IconPerson from '@material-ui/icons/Person';
import { Empty, Button, Skeleton, Input, Tooltip } from 'antd';
import { ClearOutlined, FileExcelOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import { SortDirection } from 'react-virtualized';
import 'react-virtualized/styles.css';
import deburr from 'lodash/deburr';
import op from 'object-path';
import flatten from 'flat';
import sort from 'fast-sort';

import NbItemsField from '../commands/NbItemsField';
import FullNameField from './FullNameField';
import { getAllUsers } from '../common/network';

import globalStyles from '../common/globalStyles';

import CompanyInfo from './CompanyInfo';
import SortableTable from './clientList/SortableTable';
import Gamification from './gamification/Gamification';
import Files from './Files';
import FiniMessages from './FiniMessages';

import { exportClients } from './export/exportExcel';

import './index.scss';

const { Search } = Input;

export const VisitorIcon = IconPerson;

const colored = WrappedComponent => {
  const Colored = props =>
    props.record[props.source] > 500 ? (
      <span style={{ color: 'red' }}>
        <WrappedComponent {...props} />
      </span>
    ) : (
      <WrappedComponent {...props} />
    );

  Colored.displayName = `Colored(${WrappedComponent.displayName})`;

  return Colored;
};

export const ColoredNumberField = colored(NumberField);
ColoredNumberField.defaultProps = NumberField.defaultProps;

const listStyles = {
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    backgroundColor: '#ffffff',
    //border: '1px solid #e8e8e8',
    borderRadius: globalStyles.global.baseline / 2,
    overflowX: 'hidden'
  }
};
export class VisitorList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sortedList: [],
      error: false,
      loading: false,
      sortDirection: SortDirection.DESC,
      sortBy: 'email'
    };
    this.clientCache = [];
    autobind(this);
  }

  async refresh() {
    this.setState({ loading: true });
    const response = await getAllUsers();

    if (!response?.ok) {
      this.setState({
        sortedList: [],
        error: true
      });
      this.clientCache = [];
    } else {
      const data = response?.data ?? [];

      this.setState({
        sortedList: data,
        error: false
      });
      this.clientCache = data;
    }

    this.setState({ loading: false });
  }

  async sort({ sortBy, sortDirection }) {
    const sortedClients = this.sortList({
      list: this.state.sortedList,
      sortBy,
      sortDirection
    });

    this.setState({ sortBy, sortDirection, sortedList: [...sortedClients] });
  }

  sortList({ list, sortBy, sortDirection }) {
    const toSort = sort(list);

    if (sortBy === 'gamification.rewards') {
      switch (sortDirection) {
        case SortDirection.ASC:
          return toSort.by([
            {
              asc: user =>
                user.gamification?.rewards?.filter?.(r => !r.done)?.length ?? 0
            },
            {
              asc: user => user.gamification?.points ?? 0
            },
            { asc: user => user.name }
          ]);
        case SortDirection.DESC:
          return toSort.by([
            {
              desc: user =>
                user.gamification?.rewards?.filter?.(r => !r.done)?.length ?? 0
            },
            {
              desc: user => user.gamification?.points ?? 0
            },
            { asc: user => user.name }
          ]);
        default:
          return list;
      }
    } else if (sortBy === 'gamification.points') {
      switch (sortDirection) {
        case SortDirection.ASC:
          return toSort.by([
            { asc: user => user.gamification?.points },
            {
              asc: user =>
                user.gamification?.rewards?.filter?.(r => !r.done)?.length ?? 0
            },
            { asc: user => user.name }
          ]);
        case SortDirection.DESC:
          return toSort.by([
            { desc: user => user.gamification?.points },
            {
              desc: user =>
                user.gamification?.rewards?.filter?.(r => !r.done)?.length ?? 0
            },
            { asc: user => user.name }
          ]);
        default:
          return list;
      }
    }

    switch (sortDirection) {
      case SortDirection.ASC:
        return toSort.asc(client => cleanValue(op.get(client, sortBy) ?? ''));
      case SortDirection.DESC:
        return toSort.desc(client => cleanValue(op.get(client, sortBy) ?? ''));
      default:
        return list;
    }
  }

  search(value) {
    this.setState({ loading: true });

    if (!value) {
      this.setState({
        sortedList: this.clientCache,
        searching: '',
        loading: false
      });
      return;
    }

    const result = this.clientCache.filter(
      client => cleanValue(client).indexOf(cleanValue(value)) !== -1
    );

    this.setState({
      sortedList: [...result],
      searching: value,
      loading: false
    });
  }

  componentDidMount() {
    this.refresh();
  }

  render() {
    return (
      <div style={listStyles.container}>
        {this.state.loading ? (
          <>
            <Skeleton active />
            <Skeleton active />
          </>
        ) : this.state.error ? (
          <Empty>
            <Button onClick={this.refresh}>Try again</Button>
          </Empty>
        ) : (
          <>
            <div className="action-container w-100">
              <div className="actions-start">
                <Search
                  value={this.state.search ?? ''}
                  placeholder="Procurar"
                  size="small"
                  onChange={e => {
                    console.log(e.target.value);
                    this.setState({ search: e.target?.value });
                  }}
                  onSearch={() => {
                    return this.search(this.state.search);
                  }}
                  className="search-input pr-3"
                />
                <Tooltip title={'Limpar pesquisa'}>
                  <Button
                    className="clear-search mr-3"
                    size="small"
                    type={this.props.searching ? 'primary' : 'default'}
                    shape="circle"
                    onClick={() => {
                      this.setState({ search: '' });
                      return this.search?.('');
                    }}
                  >
                    <ClearOutlined></ClearOutlined>
                  </Button>
                </Tooltip>
              </div>
              <div className="actions-center d-flex justify-content-center align-items-center"></div>
              <div className="actions-end">
                <Tooltip title={'Exportar'}>
                  <Button
                    className="mr-3"
                    size="small"
                    type={'default'}
                    shape="circle"
                    onClick={() => {
                      exportClients(this.state.sortedList);
                    }}
                  >
                    <FileExcelOutlined></FileExcelOutlined>
                  </Button>
                </Tooltip>
              </div>
            </div>
            <SortableTable
              data={this.state.sortedList}
              onSort={this.sort}
              sortDirection={{
                value: this.state.sortDirection,
                toggle: () =>
                  this.setState(state => ({
                    sortDirection:
                      state.sortDirection === SortDirection.DESC
                        ? SortDirection.ASC
                        : SortDirection.DESC
                  }))
              }}
              sortBy={{
                value: this.state.sortBy,
                set: sortBy => this.setState({ sortBy })
              }}
            ></SortableTable>
          </>
        )}
      </div>
    );
  }
}

const stringify = value => {
  if (typeof value === 'string') {
    return value;
  }
  return JSON.stringify(Object.values(flatten(value)));
};

const cleanValue = value => {
  return deburr(stringify(value)).toLowerCase();
};

const VisitorTitle = ({ record }) =>
  record ? <FullNameField record={record} size={32} /> : null;

const editStyles = {
  first_name: { display: 'inline-block' },
  email: { width: 544 },
  address: { maxWidth: 544 },
  zipcode: { display: 'inline-block' },
  city: { display: 'inline-block', marginLeft: 32 },
  comment: {
    maxWidth: '20em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
};

const EditForm = ({ classes, record, save, ...rest }) => {
  return (
    <TabbedForm
      record={record}
      save={(...args) => {
        console.log({ args });
        return save(args);
      }}
      {...rest}
    >
      <FormTab
        style={{ display: 'flex', flexDirection: 'column' }}
        label="resources.users.tabs.identity"
      >
        <TextInput
          style={{ clear: 'both' }}
          source="name"
          formClassName={classes.first_name}
        />
        <br></br>
        <TextField
          style={{ clear: 'both' }}
          type="email"
          source="email"
          formClassName={classes.email}
        />
        <TextField
          style={{ clear: 'both' }}
          type="id"
          source="id"
          formClassName={classes.first_name}
        />
        <CompanyInfo></CompanyInfo>
        <BooleanInput source="approved" label="Approved" />
      </FormTab>
      <FormTab label="resources.users.tabs.orders">
        <ReferenceManyField addLabel={false} reference="sales" target="userId">
          <Datagrid>
            <DateField key="date" source="date" />
            <NbItemsField key="NbItems" />
            <NumberField
              key="total"
              source="price"
              options={{ style: 'currency', currency: 'USD' }}
            />
            <TextField key="status" source="status" />
            <EditButton key="edit" />
          </Datagrid>
        </ReferenceManyField>
      </FormTab>
      <FormTab label="resources.users.tabs.charges">
        <ReferenceManyField
          addLabel={false}
          reference="charges"
          target="userId"
        >
          <Datagrid>
            <DateField key="date" source="createdAt" />
            <TextField key="status" source="status" />
            <NumberField
              key="value"
              source="value"
              options={{ style: 'currency', currency: 'EUR' }}
            />
          </Datagrid>
        </ReferenceManyField>
      </FormTab>
      <FormTab label="resources.users.tabs.files">
        <Files></Files>
      </FormTab>

      <FormTab label="resources.users.tabs.meetings">
        <ArrayField style={{ width: '100%' }} source="meetings" label={null}>
          <Datagrid style={{ width: '100%' }}>
            <TextField source="title" />
            <TextField source="description" />
            <TextField source="location" />
            <DateField source="date" showTime />
          </Datagrid>
        </ArrayField>
      </FormTab>
      <FormTab label="resources.users.tabs.gamification">
        <Gamification></Gamification>
      </FormTab>
      <FormTab label="resources.users.tabs.fini">
        <FiniMessages></FiniMessages>
      </FormTab>
    </TabbedForm>
  );
};

export const VisitorEdit = withStyles(editStyles)(({ classes, ...props }) => {
  try {
    return (
      <Edit title={<VisitorTitle />} {...props}>
        <EditForm classes={classes}></EditForm>
      </Edit>
    );
  } catch (error) {
    console.warn(error);
  }
});

export const VisitorCreate = withStyles(editStyles)(({ classes, ...props }) => (
  <Create {...props}>
    <TabbedForm>
      <FormTab label="resources.users.tabs.identity">
        <TextInput source="first_name" formClassName={classes.first_name} />
        <TextInput source="last_name" formClassName={classes.last_name} />
        <TextInput
          type="email"
          source="email"
          validation={{ email: true }}
          fullWidth={true}
          formClassName={classes.email}
        />
        <DateInput source="birthday" />
      </FormTab>
      <FormTab label="resources.users.tabs.address">
        <LongTextInput source="address" formClassName={classes.address} />
        <TextInput source="zipcode" formClassName={classes.zipcode} />
        <TextInput source="city" formClassName={classes.city} />
      </FormTab>
    </TabbedForm>
  </Create>
));

// const PrintProps = props => {
//   console.log('PRintProps: ', props);
//   return null;
// };
