import portugueseMessages from 'ra-language-portuguese';

export default {
  ...portugueseMessages,
  null: 'null',
  ra: {
    action: {
      delete: 'Apagar'
    },
    delete: 'Apagar %{name} #%{id}'
  },
  lang: {
    pt_PT: 'Português',
    en_US: 'Inglês',
    cn_CN: 'Chinês',
    fr_FR: 'Francês',
    sp_SP: 'Espanhol',
    ru_RU: 'Russo'
  },
  auth: {
    email: 'Email',
    confirmation_code: 'Código de Confirmação',
    request_code: 'Enviar código de confirmação',
    reset: 'Alterar palavra-passe',
    new_password: 'Nova palavra-passe',
    confirm_password: 'Confirmar palavra-passe',
    reset_password: 'Alterar Password',
    thanks: 'Obrigado',
    password_changed: 'A sua palavra-passe será alterada nos próximos minutos.',
    password_match: 'As palavras-passe não coincidem'
  },
  'NetworkError when attempting to fetch resource.':
    'NetworkError when attempting to fetch resource.',
  'response is undefined': 'response is undefined',
  'Not Found': 'Not Found',
  pos: {
    calendar: 'Calendário',
    meetings: 'Reuniões',
    companies: 'Empresas',
    news: 'Notícias',
    gamification: 'Gamificação',
    search: 'Pesquisar',
    configuration: 'Configuração',
    language: 'Língua',
    fini: 'Fini',
    nif: 'NIF',
    theme: {
      name: 'Tema',
      light: 'Claro',
      dark: 'Escuro'
    },
    dashboard: {
      monthly_revenue: 'Facturação Mensal',
      new_orders: 'Novas Transacções',
      pending_reviews: 'Transações por Processar',
      new_customers: 'Novos Clientes',
      pending_orders: 'Compras em Espera',
      order: {
        items:
          'por %{customer_name}, uma compra |||| por %{customer_name}, %{nb_items} compras'
      },
      welcome: {
        title: 'Bem-vindo ao backoffice Finpartner',
        subtitle: '',
        aor_button: '',
        demo_button: ''
      }
    }
  },
  resources: {
    users: {
      name: 'Cliente |||| Clientes',
      fields: {
        address: 'Rua',
        birthday: 'Data de Nascimento',
        city: 'Localidade',
        commands: 'Compras',
        first_name: 'Nome',
        first_seen: 'Primeira Visita',
        groups: 'Segmentos',
        has_newsletter: 'Subscreve Newsletter',
        has_ordered: 'Comprou',
        last_name: 'Apelido',
        last_seen: 'Última Visita',
        last_seen_gte: 'Última Visita',
        latest_purchase: 'Última Compra',
        name: 'Nome',
        total_spent: 'Total gasto',
        zipcode: 'Código Postal'
      },
      tabs: {
        identity: 'Identidade',
        files: 'Ficheiros',
        address: 'Morada',
        orders: 'Compras',
        gamification: 'Gamificação',
        charges: 'Cobranças',
        reviews: 'Subscrições',
        stats: 'Estatísticas',
        meetings: 'Reuniões',
        fini: 'Fini'
      },
      page: {
        delete: 'Apagar Cliente'
      }
    },
    admins: {
      name: 'Administrador |||| Administradores',
      fields: {
        address: 'Rua',
        birthday: 'Data de Nascimento',
        city: 'Localidade',
        commands: 'Compras',
        first_name: 'Nome',
        first_seen: 'Primeira Visita',
        groups: 'Segmentos',
        has_newsletter: 'Subscreve Newsletter',
        has_ordered: 'Comprou',
        last_name: 'Apelido',
        last_seen: 'Última Visita',
        last_seen_gte: 'Última Visita',
        latest_purchase: 'Última Compra',
        name: 'Nome',
        total_spent: 'Total gasto',
        zipcode: 'Código Postal'
      },
      tabs: {
        identity: 'Identidade',
        address: 'Morada',
        orders: 'Compras',
        reviews: 'Subscrições',
        stats: 'Estatísticas'
      },
      page: {
        delete: 'Apagar Cliente'
      }
    },
    sales: {
      name: 'Compra |||| Compras',
      fields: {
        basket: {
          delivery: 'Entrega',
          reference: 'Referência',
          quantity: 'Quantidade',
          sum: 'Total',
          tax_rate: 'IVA',
          total: 'Total',
          unit_price: 'P.U.',
          product: 'Product'
        },
        customer_id: 'Cliente',
        date_gte: 'Depois de',
        date_lte: 'Antes de',
        nb_items: 'N. Artigos',
        reference: 'Referência',
        returned: 'Cancelado',
        status: 'Estado',
        total_gte: 'Montante Mínimo'
      }
    },
    charges: {
      name: 'Cobrança |||| Cobranças',
      fields: {
        customer_id: 'Cliente',
        date_gte: 'Depois de',
        date_lte: 'Antes de',
        nb_items: 'N. Artigos',
        reference: 'Referência',
        returned: 'Cancelado',
        status: 'Estado',
        total_gte: 'Montante Mínimo'
      }
    },
    products: {
      name: 'Produto |||| Produtos',
      fields: {
        category_id: 'Categoria',
        height_gte: 'Altura Mínima',
        height_lte: 'Altura Máxima',
        height: 'Altura',
        image: 'Fotografia',
        price: 'Preço',
        reference: 'Referência',
        stock_lte: 'Stock Baixo',
        stock: 'Stock',
        thumbnail: 'Miniatura',
        width_gte: 'Largura Mínima',
        width_lte: 'Largura Máxima',
        width: 'Largura'
      },
      tabs: {
        image: 'Imagem',
        details: 'Detalhes',
        description: 'Descrição',
        reviews: 'Subscrições'
      }
    },
    categories: {
      name: 'Categoria |||| Categorias',
      fields: {
        name: 'Nome',
        products: 'Produtos'
      }
    },
    subscriptions: {
      name: 'Subscrição |||| Subscrições',
      fields: {
        customer_id: 'Cliente',
        command_id: 'Compra',
        product_id: 'Produto',
        date_gte: 'Comprado em',
        date_lte: 'Válido até',
        date: 'Data',
        comment: 'Conteºudo',
        status: 'Estado',
        rating: 'Classificação'
      },
      action: {
        accept: 'Aceitar',
        reject: 'Rejeitar'
      },
      notification: {
        approved_success: 'Subscrição Aprovada',
        approved_error: 'Erro: Não aprovado',
        rejected_success: 'Rejeitado',
        rejected_error: 'Erro: Comentário não rejeitado'
      }
    },
    segments: {
      name: 'Segmentos',
      fields: {
        customers: 'Clientes',
        name: 'Nome'
      },
      data: {
        compulsive: 'Orbigatório',
        collector: 'Coleccionador',
        ordered_once: 'Comprado',
        regular: 'Regular',
        returns: 'Cancelamentos',
        reviewer: 'Subscritor'
      }
    }
  }
};
