import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { propTypes, reduxForm, Field } from 'redux-form';
import compose from 'recompose/compose';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { Notification, translate } from 'react-admin';

import { Link } from 'react-router-dom';

import { Auth } from 'aws-amplify';

const styles = theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        background:
            'url(https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=37c91c8e3f63462e0739c676dfe8fee8&auto=format&fit=crop&w=1350&q=80 )',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    },
    card: {
        minWidth: 300,
        marginTop: '6em'
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center'
    },
    icon: {
        backgroundColor: theme.palette.secondary.main
    },
    hint: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.grey[500]
    },
    form: {
        padding: '0 1em 1em 1em'
    },
    input: {
        marginTop: '1em'
    },
    actions: {
        padding: '0 1em 1em 1em'
    }
});

const required = value => {
    if (!value) {
        return 'This field is required!';
    }
};

// see http://redux-form.com/6.4.3/examples/material-ui/
const renderInput = ({
    meta: { touched, error } = {},
    input: { ...inputProps },
    ...props
}) => (
    <div>
        <TextField
            error={!!(touched && error)}
            helperText={touched && error}
            {...inputProps}
            {...props}
            fullWidth
        />
        {props.meta &&
            props.meta.error &&
            props.meta.touched && (
                <div className="sans-serif red">{props.meta.error}</div>
            )}
    </div>
);

class Recover extends Component {
    constructor() {
        super();
        this.state = {
            phase: 0,
            email: ''
        };
    }
    handleEmail = data => {
        if (!data.email) {
            return;
        }
        this.setState({ email: data.email });
        Auth.forgotPassword(data.email)
            .then(data => {
                this.setState({ phase: 1 });
            })
            .catch(err => {
                console.log(err);
                throw err;
            });
    };

    handleCode = data => {
        if (!data.code || !data.password || !data.confirm_password) {
            return;
        }

        if (!(data.password === data.confirm_password)) {
            throw new Error(translate('auth.password_match'));
        }
        Auth.forgotPasswordSubmit(this.state.email, data.code, data.password)
            .then(data => {
                this.setState({ phase: 2 });
            })
            .catch(err => console.log(err));
    };

    render() {
        const { classes, handleSubmit, isLoading, translate } = this.props;
        return (
            <div className={classes.main}>
                {this.state.phase === 0 && (
                    <Card className={classes.card}>
                        <form onSubmit={handleSubmit(this.handleEmail)}>
                            <div className={classes.form}>
                                <div className={classes.input}>
                                    <Field
                                        name="email"
                                        component={renderInput}
                                        label={translate('auth.email')}
                                        disabled={isLoading}
                                        validate={required}
                                    />
                                </div>
                            </div>
                            <CardActions className={classes.actions}>
                                <Button
                                    variant="raised"
                                    type="submit"
                                    color="primary"
                                    disabled={isLoading}
                                    className={classes.button}
                                    fullWidth
                                >
                                    {isLoading && (
                                        <CircularProgress
                                            size={25}
                                            thickness={2}
                                        />
                                    )}
                                    {translate('auth.request_code')}
                                </Button>
                            </CardActions>
                        </form>
                    </Card>
                )}
                {this.state.phase === 1 && (
                    <Card className={classes.card}>
                        <form onSubmit={handleSubmit(this.handleCode)}>
                            <div className={classes.form}>
                                <div className={classes.input}>
                                    <Field
                                        name="code"
                                        component={renderInput}
                                        label={translate(
                                            'auth.confirmation_code'
                                        )}
                                        disabled={isLoading}
                                        validate={required}
                                    />
                                </div>
                                <div className={classes.input}>
                                    <Field
                                        name="password"
                                        component={renderInput}
                                        label={translate('auth.new_password')}
                                        type="password"
                                        disabled={isLoading}
                                        validate={required}
                                    />
                                </div>
                                <div className={classes.input}>
                                    <Field
                                        name="confirm_password"
                                        component={renderInput}
                                        label={translate(
                                            'auth.confirm_password'
                                        )}
                                        type="password"
                                        disabled={isLoading}
                                        validate={required}
                                    />
                                </div>
                            </div>
                            <CardActions className={classes.actions}>
                                <Button
                                    variant="raised"
                                    type="submit"
                                    color="primary"
                                    disabled={isLoading}
                                    className={classes.button}
                                    fullWidth
                                >
                                    {isLoading && (
                                        <CircularProgress
                                            size={25}
                                            thickness={2}
                                        />
                                    )}
                                    {translate('auth.reset_password')}
                                </Button>
                            </CardActions>
                        </form>
                    </Card>
                )}
                {this.state.phase === 2 && (
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography variant="headline" component="h2">
                                {translate('auth.thanks')}
                            </Typography>
                            <Typography component="p">
                                {translate('auth.password_changed')}
                            </Typography>
                        </CardContent>
                        <div className={classes.avatar}>
                            <Link to={{ pathname: '/login' }}>
                                Back to Login
                            </Link>
                        </div>
                    </Card>
                )}
                <Notification />
            </div>
        );
    }
}

Recover.propTypes = {
    ...propTypes,
    authProvider: PropTypes.func,
    classes: PropTypes.object,
    previousRoute: PropTypes.string,
    translate: PropTypes.func.isRequired
};

const enhance = compose(
    translate,
    reduxForm({
        form: 'recoverPassword',
        validate: (/*values, props*/) => {
            const errors = {};
            return errors;
        }
    }),
    withStyles(styles)
);

export default enhance(Recover);
