import englishMessages from 'ra-language-english';

export default {
  ...englishMessages,
  null: 'null',
  'NetworkError when attempting to fetch resource.':
    'NetworkError when attempting to fetch resource.',
  'response is undefined': 'response is undefined',
  'Not Found': 'Not Found',
  lang: {
    pt_PT: 'Portuguese',
    en_US: 'English',
    cn_CN: 'Chinese',
    fr_FR: 'French',
    sp_SP: 'Spanish',
    ru_RU: 'Russian'
  },
  auth: {
    email: 'Email',
    confirmation_code: 'Confirmation Code',
    request_code: 'Send Confirmation Code',
    reset: 'Reset Password',
    new_password: 'New Password',
    confirm_password: 'Confirm New Password',
    reset_password: 'Reset Password',
    thanks: 'Thank you',
    password_changed: 'Your password will be reset in the next few minutes.',
    password_match: 'Passwords do not match'
  },
  pos: {
    calendar: 'Calendar',
    meetings: 'Meetings',
    companies: 'Companies',
    news: 'News',
    gamification: 'Gamification',
    search: 'Search',
    configuration: 'Configuration',
    language: 'Language',
    fini: 'Fini',
    nif: 'NIF',
    theme: {
      name: 'Theme',
      light: 'Light',
      dark: 'Dark'
    },
    dashboard: {
      monthly_revenue: 'Monthly Revenue',
      new_orders: 'New Orders',
      pending_reviews: 'Pending Reviews',
      new_customers: 'New Customers',
      pending_orders: 'Pending Orders',
      order: {
        items:
          'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items'
      },
      welcome: {
        title: 'Welcome to the Finpartner Backoffice',
        subtitle: '',
        aor_button: '',
        demo_button: ''
      }
    }
  },
  resources: {
    users: {
      name: 'User |||| Users',
      fields: {
        commands: 'Orders',
        groups: 'Segments',
        last_seen_gte: 'Visited Since',
        name: 'Name',
        total_spent: 'Total spent'
      },
      tabs: {
        identity: 'Identity',
        files: 'Files',
        address: 'Address',
        orders: 'Orders',
        gamificatoon: 'Gamification',
        charges: 'Charges',
        subscriptions: 'Subscriptions',
        stats: 'Stats',
        meetings: 'Meetings',
        fini: 'Fini'
      },
      page: {
        delete: 'Delete Customer'
      }
    },
    admins: {
      name: 'Admin |||| Admins',
      fields: {
        commands: 'Orders',
        groups: 'Segments',
        last_seen_gte: 'Visited Since',
        name: 'Name',
        total_spent: 'Total spent'
      },
      tabs: {
        identity: 'Identity',
        address: 'Address',
        orders: 'Orders',
        subscriptions: 'Subscriptions',
        stats: 'Stats'
      },
      page: {
        delete: 'Delete Customer'
      }
    },
    sales: {
      name: 'Order |||| Orders',
      fields: {
        basket: {
          delivery: 'Delivery',
          reference: 'Reference',
          quantity: 'Quantity',
          sum: 'Sum',
          tax_rate: 'Tax Rate',
          total: 'Total',
          unit_price: 'Unit Price',
          product: 'Product'
        },
        customer_id: 'Customer',
        date_gte: 'Passed Since',
        date_lte: 'Passed Before',
        total_gte: 'Min amount',
        status: 'Status',
        returned: 'Returned'
      }
    },
    charges: {
      name: 'Charge |||| Charges',
      fields: {
        customer_id: 'Customer',
        date_gte: 'Passed Since',
        date_lte: 'Passed Before',
        total_gte: 'Min amount',
        status: 'Status',
        returned: 'Returned'
      }
    },
    products: {
      name: 'Product |||| Products',
      fields: {
        category_id: 'Category',
        height_gte: 'Min height',
        height_lte: 'Max height',
        height: 'Height',
        image: 'Image',
        price: 'Price',
        reference: 'Reference',
        stock_lte: 'Low Stock',
        stock: 'Stock',
        thumbnail: 'Thumbnail',
        width_gte: 'Min width',
        width_lte: 'Max width',
        width: 'Width'
      },
      tabs: {
        image: 'Image',
        details: 'Details',
        description: 'Description',
        reviews: 'Reviews'
      }
    },
    categories: {
      name: 'Category |||| Categories',
      fields: {
        products: 'Products'
      }
    },
    subscriptions: {
      name: 'Subscription |||| Subscriptions',
      fields: {
        customer_id: 'Customer',
        command_id: 'Order',
        product_id: 'Product',
        date_gte: 'Posted since',
        date_lte: 'Posted before',
        date: 'Date',
        comment: 'Comment',
        rating: 'Rating'
      },
      action: {
        accept: 'Accept',
        reject: 'Reject'
      },
      notification: {
        approved_success: 'Review approved',
        approved_error: 'Error: Review not approved',
        rejected_success: 'Review rejected',
        rejected_error: 'Error: Review not rejected'
      }
    },
    segments: {
      name: 'Segments',
      fields: {
        customers: 'Customers',
        name: 'Name'
      },
      data: {
        nc: 'NC',
        cs: 'CS',
        cc: 'CC'
      }
    }
  }
};
