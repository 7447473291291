import React from 'react';
import { Edit, List, NumberInput, SimpleForm, TextInput } from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import Icon from '@material-ui/icons/Collections';
import RichTextInput from 'ra-input-rich-text';

import GridList from './GridList';
import Product from './Product';

export const ProductIcon = Icon;

export const ProductList = props => (
    <List {...props} perPage={20} sort={{ field: 'id', order: 'ASC' }}>
        <GridList />
    </List>
);

const createStyles = {
    stock: { width: '5em' },
    price: { width: '5em' },
    widthFormGroup: { display: 'inline-block' },
    heightFormGroup: { display: 'inline-block', marginLeft: 32 }
};
/* 
export const ProductCreate = withStyles(createStyles)(
    ({ classes, ...props }) => (
        <Create {...props}>
            <TabbedForm>
                <FormTab label="resources.products.tabs.image">
                    <TextInput
                        source="image"
                        options={{ fullWidth: true }}
                        validate={required()}
                    />
                    <TextInput
                        source="thumbnail"
                        options={{ fullWidth: true }}
                        validate={required()}
                    />
                </FormTab>
                <FormTab label="resources.products.tabs.details">
                    <TextInput source="reference" validate={required()} />
                    <NumberInput
                        source="price"
                        validate={required()}
                        className={classes.price}
                    />
                    <NumberInput
                        source="width"
                        validate={required()}
                        className={classes.width}
                        formClassName={classes.widthFormGroup}
                    />
                    <NumberInput
                        source="height"
                        validate={required()}
                        className={classes.height}
                        formClassName={classes.heightFormGroup}
                    />
                    <ReferenceInput
                        source="category_id"
                        reference="categories"
                        allowEmpty
                    >
                        <SelectInput source="name" />
                    </ReferenceInput>
                    <NumberInput
                        source="stock"
                        validate={required()}
                        className={classes.stock}
                    />
                </FormTab>
                <FormTab label="resources.products.tabs.description">
                    <RichTextInput source="description" addLabel={false} />
                </FormTab>
            </TabbedForm>
        </Create>
    )
); */

const ProductTitle = ({ record }) => <span>{record.title}</span>;

const editStyles = {
    ...createStyles,
    color: '#444',
    comment: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    }
};

export const ProductEdit = withStyles(editStyles)(({ classes, ...props }) => (
    <Edit {...props} title={<ProductTitle />}>
        <SimpleForm>
            <Product />
            <TextInput source="title" />
            <NumberInput source="price" className={classes.price} />
            {/* <ReferenceInput source="category_id" reference="categories">
                <SelectInput source="name" />
            </ReferenceInput> */}
            <RichTextInput source="description" addLabel={true} />
            {/* <ReferenceManyField
                reference="reviews"
                target="product_id"
                addLabel={false}
            >
                <Datagrid>
                    <DateField source="date" />
                    <CustomerReferenceField />
                    <StarRatingField />
                    <TextField
                        source="comment"
                        cellClassName={classes.comment}
                    />
                    <TextField source="status" />
                    <EditButton />
                </Datagrid>
            </ReferenceManyField> */}
        </SimpleForm>
    </Edit>
));
