import React from 'react';
import autobind from 'auto-bind';
import { withRouter } from 'react-router-dom';

import moment from 'moment';
import 'react-virtualized/styles.css';
import sort from 'fast-sort';

import ConfirmDelete from '../../components/ConfirmDelete';
import { deleteFiniMessage } from '../../common/network';

import constants from '../../fini/constants';
import './FiniMessages.scss';

const { languages } = constants;

class Gamification extends React.Component {
  constructor(props) {
    super(props);

    const messages = this.props.record?.finiMessages ?? [];
    const toSort = sort(messages);

    const sortedMessages = toSort.desc(message =>
      moment(message.createdAt).format('YYYYMMDD')
    );

    this.state = {
      messages: sortedMessages,
      loading: false
    };

    autobind(this);
  }

  render() {
    return (
      <div
        id="gamification"
        className="w-100 h-100 d-flex flex-col justify-content-start align-items-start"
      >
        {/* <span className="mr-3 mb-3">Mensagens Fini</span> */}
        {this.state.messages.map(message => {
          const language =
            languages.find(x => x.code === message.language)?.name ??
            'Desconhecida';
          return (
            <div
              className="w-100 d-flex justify-content-start align-items-start mb-3"
              style={{
                border: '1px solid #e8e8e8',
                borderRadius: 3,
                background: message.unread ? '#FDF6F1' : '#F2F5FC'
              }}
            >
              <div className="h-100 d-flex fg-5 p-3">{message.message}</div>
              <div
                className="px-3 pt-3 fg-1"
                style={{ borderLeft: '1px solid #e8e8e8' }}
              >
                <div className="w-100 h-100 d-flex flex-col justify-content-start align-items-start">
                  <div className="pb-1">
                    {moment(message.createdAt).format('YYYY-MM-DD')}
                  </div>

                  <div className="pb-1">Língua: {language}</div>
                  <div className="pb-1">
                    Lida: {message.unread ? 'Não' : 'Sim'}
                  </div>
                  <ConfirmDelete
                    isConfirm={false}
                    showCancel={true}
                    deleteLabel={'Apagar'}
                    cancelLabel={'Cancelar'}
                    confirmLabel={'Confirmar!'}
                    buttonStyle={{
                      float: 'right',
                      marginBottom: '1em'
                    }}
                    onDelete={async () => {
                      try {
                        this.setState({ loading: true });
                        const response = await deleteFiniMessage(message._id);
                        console.log('delete response: ', response);
                        if (response?.ok) {
                          this.setState(oldState => {
                            const newMessages = oldState.messages?.filter(
                              m => m._id !== message._id
                            );

                            return { messages: newMessages ?? [] };
                          });
                        } else {
                          throw new Error("Can't delete message");
                        }
                        this.setState({ loading: false });
                      } catch (err) {
                        message.error(
                          'Ocorreu um erro, não foi possível apagar a mensagem.'
                        );
                        this.setState({ loading: false });
                        return;
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

const GamificationForm = withRouter(Gamification);

export default GamificationForm;
