import ExcelJS from 'exceljs';
import moment from 'moment';

const exportNifRequests = async nifRequests => {
  let workbook = new ExcelJS.Workbook();

  workbook.creator = 'Backoffice App Finpartner';
  workbook.lastModifiedBy = 'Backoffice App Finpartner';
  workbook.created = new Date();
  workbook.modified = new Date();
  workbook.lastPrinted = new Date();
  workbook.views = [
    {
      x: 0,
      y: 0,
      width: 10000,
      height: 20000,
      firstSheet: 0,
      activeTab: 1,
      visibility: 'visible'
    }
  ];

  let worksheet = workbook.addWorksheet('Pedidos de Nif');

  // const dummies = [
  //   {
  //     fullName: 'Cliente Teste',
  //     idCard: '123456',
  //     fullAddress: 'Rua do Exemplo, 0000-000 Lisboa',
  //     email: 'exemplo@nomail.com',
  //     telephone: '+351 912345678',
  //     wechat: '@exemplo',
  //     documents: [
  //       {
  //         s3Id: 'dummy',
  //         title: 'Resident Title'
  //       },
  //       {
  //         s3Id: 'dummy2',
  //         title: 'Blabla'
  //       }
  //     ],
  //     finpartnerComments: '',
  //     maritalStatus: 'Single',
  //     paid: false,
  //     datePaid: new Date(),
  //     paidByAdminId: new Date(),
  //     processFinished: false,
  //     dateFinished: new Date(),
  //     finishedByAdminId: new Date(),
  //     userId: '3f8c0239-1a9d-4a0f-a99e-6b31f2b30b23',
  //     _id: '0',
  //     createdAt: new Date(),
  //     updatedAt: new Date()
  //   }
  // ];
  worksheet.columns = [
    { header: 'Criado em', key: 'createdAt', width: 15 },
    { header: 'Pago', key: 'paid', width: 10 },
    { header: 'Pago Em', key: 'datePaid', width: 15 },
    { header: 'Terminado', key: 'processFinished', width: 10 },
    { header: 'Terminado Em', key: 'dateFinished', width: 15 },
    { header: 'Nome', key: 'fullName', width: 30 },
    { header: 'ID', key: 'idCard', width: 15 },
    { header: 'Morada', key: 'fullAddress', width: 50 },
    { header: 'Email', key: 'email', width: 20 },
    { header: 'Tel', key: 'telephone', width: 20 },
    { header: 'WeChat', key: 'wechat', width: 20 },
    { header: 'Estado Civil', key: 'maritalStatus', width: 15 },
    { header: 'Nº Docs', key: 'documents', width: 5 },
    { header: 'Comentários', key: 'finpartnerComments', width: 30 }
  ];

  formatColors(worksheet);
  console.log(nifRequests?.[0]);

  nifRequests.forEach(request => {
    let row = worksheet.addRow({
      createdAt: moment(request.createdAt).format('YYYY-MM-DD'),
      paid: request.paid,
      datePaid: request.datePaid
        ? moment(request.datePaid).format('YYYY-MM-DD')
        : '',
      processFinished: request.processFinished,
      dateFinished: request.dateFinished
        ? moment(request.dateFinished).format('YYYY-MM-DD')
        : '',
      fullName: request.fullName,
      idCard: request.idCard,
      fullAddress: request.fullAddress,
      email: request.email,
      telephone: request.telephone,
      wechat: request.wechat,
      maritalStatus: request.maritalStatus,
      documents: request.documents?.length ?? 0,
      finpartnerComments: request.finpartnerComments
    });
    row.eachCell(cell => {
      cell.font = {};
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'F8F2F8FF' }
      };
      cell.border = {
        top: { style: 'thin', color: { argb: 'FF444444' } },
        left: { style: 'thin', color: { argb: 'FF444444' } },
        bottom: { style: 'thin', color: { argb: 'FF444444' } },
        right: { style: 'thin', color: { argb: 'FF444444' } }
      };
    });
  });

  return await downloadExcel(workbook);
};

function formatColors(worksheet) {
  //Paint in gray from A1 to L1
  ['A1', 'B1', 'C1', 'D1', 'E1'].forEach(key => {
    worksheet.getCell(key).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'e8e8e8' }
    };
    worksheet.getCell(key).font = {
      size: 13,
      underline: false,
      bold: true
    };
  });
  worksheet.getRow(1).border = {
    top: { style: 'thin', color: { argb: 'FF444444' } },
    left: { style: 'thin', color: { argb: 'FF444444' } },
    bottom: { style: 'thin', color: { argb: 'FF444444' } },
    right: { style: 'thin', color: { argb: 'FF444444' } }
  };
  //create filters
  worksheet.autoFilter = {
    from: 'A1',
    to: 'M1'
  };
}

async function downloadExcel(workbook) {
  try {
    let blob = await workbook2Blob(workbook);

    let filename =
      'app_nif_requests_' + moment().format('YYYY-MM-DD_HH:ss') + '.xlsx';
    return anchorDownload(blob, filename);
  } catch (err) {
    console.error(err);
    return null;
  }
}

const workbook2Blob = async workbook => {
  let buffer = await workbook.xlsx.writeBuffer();
  let blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });
  return blob;
};
const anchorDownload = (blob, filename) => {
  let a = document.createElement('a');
  a.download = filename;
  a.href = URL.createObjectURL(blob);
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  setTimeout(function() {
    URL.revokeObjectURL(a.href);
  }, 1500);
};

export default exportNifRequests;
