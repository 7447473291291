import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import SettingsIcon from '@material-ui/icons/Settings';
import PermContactCalendar from '@material-ui/icons/PermContactCalendar';
import LiveTv from '@material-ui/icons/LiveTv';
import AccessAlarm from '@material-ui/icons/AccessAlarm';
import AccountBalance from '@material-ui/icons/AccountBalance';
import MessageIcon from '@material-ui/icons/Message';
import FingerprintIcon from '@material-ui/icons/Fingerprint';

import {
  translate,
  DashboardMenuItem,
  MenuItemLink,
  Responsive
} from 'react-admin';
import { withRouter } from 'react-router-dom';

import { VisitorIcon } from './visitors';
import { AdminIcon } from './admins';
import { CommandIcon } from './commands';
//import { ProductIcon } from './products';
//import { CategoryIcon } from './categories';
//import { SubscriptionIcon } from './reviews';
import { ChargeIcon } from './charges';

const items = [
  { name: 'admins', icon: <AdminIcon /> },
  { name: 'users', icon: <VisitorIcon /> },
  // { name: 'segments', icon: <LabelIcon /> },
  { name: 'sales', icon: <CommandIcon /> },
  { name: 'charges', icon: <ChargeIcon /> }
  // { name: 'products', icon: <ProductIcon /> }
  // { name: 'categories', icon: <CategoryIcon /> },
  // { name: 'subscriptions', icon: <SubscriptionIcon /> }
];

const baseline = 12;
const headerHeight = 48;

const styles = {
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    minHeight: `calc(100vh - ${baseline + headerHeight}px)`
  }
};

const Menu = ({ onMenuClick, translate, logout }) => (
  <div style={styles.main}>
    <DashboardMenuItem onClick={onMenuClick} />
    {items.map(item => (
      <MenuItemLink
        key={item.name}
        to={`/${item.name}`}
        primaryText={translate(`resources.${item.name}.name`, {
          smart_count: 2
        })}
        leftIcon={item.icon}
        onClick={onMenuClick}
      />
    ))}
    <MenuItemLink
      to="/calendar"
      primaryText={translate('pos.calendar')}
      leftIcon={<PermContactCalendar />}
      onClick={onMenuClick}
    />
    <MenuItemLink
      to="/meetings"
      primaryText={translate('pos.meetings')}
      leftIcon={<AccessAlarm />}
      onClick={onMenuClick}
    />
    <MenuItemLink
      to="/companies"
      primaryText={translate('pos.companies')}
      leftIcon={<AccountBalance />}
      onClick={onMenuClick}
    />
    <MenuItemLink
      to="/news"
      primaryText={translate('pos.news')}
      leftIcon={<LiveTv />}
      onClick={onMenuClick}
    />
    <MenuItemLink
      to="/fini"
      primaryText={translate('pos.fini')}
      leftIcon={<MessageIcon />}
      onClick={onMenuClick}
    />
    <MenuItemLink
      to="/nif"
      primaryText={translate('pos.nif')}
      leftIcon={<FingerprintIcon />}
      onClick={onMenuClick}
    />
    <MenuItemLink
      to="/configuration"
      primaryText={translate('pos.configuration')}
      leftIcon={<SettingsIcon />}
      onClick={onMenuClick}
    />
    <Responsive xsmall={logout} medium={null} />
  </div>
);

const enhance = compose(
  withRouter,
  connect(
    state => ({
      theme: state.theme,
      locale: state.i18n.locale
    }),
    {}
  ),
  translate
);

export default enhance(Menu);
