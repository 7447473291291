import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import withStyles from '@material-ui/core/styles/withStyles';
import finpartnerSvg from '../images/finpartner.svg';

const styles = {
    root: { display: 'inline-block', marginTop: '1em', zIndex: 2 },
    content: { padding: 0, '&:last-child': { padding: 0 } },
    img: {
        minWidth: 'initial',
        width: '200px',
        height: '200px'
    }
};

const Product = withStyles(styles)(({ classes, record }) => (
    <Card className={classes.root}>
        <CardContent className={classes.content}>
            <img
                src={finpartnerSvg /*record.image*/}
                alt=""
                className={classes.img}
            />
        </CardContent>
    </Card>
));

export default Product;
