import React from 'react';
import { connect } from 'react-redux';
import { translate, changeLocale } from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import compose from 'recompose/compose';
import autobind from 'auto-bind/react';
import { Form, Select, Button, Input, Transfer } from 'antd';

import { getAllUsers, postFiniMessage } from '../common/network';
import g from '../common/globalStyles';
import DetailedMessage from '../components/DetailedMessage';

import constants from './constants';

const { languages } = constants;

const { Option } = Select;
const styles = {
  body: {
    display: 'flex',
    alignItems: 'center'
  },
  simpleContainer: {
    marginBottom: g.global.baseline
  },
  container: {
    width: '100%',
    ...g.layout.flexHorizontal,
    ...g.layout.flexStart,
    ...g.layout.alignCenter,
    marginBottom: g.global.baseline
  },

  item: {
    width: '33%',
    fontWeight: 100,
    marginBottom: 0,
    marginRight: g.global.baseline
  },
  largeItem: {
    width: '100%',
    fontWeight: 100,
    marginBottom: 0,
    marginRight: g.global.baseline
  },
  form: {
    width: '100%',
    border: '1px solid #dddddd',
    borderRadius: 5,
    padding: g.global.baseline * 2
  }
};

class Meetings extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      users: [],
      selectedUsers: [],
      loading: false,
      sent: null,
      done: false
    };

    autobind(this);
  }

  async componentDidMount() {
    await this.refresh();
  }

  clear = () => {
    this.props.form.resetFields();
    this.setState({ done: false, selectedUsers: [] });
  };

  async refresh() {
    this.setState({ loading: false });

    let response = await getAllUsers();

    if (response && response.result === 'OK') {
      this.setState({ users: response.data });
    } else {
      DetailedMessage.error(
        'Error: cannot fetch users. Please refresh this page and try again',
        response
      );
    }
  }

  async submit(event) {
    event.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        this.setState({ loading: true });
        values.language = values.language.key;
        console.log(values);

        const response = await postFiniMessage(values);
        if (response && response.result === 'OK') {
          this.setState({
            done: true,
            failed: response?.data?.failed,
            successCount: response?.data?.successCount
          });
        } else {
          console.log('Error sending request: ', response);
          this.setState({
            done: true,
            failed: ['Erro a enviar pedido ou a receber resposta do servidor.']
          });
        }
        this.setState({ loading: false });
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div style={styles.body}>
        <Form style={styles.form} onSubmit={this.submit}>
          {this.state.done && (
            <div className="w-100 d-flex flex-col pb-5">
              <h3>
                {`Mensagens enviadas com
                ${this.state.failed?.length > 0 ? 'erro' : 'sucesso'}`}
              </h3>
              <span className="mb-3">
                Nº de mensagens enviadas: {this.state.successCount ?? '-'}
              </span>
              {this.state.failed?.length > 0 && (
                <>
                  <span>Mensagens com falha (não enviadas):</span>
                  {this.state.failed.map(fail => {
                    return <div key={fail}>{fail}</div>;
                  })}
                </>
              )}
              <div className="w-100 d-flex mt-3">
                <Button
                  style={styles.item}
                  type="primary"
                  className="login-form-button mr-3"
                  onClick={this.clear}
                >
                  Finalizar e limpar valores
                </Button>
                <Button
                  style={styles.item}
                  type="primary"
                  ghost
                  className="login-form-button"
                  onClick={() => this.setState({ done: false })}
                >
                  Editar Mensagem para novo envio
                </Button>
              </div>
            </div>
          )}
          {/* <Form.Item style={styles.item}>
              {getFieldDecorator('title', {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório'
                  }
                ]
              })(
                <Input
                  disabled={this.state.loading}
                  style={{
                    width: '100%',
                    paddingRight: g.global.baseline
                  }}
                  placeholder="Título"
                />
              )}
            </Form.Item> */}
          <div style={styles.container}>
            <Form.Item style={styles.largeItem}>
              {getFieldDecorator('message', {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório'
                  }
                ]
              })(
                <Input.TextArea
                  disabled={this.state.loading || this.state.done}
                  autosize={{ minRows: 3 }}
                  style={{
                    width: '100%'
                  }}
                  placeholder="Mensagem"
                />
              )}
            </Form.Item>
          </div>
          <div style={{ ...styles.container, ...g.layout.flexEnd }}>
            <span style={{ paddingRight: 12 }}>Língua: </span>
            <Form.Item style={styles.item}>
              {getFieldDecorator('language', {
                rules: [{ required: true, message: 'Obrigatório' }]
              })(
                <Select
                  optionFilterProp="children"
                  disabled={this.state.loading || this.state.done}
                  labelInValue
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {languages.map(lang => (
                    <Option key={lang.code}>{lang.name}</Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </div>
          <div style={styles.container}>
            <Form.Item style={styles.largeItem}>
              {getFieldDecorator('users', {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório'
                  }
                ]
              })(
                <Transfer
                  style={{
                    display: 'flex',
                    alignItems: 'stretch',
                    height: '60vh'
                  }}
                  titles={['Clientes disponíveis', 'Clientes-alvo']}
                  listStyle={({ direction }) => {
                    const style = { flexGrow: 1, height: 'auto' };
                    if (direction === 'right') {
                      style.border = '1px solid #0353a4';
                    }
                    return style;
                  }}
                  dataSource={this.state.users}
                  rowKey={record => record._id}
                  showSearch
                  disabled={this.state.loading || this.state.done}
                  targetKeys={this.state.selectedUsers}
                  onChange={targetKeys => {
                    this.setState({ selectedUsers: targetKeys });
                  }}
                  render={item => `${item.name} - ${item.email}`}
                  footer={this.renderFooter}
                />
              )}
            </Form.Item>
          </div>
          <div
            style={{
              ...styles.container,
              ...g.layout.flexEnd,
              ...g.layout.alignCenter
            }}
          >
            <Button
              style={styles.item}
              loading={this.state.loading}
              disabled={this.state.loading || this.state.done}
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Enviar
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  theme: state.theme,
  locale: state.i18n.locale
});

export default compose(
  connect(
    mapStateToProps,
    {
      changeLocale
    }
  ),
  translate,
  withStyles(styles),
  Form.create({ name: 'fini_form' })
)(Meetings);
