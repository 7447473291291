import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { translate, crudGetMany as crudGetManyAction } from 'react-admin';
import compose from 'recompose/compose';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = {
    container: {
        width: '42em',
        float: 'right',
        zIndex: 2,
        '&:after': { clear: 'both' }
    },
    rightAlignedCell: { textAlign: 'right' },
    boldCell: { fontWeight: 'bold' }
};

class Basket extends Component {
    componentDidMount() {
        this.fetchData();
    }
    fetchData() {
        const {
            record: { productIds },
            crudGetMany
        } = this.props;
        crudGetMany('products', productIds);
    }
    render() {
        const { classes, record, products, translate } = this.props;
        const { productIds } = record;
        //console.log('PRODUCT: ' + JSON.stringify(products));
        return (
            <Paper className={classes.container}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.leftAlignedCell}>
                                {translate(
                                    'resources.sales.fields.basket.product'
                                )}
                            </TableCell>
                            <TableCell className={classes.rightAlignedCell}>
                                {translate(
                                    'resources.sales.fields.basket.unit_price'
                                )}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {productIds.map(
                            id =>
                                products[id] && (
                                    <TableRow key={id}>
                                        <TableCell>
                                            {products[id].title}
                                        </TableCell>
                                        <TableCell
                                            className={classes.rightAlignedCell}
                                        >
                                            {products[id].price.toLocaleString(
                                                undefined,
                                                {
                                                    style: 'currency',
                                                    currency: 'EUR'
                                                }
                                            )}
                                        </TableCell>
                                        {/* <TableCell
                                            className={classes.rightAlignedCell}
                                        >
                                            {item.quantity}
                                        </TableCell> 
                                        <TableCell
                                            className={classes.rightAlignedCell}
                                        >
                                            {(
                                                products[item.id].price *
                                                item.quantity
                                            ).toLocaleString(undefined, {
                                                style: 'currency',
                                                currency: 'EUR'
                                            })}
                                        </TableCell>*/}
                                    </TableRow>
                                )
                        )}
                        {/* <TableRow>
                            <TableCell colSpan={2} />
                            <TableCell>
                                {translate('resources.sales.fields.basket.sum')}
                            </TableCell>
                            <TableCell className={classes.rightAlignedCell}>
                                {record.total_ex_taxes.toLocaleString(
                                    undefined,
                                    { style: 'currency', currency: 'EUR' }
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={2} />
                            <TableCell>
                                {translate(
                                    'resources.sales.fields.basket.delivery'
                                )}
                            </TableCell>
                            <TableCell className={classes.rightAlignedCell}>
                                {record.delivery_fees.toLocaleString(
                                    undefined,
                                    { style: 'currency', currency: 'EUR' }
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={2} />
                            <TableCell>
                                {translate(
                                    'resources.sales.fields.basket.tax_rate'
                                )}
                            </TableCell>
                            <TableCell className={classes.rightAlignedCell}>
                                {record.tax_rate.toLocaleString(undefined, {
                                    style: 'percent'
                                })}
                            </TableCell>
                        </TableRow> */}
                        <TableRow>
                            <TableCell colSpan={2} />
                            <TableCell className={classes.boldCell}>
                                {translate(
                                    'resources.sales.fields.basket.total'
                                )}
                            </TableCell>
                            <TableCell
                                className={classnames(
                                    classes.boldCell,
                                    classes.rightAlignedCell
                                )}
                            >
                                {record.price.toLocaleString(undefined, {
                                    style: 'currency',
                                    currency: 'EUR'
                                })}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Paper>
        );
    }
}

const mapStateToProps = (state, props) => {
    const {
        record: { productIds }
    } = props;

    return {
        products: productIds
            .map(productId => state.admin.resources.products.data[productId])
            .filter(r => typeof r !== 'undefined')
            .reduce((prev, next) => {
                prev[next.id] = next;
                return prev;
            }, {})
    };
};

const enhance = compose(
    translate,
    withStyles(styles),
    connect(
        mapStateToProps,
        {
            crudGetMany: crudGetManyAction
        }
    )
);

export default enhance(Basket);
