import React from 'react';
import { Popover, Icon, Tabs, Button } from 'antd';
import globalStyles from '../common/globalStyles';

import ConfirmDelete from '../components/ConfirmDelete';

import { translate } from 'react-admin';
import compose from 'recompose/compose';
import CreateEvent from './CreateEvent';

const { TabPane } = Tabs;

class Event extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      visible: false
    };

    this.viewEvent = this.viewEvent.bind(this);
    this.editEvent = this.editEvent.bind(this);
    this.state.content = this.viewEvent();
  }

  editEvent() {
    return (
      <CreateEvent
        data={{
          start: this.props.event.start,
          end: this.props.event.end,
          slots: this.props.event.slots,
          allDay: true,
          lang: this.props.event.lang,
          _id: this.props.event._id
        }}
        onError={this.props.onError}
        onCancel={async () => {
          this.setState({ content: this.viewEvent() });
          await this.props.onCancel();
        }}
        onSuccess={async (response, data) => {
          //console.log('in view, setting event: ', _.cloneDeep(data));
          this.setState({
            visible: false,
            event: data,
            content: this.viewEvent()
          });
          if (this.props.onSuccess) {
            return await this.props.onSuccess(response, data);
          }
        }}
        action={this.props.action}
        onClose={() => {
          this.setState({ content: this.viewEvent(), visible: false });
        }}
      />
    );
  }

  viewEvent() {
    return (
      <div style={{ minWidth: '50vh', maxWidth: '80vh' }}>
        <h3>Start: {this.props.event.start.format('dddd, MMMM Do YYYY')}</h3>
        <h3>End: {this.props.event.end.format('dddd, MMMM Do YYYY')}</h3>
        <h3>
          All day:{' '}
          {this.props.event.allDay ? (
            <Icon type="check" />
          ) : (
            <Icon type="close" />
          )}
        </h3>
        <Tabs defaultActiveKey="pt_PT">
          {Object.keys(this.props.event.lang).map(lang => {
            return (
              <TabPane tab={this.props.translate('lang.' + lang)} key={lang}>
                <h3>Title: {this.props.event.lang[lang].title}</h3>
                <h3>Description: {this.props.event.lang[lang].description}</h3>
              </TabPane>
            );
          })}
        </Tabs>
        <Button
          type="primary"
          ghost
          style={{ marginRight: globalStyles.global.baseline }}
          onClick={() => this.setState({ content: this.editEvent() })}
        >
          Editar
        </Button>
        <Button
          style={{ marginRight: globalStyles.global.baseline }}
          onClick={() => this.setState({ visible: false })}
        >
          Fechar
        </Button>
        <ConfirmDelete
          isConfirm={false}
          deleteLabel={'Apagar'}
          confirmLabel={'Confirmar!'}
          buttonStyle={{
            marginRight: globalStyles.global.baseline,
            float: 'right'
          }}
          onDelete={async () => {
            if (this.props.onDelete) {
              await this.props.onDelete(this.props.event._id);
              this.setState({ visible: false });
            }
          }}
        />
      </div>
    );
  }

  render() {
    let title = null;
    for (let key of Object.keys(this.props.event.lang)) {
      const t = this.props.event.lang[key].title;
      if (t) {
        title = t;
        break;
      }
    }
    if (!title) {
      title = 'Sem Título';
    }

    return (
      <Popover
        trigger="hover"
        visible={this.state.visible}
        content={this.state.visible ? this.state.content : <div></div>}
        title={title}
      >
        <div
          style={{ borderRadius: 0 }}
          onClick={() =>
            this.setState({
              visible: !this.state.visible,
              content: this.viewEvent()
            })
          }
        >
          <span style={{ fontWeight: 'light' }}>{title}</span>
        </div>
      </Popover>
    );
  }
}

export default compose(translate)(Event);
