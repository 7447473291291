import React from 'react';
import autobind from 'auto-bind';
import { Link } from 'react-router-dom';
import { Empty, Badge, Button } from 'antd';

import {
  ZoomInOutlined,
  CaretDownOutlined,
  CaretUpOutlined
} from '@ant-design/icons';
import { AutoSizer, Table, Column, SortDirection } from 'react-virtualized';
import op from 'object-path';
import moment from 'moment';

import './SortableTable.scss';

import { getLevel, getPointsFromActions } from '../gamification/constants';

import level0 from '../../images/gamification_level_1.png';
import level1 from '../../images/gamification_level_2.png';
import level2 from '../../images/gamification_level_3.png';
import level3 from '../../images/gamification_level_4.png';

const levelImages = [level0, level1, level2, level3];

const AntSortIndicator = ({ sortDirection }) => {
  return sortDirection === SortDirection.ASC ? (
    <CaretUpOutlined></CaretUpOutlined>
  ) : (
    <CaretDownOutlined></CaretDownOutlined>
  );
};

const defaultRowHeight = 80;
const defaultHeaderHeight = 60;

//eslint-disable-next-line no-unused-vars
const passwordRenderer = () => {
  return <div className={`inner-cell`}>{'•'.repeat(3)}</div>;
};

//eslint-disable-next-line no-unused-vars
const dateRenderer = ({ cellData }) => {
  const date = moment(cellData);
  const cellContent = date.isValid() ? date.format('YYYY-MM-DD') : '';
  return <div className={`inner-cell`}>{cellContent}</div>;
};

const cellRenderer = ({ cellData }) => {
  return <div className={`inner-cell`}>{cellData}</div>;
};

//eslint-disable-next-line no-unused-vars
const yesNoRenderer = ({ cellData }) => {
  return <div className={`inner-cell`}>{cellData ? 'Sim' : 'Não'}</div>;
};
//eslint-disable-next-line no-unused-vars
const yesRenderer = ({ cellData }) => {
  return cellData ? (
    <div className={`inner-cell color-red font-500`}>Sim</div>
  ) : null;
};

//eslint-disable-next-line no-unused-vars
const actionRenderer = ({ cellData }) => {
  return (
    <div className="inner-cell h-100">
      <Link
        className="row-link h-100 d-flex flex-col justify-content-center align-items-center hover-bg-blue button-invisible"
        to={location =>
          `${location.pathname.replace(/\/$/, '')}/client/${cellData}/`
        }
      >
        <ZoomInOutlined style={{ fontSize: 20 }}></ZoomInOutlined>
      </Link>
    </div>
  );
};

const levelImageRenderer = ({ rowData }) => {
  const actions = rowData?.gamification?.actions ?? [];
  const points = getPointsFromActions(actions);
  const level = getLevel(points);
  const levelImageSrc = levelImages[level.id];
  return (
    <div className="inner-cell h-100">
      <div className="level-image mr-3">
        <img src={levelImageSrc} alt="level badge"></img>
      </div>
    </div>
  );
};

const pointsRenderer = ({ rowData }) => {
  const actions = rowData?.gamification?.actions ?? [];
  const points = getPointsFromActions(actions);

  return cellRenderer({ cellData: points });
};

const rewardRenderer = ({ rowData }) => {
  const rewards = rowData?.gamification?.rewards ?? [];

  const done = rewards.filter(x => x.done).length;
  const todo = rewards.filter(x => !x.done).length;

  return (
    <div
      className="inner-cell text-muted d-flex flex-col justify-content-center align-items-center"
      style={columnProps['open'].style}
    >
      <Badge
        style={{
          backgroundColor: '#fff',
          color: '#999',
          boxShadow: '0 0 0 1px #d9d9d9 inset',
          marginBottom: 6
        }}
        count={done}
      ></Badge>
      <Badge count={todo}></Badge>
    </div>
  );
};

//eslint-disable-next-line no-unused-vars
const notificationRenderer = ({ cellData, dataKey, rowIndex }) => {
  const notifications = cellData ?? [];
  const notificationCount = notifications.filter(x => x.read !== true).length;
  // console.log({ notifications, notificationCount });
  return notificationCount ? (
    <div className={`inner-cell`}>
      <Badge count={notificationCount}></Badge>
    </div>
  ) : null;
};

const columnProps = {
  index: {
    style: {
      width: 20,
      flexGrow: 0,
      paddingLeft: 10
    },
    props: {}
  },

  name: {
    style: {
      width: 80,
      flexGrow: 1
    },
    props: { cellRenderer }
  },
  email: {
    style: {
      width: 80,
      flexGrow: 2
    },
    props: { cellRenderer }
  },

  level: {
    style: {
      width: 20,
      flexGrow: 1
    },
    props: { cellRenderer: levelImageRenderer }
  },
  points: {
    style: {
      width: 10,
      flexGrow: 1
    },
    props: { cellRenderer: pointsRenderer }
  },
  rewards: {
    style: {
      width: 10,
      flexGrow: 1
    },
    props: { cellRenderer: rewardRenderer }
  },
  open: {
    style: {
      width: 20,
      flexGrow: 2,
      paddingLeft: 10
    },
    props: {}
  }
};

export default class SortableTable extends React.Component {
  tableRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {};

    autobind(this);
  }

  rowGetter({ index }) {
    return this.props.data?.[index] ?? {};
  }

  cellDataGetter({ columnData, dataKey, rowData }) {
    return op.get(rowData, dataKey);
  }

  headerRenderer({
    columnData,
    dataKey,
    disableSort,
    label,
    sortBy,
    sortDirection
  }) {
    return (
      <div
        className={
          'd-flex flex-col justify-content-start align-items-center w-100 h-100'
        }
      >
        <div
          className={
            'inner-header d-flex justify-content-start align-items-center w-100 ' +
            (sortBy !== dataKey ? 'sort-inactive' : '')
          }
        >
          <div>{label}</div>
          {!disableSort &&
            (sortBy === dataKey ? (
              <AntSortIndicator sortDirection={sortDirection} />
            ) : (
              <AntSortIndicator sortDirection={sortDirection} />
            ))}
        </div>
      </div>
    );
  }

  rowRenderer(props) {
    const {
      className,
      columns,
      index,
      key,
      onRowClick,
      onRowDoubleClick,
      onRowMouseOut,
      onRowMouseOver,
      onRowRightClick,
      rowData,
      style
    } = props;

    const a11yProps = { 'aria-rowindex': index + 1 };

    if (
      onRowClick ||
      onRowDoubleClick ||
      onRowMouseOut ||
      onRowMouseOver ||
      onRowRightClick
    ) {
      a11yProps['aria-label'] = 'row';
      a11yProps.tabIndex = 0;

      if (onRowClick) {
        a11yProps.onClick = event => onRowClick({ event, index, rowData });
      }
      if (onRowDoubleClick) {
        a11yProps.onDoubleClick = event =>
          onRowDoubleClick({ event, index, rowData });
      }
      if (onRowMouseOut) {
        a11yProps.onMouseOut = event =>
          onRowMouseOut({ event, index, rowData });
      }
      if (onRowMouseOver) {
        a11yProps.onMouseOver = event =>
          onRowMouseOver({ event, index, rowData });
      }
      if (onRowRightClick) {
        a11yProps.onContextMenu = event =>
          onRowRightClick({ event, index, rowData });
      }
    }

    return (
      <div
        {...a11yProps}
        className={className}
        key={key}
        role="row"
        style={style}
      >
        {columns}
      </div>
    );
  }

  getRowHeight({ index }) {
    return defaultRowHeight;
  }

  render() {
    return (
      <div id="clients" className="w-100 h-100">
        <AutoSizer>
          {({ width, height }) => (
            <Table
              ref={this.tableRef}
              headerHeight={defaultHeaderHeight}
              height={height}
              noRowsRenderer={() => (
                <Empty
                  className="w-100 h-100 d-flex flex-col justify-content-center align-items-center"
                  description={<span>Sem dados</span>}
                ></Empty>
              )}
              overscanRowCount={10}
              rowHeight={this.getRowHeight}
              rowGetter={this.rowGetter}
              rowRenderer={this.rowRenderer}
              rowClassName={({ index }) => {
                return index % 2 === 0 ? 'even-row' : 'odd-row';
              }}
              rowCount={this.props.data?.length ?? 0}
              sort={this.props.onSort}
              sortBy={this.props.sortBy.value}
              sortDirection={this.props.sortDirection.value}
              width={width}
            >
              <Column
                label="N"
                cellDataGetter={this.cellDataGetter}
                headerRenderer={this.headerRenderer}
                cellRenderer={({ rowIndex, rowData }) => (
                  <div className="inner-cell text-muted index-cell">
                    {rowIndex + 1}
                  </div>
                )}
                dataKey="index"
                disableSort={true}
                {...columnProps['index'].style}
                {...columnProps['index'].props}
              />
              <Column
                dataKey="name"
                label="Nome"
                cellDataGetter={this.cellDataGetter}
                disableSort={false}
                headerRenderer={this.headerRenderer}
                {...columnProps['name'].style}
                {...columnProps['name'].props}
              />
              <Column
                disableSort={false}
                cellDataGetter={this.cellDataGetter}
                label="E-mail"
                dataKey="email"
                headerRenderer={this.headerRenderer}
                {...columnProps['email'].style}
                {...columnProps['email'].props}
              />
              <Column
                disableSort={true}
                cellDataGetter={this.cellDataGetter}
                label="Nível"
                dataKey="gamification"
                headerRenderer={this.headerRenderer}
                {...columnProps['level'].style}
                {...columnProps['level'].props}
              />
              <Column
                disableSort={false}
                cellDataGetter={this.cellDataGetter}
                label="Pontos"
                dataKey="gamification.points"
                headerRenderer={this.headerRenderer}
                {...columnProps['points'].style}
                {...columnProps['points'].props}
              />
              <Column
                disableSort={false}
                cellDataGetter={this.cellDataGetter}
                label="Recompensas"
                dataKey="gamification.rewards"
                headerRenderer={this.headerRenderer}
                {...columnProps['rewards'].style}
                {...columnProps['rewards'].props}
              />
              <Column
                disableSort={true}
                cellDataGetter={this.cellDataGetter}
                cellRenderer={({ rowIndex, rowData }) => (
                  <div
                    className="inner-cell text-muted index-cell"
                    style={columnProps['open'].style}
                  >
                    <Link
                      className="row-link h-100 d-flex flex-col justify-content-center align-items-center button-invisible"
                      to={`/users/${rowData.id}`}
                    >
                      <Button type="primary" shape="circle">
                        <ZoomInOutlined></ZoomInOutlined>
                      </Button>
                    </Link>
                  </div>
                )}
                label="open"
                dataKey="open"
                headerRenderer={() => {
                  return (
                    <div
                      className="inner-cell text-muted index-cell"
                      style={columnProps['open'].style}
                    >
                      <Button type="link" shape="round">
                        <ZoomInOutlined></ZoomInOutlined>
                      </Button>
                    </div>
                  );
                }}
                {...columnProps['open'].style}
                {...columnProps['open'].props}
              />
            </Table>
          )}
        </AutoSizer>
      </div>
    );
  }
}

export { SortableTable as List };
