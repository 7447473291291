import React from 'react';
import {
    Create,
    Datagrid,
    Edit,
    EditButton,
    CardActions,
    List,
    NumberField,
    RadioButtonGroupInput,
    Responsive,
    SimpleForm,
    TextField,
    TextInput
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import Icon from '@material-ui/icons/SupervisorAccount';

import FullNameField from './FullNameField';
import MobileGrid from './MobileGrid';

export const AdminIcon = Icon;

const colored = WrappedComponent => {
    const Colored = props =>
        props.record[props.source] > 500 ? (
            <span style={{ color: 'red' }}>
                <WrappedComponent {...props} />
            </span>
        ) : (
            <WrappedComponent {...props} />
        );

    Colored.displayName = `Colored(${WrappedComponent.displayName})`;

    return Colored;
};

export const ColoredNumberField = colored(NumberField);
ColoredNumberField.defaultProps = NumberField.defaultProps;

const listStyles = {
    nb_commands: { color: 'purple' }
};

export const AdminList = withStyles(listStyles)(({ classes, ...props }) => (
    <List {...props} perPage={50}>
        <Responsive
            xsmall={<MobileGrid />}
            medium={
                <Datagrid>
                    <TextField
                        source="name"
                        formClassName={classes.first_name}
                    />
                    <TextField source="email" />
                    <EditButton key="edit" />
                </Datagrid>
            }
        />
    </List>
));

const AdminTitle = ({ record }) =>
    record ? <FullNameField record={record} size={32} /> : null;

const editStyles = {
    first_name: { display: 'inline-block' },
    email: { width: 544 },
    address: { maxWidth: 544 },
    zipcode: { display: 'inline-block' },
    city: { display: 'inline-block', marginLeft: 32 },
    comment: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    }
};

const PostEditActions = ({ basePath, data, resource }) => (
    <CardActions>{null}</CardActions>
);

export const AdminEdit = withStyles(editStyles)(({ classes, ...props }) => (
    <Edit actions={<PostEditActions />} title={<AdminTitle />} {...props}>
        <SimpleForm>
            <TextInput source="name" formClassName={classes.first_name} />
            <TextField
                type="email"
                source="email"
                formClassName={classes.email}
            />
            <RadioButtonGroupInput
                source="role"
                choices={[
                    { id: 'admin', name: 'Administrator' },
                    { id: 'backoffice', name: 'Backoffice User' }
                ]}
            />
        </SimpleForm>
    </Edit>
));

export const AdminCreate = withStyles(editStyles)(({ classes, ...props }) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" formClassName={classes.first_name} />
            <TextInput
                type="email"
                source="email"
                validation={{ email: true }}
                fullWidth={true}
                formClassName={classes.email}
            />
            <TextInput
                type="password"
                source="password"
                fullWidth={true}
                formClassName={classes.email}
            />
            <RadioButtonGroupInput
                source="role"
                choices={[
                    { id: 'admin', name: 'Administrator' },
                    { id: 'backoffice', name: 'Backoffice User' }
                ]}
            />
        </SimpleForm>
    </Create>
));
